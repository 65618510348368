import React, { Component } from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import Select from "react-select";
import HttpService from "../../../services/HttpService";
import {MdArrowDropDown} from "react-icons/md"

export default class StudentFilter extends Component {
  state = {
    person_class: [],
    image_status: [],
    branch:[],
    section:[],
    is_active: [],
  };
  componentDidMount() {
    this.getLookUp();
  }
  async getLookUp() {
    this.setState({ person_class: await HttpService.getClassList() });
    this.setState({ section: await HttpService.getSectionsList() });
    this.setState({ branch: await HttpService.getSitesList() });
    this.setState({ image_status: await HttpService.getImageStatusList() });
    this.setState({ is_active: await HttpService.getStatusesList() });
  }
    onEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.props.onFilterApply()
    }
  };
  render() {
    const boolRfid = process.env.REACT_APP_RFID;
    const {
      onFilterApply,
      onExportCsv,
      onDDChange,
      onFilterReset,
      selectedFormValues,
      onFormFieldChange,
      SelectedValues,
    } = this.props;
    const { person_name, person_number ,roll_number, rfid} =
    selectedFormValues;
      const {selected_person_class,selected_is_active,selected_section, selected_image_status,}=SelectedValues;
    
    return (
      <>
        <Row className="mt-2 mb-4">
          <Col>
            <Card className="card-user">
              <CardBody>
                <Row>
                  <Col>
                    <h5>Filters</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-row">
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Name"}:
                        </label>
                        <input
                          type="text"
                          name="person_name"
                          className={`input-text`}
                          value={person_name}
                          placeholder={"Search student name..."}
                          onChange={onFormFieldChange}
                          onKeyDown={(e) => this.onEnterPress(e)}
                          maxLength={"30"}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Registration #"}:
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          name="person_number"
                          className={`input-text `}
                          value={person_number}
                          placeholder={"Search registration #"}
                          onChange={onFormFieldChange}
                          onKeyDown={(e) => this.onEnterPress(e)}
                          maxLength={"30"}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Roll #"}:
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          name="roll_number"
                          className={`input-text `}
                          value={roll_number}
                          placeholder={"Search Roll #"}
                          onChange={onFormFieldChange}
                          onKeyDown={(e) => this.onEnterPress(e)}
                          maxLength={"30"}
                        />
                      </div>
                      {boolRfid &&  boolRfid === "true" ?
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"RFID"}:
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          name="rfid"
                          className={`input-text `}
                          value={rfid}
                          placeholder={"Search RFID"}
                          onChange={onFormFieldChange}
                          onKeyDown={(e) => this.onEnterPress(e)}
                          maxLength={"30"}
                        />
                      </div> : null }
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                      <label className="form-label text-muted">
                          {"Class"}:
                        </label>
                        <Select
                          isClearable
                          isSearchable={false}
                          name="person_class"
                          value={selected_person_class}
                          onChange={(selectedOption, name) =>
                            onDDChange(selectedOption, name)
                          }                          
                          options={this.state.person_class}
                          classNamePrefix="react-select"
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-12 col-xs-12">
                       <label className="form-label text-muted">
                          {"Section"}:
                        </label>
                        <Select
                          isClearable
                          isSearchable={false}
                          name="section"
                          value={selected_section}
                          onChange={(selectedOption, name) =>
                            onDDChange(selectedOption, name)
                          }  
                          options={this.state.section}
                          classNamePrefix="react-select"
                        />
                       </div>
                       {/* <div className="col-lg-3  col-md-4 col-sm-12 col-xs-12">
                       <label className="form-label text-muted">
                          {"Branch"}:
                        </label>
                        <Select
                          isClearable
                          isSearchable={false}
                          name="branch"
                          value={selected_branch}
                          onChange={(selectedOption, name) =>
                            onDDChange(selectedOption, name)
                          }
                          options={this.state.branch}
                          classNamePrefix="react-select"
                        />
                       </div> */}
                      <div className="col-lg-3  col-md-4 col-sm-12 col-xs-12">
                       <label className="form-label text-muted">
                          {"Image Status"}:
                        </label>
                        <Select
                          isClearable
                          isSearchable={false}
                          name="image_status"
                          value={selected_image_status}
                          onChange={(selectedOption, name) =>
                            onDDChange(selectedOption, name)
                          }
                          options={this.state.image_status}
                          classNamePrefix="react-select"
                        />
                       </div>
                      <div className=" mb-3 col-lg-3  col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Status"}:
                        </label>
                        <Select
                          isClearable
                          isSearchable={false}
                          name="is_active"
                          value={selected_is_active}
                          onChange={(selectedOption, name) =>
                            onDDChange(selectedOption, name)
                          }
                          options={this.state.is_active}
                          classNamePrefix="react-select"
                        />
                      </div>
                      <div className=" float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="float-left full-width-column">
                          <Button 
                            onClick={onExportCsv}
                            color="primary"
                            className="caret button-full-width"
                          >
                            Export <MdArrowDropDown size={20} className="mb-1"/>
                          </Button>
                        </div>
                        <div  className="float-right full-width-column">
                          <Button className="button-full-width" color="primary" onClick={onFilterApply}>
                            Apply Filter
                          </Button>
                        </div>
                        <div className="float-right full-width-column">
                          <Button
                            className="mr-3 button-full-width"
                            outline
                            color="primary mr-3"
                            onClick={onFilterReset}
                          >
                            Clear Filters
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
