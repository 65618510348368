import { ApiUrls } from "../../../utils/apiUrls";
import authAxios from "../../../services/axiosInstance";
import axios from "axios";


export const getAllClass = () => {
  return authAxios
    .get(ApiUrls.Lookups_Class.GET_CLASS_LIST)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addClass = ({ name }) => {
  return authAxios
    .post(ApiUrls.Lookups_Class.ADD_CLASS, name)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const classDetails = ( classId ) => {
  return authAxios
    .get(ApiUrls.Lookups_Class.CLASS_DETAIL(classId))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateClass = ({ postData }) => {
  return authAxios
    .post(ApiUrls.Lookups_Class.UPDATE_CLASS, postData)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const deleteClass = ({ statusData }) => {
  return authAxios
    .post(ApiUrls.Lookups_Class.CLASS_DELETE, statusData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const filterTableRecords = (
  per_page,
  page_no,
  order_by,
  order_type,
  name,
  min,
  max,
  sections_ids,
  status,
  download
) => {
  return authAxios
    .get(ApiUrls.Lookups_Class.GET_CLASS_LIST, {
      params: {
        per_page,
        page_no,
        order_by,
        order_type,
        name,
        min,
        max,
        sections_ids,
        status,
        download
      },
    })
    .then(({ data }) => data)
    .catch((response) => response);
};

export const activeInActiveClass = ({ statusData }) => {
  return authAxios
    .put(ApiUrls.Lookups_Class.ACTIVE_INACTIVE_CLASS, statusData)
    .then(({ data }) => data)
    .catch((response) => response);
};