import { ApiUrls } from "../../utils/apiUrls";
import authAxios from "../../services/axiosInstance";
import axios from "axios";


export const getAllStaff = () => {
  return authAxios
    .get(ApiUrls.Person.GET_PERSON_LIST)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addStaff = (selectedStaffInformation) => {
  return authAxios
    .post(ApiUrls.Person.ADD_PERSON, selectedStaffInformation)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const staffDetails = ({ person, person_data }) => {
  return authAxios
    .post(ApiUrls.Person.PERSON_DETAIL, person, person_data)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateStaff = ({ postData }) => {
  return authAxios
    .post(ApiUrls.Person.UPDATE_PERSON, postData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const importStaffBulk = ({ data }) => {
  return authAxios
    .post(ApiUrls.Person.IMPORT_PERSON_BULK, data)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const studentFilters = ({ student_filters }) => {
  return authAxios
    .post(ApiUrls.Person.PERSON_FILTERS, student_filters)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const deleteStaff = ({ id }) => {
  return authAxios
    .post(ApiUrls.Person.PERSON_DELETE, id)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const addStaffImage = ({ data }) => {
  return authAxios
    .post(ApiUrls.Person.ADD_PERSON_IMAGE, data)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const bulkActive = ({ activeInctiveData }) => {
  return authAxios
    .post(ApiUrls.Person.BULK_ACTIVE, activeInctiveData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const bulkInActive = ({ activeInctiveData }) => {
  return authAxios
    .post(ApiUrls.Person.BULK_INACTIVE, activeInctiveData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const bulkDelete = ({ BulkDeleteData }) => {
  return authAxios
    .post(ApiUrls.Person.BULK_DELETE, BulkDeleteData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const activeInActiveStaff = ({ statusData }) => {
  return authAxios
    .post(ApiUrls.Person.ACTIVE_INACTIVE_PERSON, statusData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const filterTableRecords = (
  per_page,
  page_no,
  order_by,
  order_type,
  person_name,
  person_number,
  rfid,
  department,
  branch,
  image_status,
  is_active,
  person_type,
  download
) => {
  return authAxios
    .get(ApiUrls.Person.GET_PERSON_LIST, {
      params: {
        per_page,
        page_no,
        order_by,
        order_type,
        person_name,
        person_number,
        rfid,
        department,
        branch,
        image_status,
        is_active,
        person_type,
        download
      },
    })
    .then(({ data }) => data)
    .catch((response) => response);
};
export const uploadBulkImages = (file) => {
  let formData = new FormData();
  formData.append("image", file);
  return authAxios
    .post(ApiUrls.Person.IMPORT_IMAGES, formData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const deleteBulkImage = (id) => {
  return authAxios
    .delete(ApiUrls.Media.DELETE_BULK_IMAGE, { data: { id: id.id } })
    .then(({ data }) => data)
    .catch((response) => response);
};
