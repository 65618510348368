import React, { Component } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { addStudentImage } from '../../views/student/apiCalls';
import { BrowseImage } from "./BrowseImage";
import CaptureImage from "./CaptureImage";
import "./ProfileView.css";


export default class ProfileView extends Component {
  state={showModal:false}
  handelToggle=()=>{
    this.setState({showModal:!this.state.showModal})
  }
  uploadImageToServer = (datavalue) => {
    this.setState({ imgData: datavalue });

    function DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    }
    let selectedFile = {};
    selectedFile = DataURIToBlob(datavalue);
    const data = new FormData();
    data.append("image", selectedFile);
    data.append("media_type", 6);
    addStudentImage({ data }).then((response) => {
      if (response.code == 200) {
        // this.props.onImageAdded(response.data.id);
        const info = {
          message: response.message,
          status: "success",
        };
        // this.props.notificationAction(info);
      } else if (response.code != 200) {
        const info = {
          message: response.message,
          status: "error",
        };
        // this.props.notificationAction(info);
      }
    });
  };
  render() {
    const { onModalClose, onCsvChange,  onDone } = this.props;
    return (
      <div>
      <Modal
        isOpen={this.props.isOpen}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 200 }}
        toggle={onModalClose}
        size={"lg"}
        top
      >
        {/* <ModalHeader toggle={onModalClose}>{"Update Image"}</ModalHeader> */}
        <ModalBody className="modalBackground">
          <Row>
            <Col>
            <h4 className="img-sops text-center mr-5 ">Update Image</h4>
                <Row>
         
                  <Col className="pr-1">
                  <BrowseImage />
                  </Col>
                  <Col>
                  <Button onClick={this.handelToggle} color="primary" className="">
                  &nbsp; Capture Image
                </Button>
                  <CaptureImage isOpen={this.state.showModal} toggle={this.handelToggle} passingImage={this.uploadImageToServer} />
                  </Col>
                </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
           
              <Col>
                <h4 className="img-sops mr-auto">SOPs for Image</h4>
              </Col>
              <Col>
              {/* <Button onClick={onModalClose}  className="float-right"  color="primary"   >  &nbsp;  Continue</Button> */}
              </Col>
            
            <Row xs="1" sm="2" md="2">
              <Col>
                <p className="text-muted">1. Uncover your face</p>
              </Col>
              <Col>
                <p className="text-muted">2. Uncover your face</p>
              </Col>
              <Col>
                <p className="text-muted">3. Uncover your face</p>
              </Col>
              <Col>
                <p className="text-muted">4. Uncover your face</p>
              </Col>
             
            </Row>
          </ModalFooter>
      </Modal>
    </div>
    
    );
  }
}


