import React, { Component } from "react";
import "../../../../assets/sass/_common.scss";
import { notificationAction } from "../../../../redux/reducers/notification_reducer";
import { Message } from "../../../../utils/messages.js";
import { connect } from "react-redux";
import BranchForm from "./Form/BranchForm";
import { addBranch } from "../apiCalls";

class AddLocation extends Component {
  state = {
    sid: 0,
    selectedBranchInfo: {
      name: "",
    },
    validateBranch: {},
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "name"
    ) {
      this.validateField({ name, value });
      return;
    }
  };

  validateField = ({ name, value }) => {

    const { selectedBranchInfo, validateBranch } = this.state;
    if (name === "name"
    ) {
      this.setState({
        selectedBranchInfo: {
          ...selectedBranchInfo,
          // [`invalid${name}`]: !value,
          [name]: value,
        },
        validateBranch: {
          ...validateBranch,
          [`invalid${name}`]: !value,
          // [name]: value,
        },
      })
    }
  }
  handleSaveBranch = () => {
    let { selectedBranchInfo, validateBranch } = this.state;

    if (!selectedBranchInfo.name) {
      this.setState({
        validateBranch: {
          ...validateBranch,
          invalidname: true,
        },
      });
      const info = {
        message: Message.AddBranch,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    this.setState({
      Loading: true,
    });

    addBranch({ name: selectedBranchInfo })
      .then((response) => {
        if (response.code == 200) {
          const info = {
            message: Message.AddSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.history.push("/lookups/branch");
          return;
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        const info = {
          message: Message.AddFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };
  render() {
    return (
      <>
        <BranchForm
          selectedBranchInfo={this.state.selectedBranchInfo}
          onFormFieldChange={this.handleFormFieldChange}
          validateBranch={this.state.validateBranch}
          onSave={this.handleSaveBranch}
        />
      </>
    )


  }

}
const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});


export default connect(null, actions)(AddLocation);
