import {
  getClass,
  getSites,
  getDepartment,
  getImageStatus,
  getStatuses,
  getSections,
  getDynamicTypes,
  getLocationLookUp,
  getDevices,
  getAttendanceTypes
} from "../utils/lookup/apiCalls";
class HttpService {

  async getClassList() {
    let result = await getClass()
      .then((response) => response.data)
      .catch(console.error());
    return result;
  }
  async getSitesList() {
    let result = await getSites()
      .then((response) => response.data)
      .catch(console.error());
    return result;
  }
  async getLocationLookupList(id) {
    let result = await getLocationLookUp(id)
      .then((response) => response.data)
      .catch(console.error());
    return result;
  }
  async getDevicesList(id) {
    let result = await getDevices(id)
      .then((response) => response.data)
      .catch(console.error());
    return result;
  }
  async getDepartmentList() {
    let result = await getDepartment()
      .then((response) => response.data)
      .catch(console.error());
    return result;
  }

  async getImageStatusList() {
    let result = await getImageStatus()
      .then((response) => response.data)
      .catch(console.error());
    return result;
  }
  async getStatusesList() {
    let result = await getStatuses()
      .then((response) => response.data)
      .catch(console.error());
    return result;
  }
  async getAttendanceList(type) {
    let result = await getAttendanceTypes(type)
      .then((response) => response.data)
      .catch(console.error());
    return result;
  }
  async getSectionsList() {
    let result = await getSections()
      .then((response) => response.data)
      .catch(console.error());
    return result;
  }
  async getDynamicTypesList() {
    let result = await getDynamicTypes()
      .then((response) => response.data)
      .catch(console.error());
    return result;
  }



}
export default new HttpService();
