import { ApiUrls } from "../../../utils/apiUrls";
import authAxios from "../../../services/axiosInstance";

export const addLocation = ({ name }) => {
  return authAxios
    .post(ApiUrls.Lookups_Location.ADD_LOCATION, name)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const LocationDetails = (id) => {
  return authAxios
    .get(ApiUrls.Lookups_Location.LOCATION_DETAIL(id))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateLocation = ({ postData }) => {
  return authAxios
    .post(ApiUrls.Lookups_Location.UPDATE_LOCATION, postData)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const deleteLocation = ({ statusData }) => {
  return authAxios
    .post(ApiUrls.Lookups_Location.LOCATION_DELETE, statusData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const filterTableRecords = (
  per_page,
  page_no,
  order_by,
  order_type,
  name,
  branch_id,
  status,
  download
) => {
  return authAxios
    .get(ApiUrls.Lookups_Location.GET_LOCATION_LIST, {
      params: {
        per_page,
        page_no,
        order_by,
        order_type,
        name,
        branch_id,
        status,
        download
      },
    })
    .then(({ data }) => data)
    .catch((response) => response);
};

export const activeInActiveLocation = ({ statusData }) => {
  return authAxios
    .put(ApiUrls.Lookups_Location.ACTIVE_INACTIVE_LOCATION, statusData)
    .then(({ data }) => data)
    .catch((response) => response);
};