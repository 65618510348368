class storage {
    getItem = ({ key }) => {
      return localStorage.getItem(key);
    };
  
    setItem = ({ key, value }) => {
      return localStorage.setItem(key, JSON.stringify(value));
    };
  
    removeItem = ({ key }) => {
      localStorage.removeItem(key);
    };
  
    clear = () => {
      localStorage.clear();
    };
  }
  
  export default new storage();