import React, { useState } from "react";
import { Button, Row, Col } from "reactstrap";

import "./ProfileView.css";

export const BrowseImage = () => {
  const [picture, setPicture] = useState(false);
  const [imgData, setImgData] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <div>
      <div>
        <label for="file-upload" className="custom-file-upload">
          <i className="fa fa-cloud-upload"></i> Browse Image
        </label>
        <input id="file-upload" type="file" onChange={onChangePicture} />
      </div>
      {picture ? (
        <Row className="p-4">
          <Col xs={12} sm={6} xl={3} md={4}>
            <div className="headline-3 mb-4 text-muted">
              <img
                style={{ height: "100px", width: "100px" }}
                className="rounded-circle profileImage"
                src={imgData}
              ></img>
            </div>
          </Col>
     
        </Row>
      ) : (
        ""
      )}
          {picture ? (
      <Row className="p-4">
          <Col xs={12} sm={6} xl={3} md={4}>
           <Button>Continue</Button>
          </Col>
     
        </Row>
        ) : (
          ""
        )}
    </div>
  );
};
