import { ApiUrls } from "../../utils/apiUrls";
import authAxios from "../../services/axiosInstance";

export const filterTableRecords = (
  per_page,
  page_no,
  order_by,
  order_type,
  name,
  location,
  status,
  download
) => {
  return authAxios
    .get(ApiUrls.Devices.GET_DEVICE_LIST, {
      params: {
        per_page,
        page_no,
        order_by,
        order_type,
        name,
        location,
        status,
        download
      },
    })
    .then(({ data }) => data)
    .catch((response) => response);
};


export const DeviceDetails = (id) => {
  return authAxios
    .get(ApiUrls.Devices.DEVICE_DETAIL(id))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateDevice = ({ postData }) => {
  return authAxios
    .put(ApiUrls.Devices.UPDATE_DEVICE, postData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updatePassword = ({ postData }) => {
  return authAxios
    .put(ApiUrls.Devices.UPDATE_PASSWORD, postData)
    .then(({ data }) => data)
    .catch((response) => response);
};



export const deleteDevice = ({ id }) => {
  return authAxios
    .post(ApiUrls.Devices.DEVICE_SOFT_DELETE, id)
    .then(({ data }) => data)
    .catch((response) => response);
};


export const activeInActiveDevice = ({ statusData }) => {
  return authAxios
    .put(ApiUrls.Devices.ACTIVE_INACTIVE_DEVICES, statusData)
    .then(({ data }) => data)
    .catch((response) => response);
};