import React from "react";
import { Col, Row, Button } from "reactstrap";
import Table from "../../../components/Table/Table";
import AbsentFilter from "./AbsentFilter";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../redux/actions/types";
import LoadingMask from "../../../components/Loader/LoadingMask";
import ImageModel from "../../../components/image/ImageModel";

import { connect } from "react-redux";
import { getAllAbsentList } from "./apiCalls";

class StaffAbsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureModal: false,
      imageUrl: "",
      selectedDate: "",
      person_type: "7",
      paginationData: [],
      toggle: false,
      detectionList: [],
      selectedDetectionFilters: {
        person_number: "",
        person_name: "",
        department: null,
        selected_date:""
      },
      selectedValues: {
        selected_department: { label: "All", value: null },
      },
      download: true,
    };
  }

  componentDidMount=async ()=> {
    await this.props.RecordPerPage(10)
    await this.props.PageNo(1);
    await   this.handleFilterDataTableList();
     
   }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      this.handleFilterDataTableList();
    }
    if (prevProps.pageno !== this.props.pageno) {
      this.handleFilterDataTableList();
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      this.props.PageNo(1);
      this.handleFilterDataTableList();
    }
  }

  handleDetectionClear = (e) => {
  

    this.setState(
      {
        selectedDetectionFilters: {
          person_number: "",
          person_name: "",
          department: null,
          selected_date:""
        },
        selectedValues: {
          selected_department: { label: "All", value: null },
        },
      },
      () => this.handleFilterDataTableList()
    );
    this.props.PageNo(1);
    this.props.RecordPerPage(10);
  };

  handleApplyFilter = () => {
    // this.props.Loading(true);
    this.props.PageNo(1);
    this.handleFilterDataTableList();
  };

  handleFilterFieldChange = (e) => {
    const { name, value } = e.target;
    const { selectedDetectionFilters } = this.state;

    if (name === "person_name" || name === "person_number") {
      this.setState({
        selectedDetectionFilters: {
          ...selectedDetectionFilters,
          [name]: value,
        },
      });
    }
  };

  handelDropDownChange = (selectedOption, obj) => {
    const { selectedValues, selectedDetectionFilters } = this.state;
    const { name } = obj;
    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (obj.name === "department") {
        this.setState({
          selectedValues: {
            ...selectedValues,
            selected_department: defaultOption,
          },
          selectedDetectionFilters: {
            ...selectedDetectionFilters,
            department: null,
          },
        });
      }
      return;
    }

    this.setState({
      selectedValues: {
        ...selectedValues,
        selected_department: selectedOption,
      },
      selectedDetectionFilters: {
        ...selectedDetectionFilters,
        [name]: selectedOption.id,
      },
    });
  };
  DateToFormattedString(d) {
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 1).toString();
    var dd = d.getDate().toString();
    return (
      yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0])
    );
  }

  handleDateChange = (d) => {
    const {selectedDetectionFilters}=this.state;
    let result = d && this.DateToFormattedString(d);
    this.setState({
      selectedDetectionFilters: {
        ...selectedDetectionFilters,
        selected_date: result,
      },
      selectedDate: d,
    });
  };
  handleExportCsv = () => {
    const { person_type } = this.state;
    const { person_number, person_name, department ,selected_date} =
      this.state.selectedDetectionFilters;
    getAllAbsentList(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      person_name ? person_name.replace(/^\s+|\s+$/g, "") : null,
      person_number ? person_number.replace(/^\s+|\s+$/g, "") : null,
      department ? department : null,
      selected_date ?selected_date :null,
      person_type ? person_type : null,
      this.state.download ? 1 : null
    )
      .then((response) => {
        if (response.code == 200) {
          if (response.data.file_url != null) {
            window.open(response.data.file_url, "_blank");
          }
        }
      })
      .catch((error) => console.log("Erorr in getting staffs ", error));
  };
  handleFilterDataTableList = async () => {
    const { person_type } = this.state;
    this.setState({ appLoadingState: true });

    const { person_number, person_name, department,selected_date } =
      this.state.selectedDetectionFilters;
    await getAllAbsentList(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      person_name ? person_name.replace(/^\s+|\s+$/g, "") : null,
      person_number ? person_number.replace(/^\s+|\s+$/g, "") : null,
      department ? department : null,
      selected_date ?selected_date :null,
      person_type ? person_type : null
    )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            detectionList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState: false,
          });
        } else if (response.code != 200) {
          this.setState({ appLoadingState: false });
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        } else {
        }
      })
      .catch((error, response) => {
        const info = {
          message: error.message,
          status: "error",
        };

        this.props.notificationAction(info);
      });
  };
 
  handelShowPictureModal = (item) => {
    this.setState({
      pictureModal: item && !this.state.pictureModal,
      imageUrl: item,
    });
  };
  

  render() {
    let template;

    template = (
      <div>
        <Row>
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={6} lg={6}></Col>
          <Col md={6} lg={6} >
            </Col>

        </Row>
        <AbsentFilter
          SelectedValues={this.state.selectedValues}
          onDDChange={this.handelDropDownChange}
          onDateChange={this.handleDateChange}
          selectedDate={this.state.selectedDate}
          selectedDetectionFilters={this.state.selectedDetectionFilters}
          onFilterReset={this.handleDetectionClear}
          onExportCsv={this.handleExportCsv}
          onFilterFieldChange={this.handleFilterFieldChange}
          onFilterDetection={this.handleApplyFilter}
        />
        <ImageModel
          show={this.state.pictureModal}
          imageUrl={this.state.imageUrl}
          onModalClose={this.handelShowPictureModal}
        />

        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            {this.state.detectionList && this.state.detectionList.length > 0 ? (
              <Table
                data={this.state.detectionList}
                isImageEnabled={true}
                isImageCircle={true}
                isImageSquare={[true, false]}
                isCheckOption={false}
                onShowPicture={this.handelShowPictureModal}
                listItemToDisplay={[
                  { name: "Staff", valueField: "name" },
                  { name: "Employee #", valueField: "emp_number" },
                  { name: "Department", valueField: "department" },
                  { name: "Attendance Status", valueField: "attendance_type" },
                  { name: "RFID", valueField: "rfid" },

                ]}
                paginationData={this.state.paginationData}
                buttonsToDisplay={[]}
              />
            ) : (
              <div className="text-center">
                There is no data available to show
              </div>
            )}
          </Col>
        </Row>
      </div>
    );

    return (
      <div className="">{template} </div>);
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
  SortName: (sortName) => dispatch({ type: ActionTypes.SORT_NAME, sortName }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName,
});

export default connect(mapStateToProps, actions)(StaffAbsent);
