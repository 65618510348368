import { ApiUrls } from "../../../utils/apiUrls";
import authAxios from "../../../services/axiosInstance";
import axios from "axios";
export const getAllDetectionsList = (
  per_page,
  page_no,
  order_by,
  order_type,
  person_name,
  registration,
  person_class,
  section,
  device_id,
  location,
  ignored,
  selectedDateRange,
  person_type,
  download
) => {
  return authAxios
    .get(ApiUrls.Detection.GET_ALL_DETECTIONS, {
      params: {
        per_page,
        page_no,
        order_by,
        order_type,
        person_name,
        registration,
        person_class,
        section,
        device_id,
        location,
        ignored,
        selectedDateRange,
        person_type,
        download,
      },
    })
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getDetectionById = (id) => {
  return axios
    .get(ApiUrls.Detection.GET_DETECTION_BY_ID(id))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const ignoreUnIgnoreDetection = ({payload}) => {
  return axios
    .post(ApiUrls.Detection.IGNORE_UNIGNORE_DETECTION, payload)
    .then(({ data }) => data)
    .catch((response) => response);
};

