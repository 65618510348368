import React, { Component } from "react";
import {
    Button,
    Modal,
    Row,
    Col,
} from "reactstrap";
import "react-image-crop/dist/ReactCrop.css";
import { AiFillWarning } from "react-icons/ai";
import { MdClose } from "react-icons/md"

class SweetAlert extends Component {
    

    render() {
        const {
        deleteRecord}=this.props;
        
        return (
            <div>
                <div>
                    <Modal
                        className="modal-dialog modal-content rounded Model-shape "
                        isOpen={this.props.show}
                        size="xs"
                    >
                        <Row>
                            <Col sm="12">
                                <p className="float-right mt-4 mr-5" onClick={()=>deleteRecord(false)}>  <MdClose size={30} color="black" /></p>
                                <p className="text-center mt-4 ml-4">  <AiFillWarning className="ml-5" size={100} color="#CE3939" /></p>
                                <h4 className="text-center mt-3 font-weight-bold">Are you sure?</h4>
                                <p className="text-center my-3 text-muted">Do you really want to <span class="font-weight-bold">delete</span> selected record/s?</p>
                                <Row sm="12" md={"8"} className=" justify-content-around justify-content-center p-3 mb-4">
                                    <Col className="ml-3">
                                        <Button className="btn-block" outline onClick={()=>{deleteRecord(false)}}>Cancel</Button>
                                    </Col>
                                    <Col className="mr-3">
                                        <Button className="btn-block " color="danger" onClick={()=>{deleteRecord(true)}}>Delete</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default SweetAlert;
