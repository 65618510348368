import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import { withRouter } from "react-router";
import "../userForm.scss";
import { MdRemoveRedEye, MdVisibilityOff } from "react-icons/md";

class UsersForm extends Component {
  state = {
    showPassword: false,
  };
  handelGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };
  render() {
    let isStudent = process.env.REACT_APP_IS_STUDENT;
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const UserInfo = localStorage.getItem("user");

    const {
      staff_perms,
      users_perms,
      devices_perms,
      lookups_perms,
      student_perms,
      staff_reports_perms,
      students_reports_perms,
    } = permissions;
    const {
      selectedUserInfo,
      onFormFieldChange,
      onSave,
      validateUser,
      onCheckboxChange,
      selectedCheckBoxes,
      isEdit,
    } = this.props;
    const { username, person_email, firstname, lastname, password } =
      selectedUserInfo;

    const {
      invalidusername,
      invalidperson_email,
      invalidfirstname,
      invalidlastname,
      invalidpassword,
    } = validateUser;
    const { showPassword } = this.state;
    const {
      student_checked,
      students_reports_checked,
      staff_checked,
      staff_reports_checked,
      devices_checked,
      lookups_checked,
      users_checked,
    } = selectedCheckBoxes;
    return (
      <div>
        <div className="content">
          <Row>
            <Col className="ml-3" md={3} lg={3}></Col>
            <Col lg="12" md="12" sm="12">
              <Card className="card-user p-5">
                <CardTitle tag="h4" className="p-4">
                  User Information
                </CardTitle>
                <CardBody>
                  <Form>
                    {!isEdit ? (
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label className="form-label required">
                              {"First Name"}
                            </label>
                            <Input
                              type="text"
                              autoFocus={invalidfirstname}
                              className={`input-text-field  form-label" ${
                                invalidfirstname ? "inValid" : ""
                              }`}
                              name="firstname"
                              maxLength={"30"}
                              value={firstname}
                              onChange={onFormFieldChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label className="form-label required">
                              {"Last Name"}
                            </label>
                            <Input
                              type="text"
                              autoFocus={invalidlastname}
                              className={`input-text-field  form-label" ${
                                invalidlastname ? "inValid" : ""
                              }`}
                              name="lastname"
                              maxLength={"30"}
                              value={lastname}
                              onChange={onFormFieldChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label required">
                            {"Username"}
                          </label>
                          <Input
                            type="text"
                            autoFocus={invalidusername}
                            className={`input-text-field  form-label" ${
                              invalidusername ? "inValid" : ""
                            }`}
                            name="username"
                            maxLength={"30"}
                            value={username}
                            onChange={onFormFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label required">
                            {"Email"}
                          </label>
                          <Input
                            type="text"
                            autoFocus={invalidperson_email}
                            className={`input-text-field  form-label" ${
                              invalidperson_email ? "inValid" : ""
                            }`}
                            name="person_email"
                            maxLength={"40"}
                            value={person_email}
                            onChange={onFormFieldChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {!isEdit ? (
                      <Row>
                        {" "}
                        <Col className="pr-1" md="6">
                          <label className="form-label required">
                            {"Password"}
                          </label>
                          <div className="input-icons">
                            <i
                              onClickCapture={() => {
                                this.setState({ showPassword: !showPassword });
                              }}
                              className="icon"
                            >
                              {showPassword ? (
                                <MdRemoveRedEye size={22} />
                              ) : (
                                <MdVisibilityOff size={22} />
                              )}
                            </i>
                            <Input
                              type={showPassword ? "text" : "password"}
                              className={`input-text-field   form-label" ${
                                invalidpassword ? "inValid" : ""
                              }`}
                              maxLength={30}
                              name="password"
                              autoComplete="new-password"
                              value={password}
                              onChange={onFormFieldChange}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                    <h4>Permissions</h4>

                    <Row>
                      {student_perms || UserInfo == "superadmin"}
                      <Col className="pr-1 mt-2" md="6">
                        <FormGroup check>
                          <Label
                            className="permission-checkbox permission-label"
                            check
                          >
                            <Input
                              type="checkbox"
                              checked={student_checked}
                              name="student"
                              onClick={(e) => onCheckboxChange(e)}
                            />{" "}
                            Student Actions
                          </Label>
                        </FormGroup>
                      </Col>
                      {students_reports_perms || UserInfo == "superadmin" ? (
                        <Col className="pr-1" md="6">
                          <FormGroup check>
                            <Label
                              className="permission-checkbox permission-label"
                              check
                            >
                              <Input
                                type="checkbox"
                                name="students_reports"
                                checked={students_reports_checked}
                                onClick={(e) => onCheckboxChange(e)}
                              />{" "}
                              Student Reports
                            </Label>
                          </FormGroup>
                        </Col>
                      ) : null}
                    
                   

                      {staff_perms || UserInfo == "superadmin" ? (
                        <Col className="pr-1 mt-2" md="6">
                          <FormGroup check>
                            <Label
                              className="permission-checkbox permission-label"
                              check
                            >
                              <Input
                                type="checkbox"
                                name="staff"
                                checked={staff_checked}
                                onClick={(e) => onCheckboxChange(e)}
                              />
                              Staff Actions
                            </Label>
                          </FormGroup>
                        </Col>
                      ) : null}
                      {staff_reports_perms || UserInfo == "superadmin" ? (
                        <Col className="pr-1" md="6">
                          <FormGroup check>
                            <Label
                              className="permission-checkbox permission-label"
                              check
                            >
                              <Input
                                type="checkbox"
                                name="staff_reports"
                                checked={staff_reports_checked}
                                onClick={(e) => onCheckboxChange(e)}
                              />{" "}
                              Staff Reports
                            </Label>
                          </FormGroup>
                        </Col>
                      ) : null}
                  
                      {devices_perms || UserInfo == "superadmin" ? (
                        <Col className="pr-1 mt-2" md="6">
                          <FormGroup check>
                            <Label
                              className="permission-checkbox permission-label"
                              check
                            >
                              <Input
                                type="checkbox"
                                name="devices"
                                checked={devices_checked}
                                onClick={(e) => onCheckboxChange(e)}
                              />{" "}
                              Devices
                            </Label>
                          </FormGroup>
                        </Col>
                      ) : null}
                      {lookups_perms || UserInfo == "superadmin" ? (
                        <Col className="pr-1 " md="6">
                          <FormGroup check>
                            <Label
                              className="permission-checkbox permission-label"
                              check
                            >
                              <Input
                                type="checkbox"
                                name="lookups"
                                checked={lookups_checked}
                                onClick={(e) => onCheckboxChange(e)}
                              />{" "}
                              Lookups
                            </Label>
                          </FormGroup>
                        </Col>
                      ) : null}
                  
                      <Col className="pr-1 mt-2" md="6">
                        <FormGroup check>
                          <Label
                            className="permission-checkbox permission-label"
                            check
                          >
                            <Input
                              type="checkbox"
                              name="users"
                              checked={users_checked}
                              onClick={(e) => onCheckboxChange(e)}
                            />{" "}
                            Users
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4} lg={3} sm={12} xs={12} className="mt-3">
                        <Button
                          className="mb-3 btn-block"
                          outline
                          color="primary"
                          onClick={() => this.handelGoBack()}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col lg={6} md={4} sm={12} xs={12} />
                      <Col lg={3} sm={12} md={4} xs={12} className="mt-3">
                        <Button
                          className="btn-round btn-block float-right  "
                          color="primary"
                          onClick={onSave}
                          type="button"
                        >
                          {isEdit ? "Update User" : "Add User"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(UsersForm);
