export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const COLLAPSE = 'COLLAPSE';
export const IS_EXPANDED = 'IS_EXPANDED';
export const CHANGE_ACTIVE_SIDEBAR_ITEM = 'CHANGE_ACTIVE_SIDEBAR_ITEM';

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR,
  };
}

export function closeSidebar() {
  return {

    type: CLOSE_SIDEBAR,
  };
}
export function changeIsCollapsed() {
  return {
    type: COLLAPSE,
  };
}
export function changeIsExpanded() {
  return {
    type: IS_EXPANDED,
  };
}

export function changeActiveSidebarItem(activeItem) {
  return {
    type: CHANGE_ACTIVE_SIDEBAR_ITEM,
    activeItem,
  };
}
