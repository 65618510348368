import { ApiUrls } from "../../../utils/apiUrls";
import authAxios from "../../../services/axiosInstance";
export const getMAttendanceList = (
    per_page,
    page_no,
    order_by,
    order_type,
    query,
    person_type,
  ) => {
    return authAxios
    .get(ApiUrls.Attendance.GET_MANUAL_ATTENDANCE_LIST,{
        params: {
          per_page,
          page_no,
          order_by,
          order_type,
          query,
          person_type,
        },
      })
      .then(({ data }) => data)
      .catch((response) => response);
  };
  export const addManualAttendance = ({payload}) => {
    return authAxios
      .post(ApiUrls.Attendance.ADD_MANUAL_ATTENDANCE, payload)
      .then(({ data }) => data)
      .catch((response) => response);
  };