import React from "react";
import { connect } from "react-redux";
import Pagination from "./Pagination";
import "./Table.scss";
import "./UserRoles.scss"
import * as ActionTypes from "../../redux/actions/types";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import {
  Button,
  Col,
  Row,
  Table,
} from "../../../node_modules/reactstrap/es/index";
import s from "./Table.module.scss";
import Widget from "../../components/Widget/Widget.js";
import Active from "../../assets/employee/Active.svg";
import InActive from "../../assets/employee/InActive.svg";
import Enabled from "../../assets/table/Enabled.png";
import Disabled from "../../assets/table/Disabled.svg";
import { Fragment } from "react";
import Tooltip from "../Tooltip/ToolTip";

let selectedRecord = 0;

let orderByAsc = true;
class DataTable extends React.Component {
  state = {
    filteredData: [],
    noOfRecordPerPage: null,
    listItemToPrint: [],
    onPagination: true,
    sortDesc: false,
    sortName: "",
  };

  //lifecycle methods
  componentDidMount = () => {
    this.displayCurrentPageRecord(0, this.props.recordperpage);
    const listItemToPrint = this.props.data.map((item) => {
      return this.props.listItemToDisplay.map((nes) => item[nes.valueField]);
    });
    this.setState({
      listItemToPrint,
    });
    if (this.props.data.length < 1) {
      this.setState({ onPagination: false });
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const listItemToPrint = this.props.data.map((item) => {
        return this.props.listItemToDisplay.map((nes) => item[nes.valueField]);
      });
      this.setState({
        listItemToPrint,
      });
      this.displayCurrentPageRecord(0, this.props.paginationData.per_page);
    }
    if (prevProps.data.length !== this.props.data.length) {
      if (this.props.data.length < 1) {
        this.setState({ onPagination: false });
      }
    }
  }

  displayCurrentPageRecord = (startFrom, noOfRecordPerPageValue) => {
    let noOfRecordPerPageVal;
    if (noOfRecordPerPageValue != undefined) {
      noOfRecordPerPageVal = noOfRecordPerPageValue;
    } else {
      this.setState({
        noOfRecordPerPage: noOfRecordPerPageVal,
      });
    }
    var items = this.props.data.slice(
      startFrom * noOfRecordPerPageVal,
      +noOfRecordPerPageVal + startFrom * +noOfRecordPerPageVal
    );
    this.setState({
      filteredData: items,
    });
  };
  toggleKeyword = () => {
    orderByAsc = !orderByAsc;
    if (orderByAsc) {
      return "asc";
    } else return "desc";
  };
  //Sorting
  handleSorting = (sortByName) => {
    let sortValue = this.toggleKeyword();
    this.props.SortBy(sortValue);
    this.props.SortName(sortByName);
    this.setState({
      sortDesc: !this.state.sortDesc,
      sortName: sortByName,
    });
  };
  findSelectedRecord = () => {
    const listArray = [];
    this.props.data.map((item) => {
      if (item.Checked == true) {
        listArray.push([
          item.employee_name,
          item.image_path,
          item.employee_code,
        ]);
      }
    });
    return listArray;
  };
  handleNoOfRecordPerPageChange = (event) => {
    this.setState({
      noOfRecordPerPage: event.target.value,
    });
    this.displayCurrentPageRecord(
      event.target.value - event.target.value,
      event.target.value
    );
    this.props.onRecordPerPage(parseInt(event.target.value));
  };

  handleBulkList = () => {
    const listArray = [];
    this.props.data.map((item) => {
      if (item.Checked == true) {
        listArray.push(item.id);
      }
    });
    return listArray;
  };

  handleBulkOperation = (action) => {
    const list = this.handleBulkList();
    let BulkList = {};
    BulkList = { data: list, action: action };
    this.props.onGettingBulkRecords(BulkList);
  };

  render() {
    if (this.props.isCheckOption == false) {
      selectedRecord = 0;
    }
    const {
      listItemToDisplay,
      buttonsToDisplay,
      toggleButtonToDisplay = false,
      onTableButtonClick,
      onToggleStatus,
      paginationData,
      noOfRecordsPerPageOptions = [10, 20, 30, 50, 100],
      isCheckOption = false,
      onCheckOptionToggle,
      allChecked = false,
    } = this.props;

    const { onPagination } = this.state;
    const { current_page, per_page, total_count, total_pages } = paginationData;
    let startingPage = 1;
    startingPage = per_page * current_page - per_page + 1;

    let endingPage = 1;
    let diff1 = 1;
    let diff2 = 1;
    let totalPages = total_pages * per_page;
    let cpage = current_page * per_page;
    if (cpage > total_count) {
      diff1 = totalPages - total_count;
      diff2 = total_count - diff1;
      endingPage = diff1 + diff2;
    } else {
      endingPage = current_page * per_page;
    }
    const headerClass = [
      "text-dark py-2 pr-4 m-0",
      current_page ? "border-gray border-right" : "",
    ]
      .join(" ")
      .trim();
    if (this.props.isCheckOption) {
      let checkedRecords = this.findSelectedRecord();
      selectedRecord = checkedRecords.length;
    }
    let selectedOptionsBar = "";
    if (isCheckOption) {
      selectedOptionsBar = (
        <Fragment>
          <Button
            onClick={() => this.handleBulkOperation("delete")}
            className="m-2"
            color="danger"
          >
            {"Delete"}
          </Button>
          <Button
            onClick={() => this.handleBulkOperation("inactive")}
            className="m-2"
            color="danger"
          >
            {"Inactive"}
          </Button>
          <Button
            onClick={() => this.handleBulkOperation("activate")}
            className="m-2"
            color="success"
          >
            {"Activate"}
          </Button>
        </Fragment>
      );
    }
    return (
      <Row>
        <Col>
          <Row></Row>

          {selectedRecord > 0 ? (
            <Row
              style={{ backgroundColor: "#101C30" }}
              className=" table-container rounded"
            >
              <Col lg={6} md={6} sm={12} className="float-left">
                <p className="text-light mt-3">
                  {" "}
                  Selected Records {selectedRecord}
                </p>
              </Col>
              <Col lg={6} md={6} sm={12} className="text-right ">
                {selectedOptionsBar}
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row className="mb-4">
            <Col>
              <Widget>
                <div
                  className="widget-table-overflow table-responsive"
                  id="example"
                >
                  <Table
                    className={`table-striped table-borderless   table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead
                      style={{
                        wordBreak: "keep-all",
                        whiteSpace: "nowrap",
                        backgroundColor: "#E7EBF6",
                        textAlign: "center",
                        position: "sticky",
                      }}
                    >
                      <tr>
                        {isCheckOption && (
                          <th className={s.checkboxCol}>
                            <div className=" checkbox-primary">
                              <input
                                type="checkbox"
                                className="styled"
                                id="checkbox100"
                                checked={allChecked}
                                onClick={() => onCheckOptionToggle(null)}
                              />
                            </div>
                          </th>
                        )}

                        {listItemToDisplay.map((item) => (
                          <th
                            className="font-weight-bold"
                            key={item.name}
                            onClick={() => this.handleSorting(item.valueField)}
                          >
                            {this.state.sortName == item.valueField ? (
                              this.state.sortDesc ? (
                                <>
                                  {item.name}
                                  <FaLongArrowAltUp />
                                </>
                              ) : (
                                <>
                                  {item.name}
                                  <FaLongArrowAltDown />
                                </>
                              )
                            ) : (
                              item.name
                            )}
                          </th>
                        ))}

                        {toggleButtonToDisplay && (
                          <th className="font-weight-bold">Status</th>
                        )}
                        {buttonsToDisplay && (
                          <th className="actionHead text-center font-weight-bold">
                            {buttonsToDisplay.length > 0 ? "Action" : null}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody
                      className="table-body"
                      style={{ textAlign: "center" }}
                    >
                      {this.state.filteredData.map((item, index) => (
                        <tr style={{ wordBreak: "keep-all" }} key={index}>
                          {isCheckOption && (
                            <td>
                              <input
                                type="checkbox"
                                checked={item.Checked}
                                onClick={() => onCheckOptionToggle(item)}
                              />
                            </td>
                          )}

                          {listItemToDisplay.map((nes, index) => (
                            <Fragment>
                              {nes.valueField === "username" ||
                              nes.valueField === "email" ? (
                                <td key={index} data-label={nes.index}>
                                  <span >
                                    {" "}
                                    {`${item[nes.valueField]}`}
                                  </span>
                                </td>
                              ) : (
                                <td style={{ textAlign: "center" }}>
                                  <span>
                                    {item[nes.valueField] == true ? (
                                      <img src={Enabled}></img>
                                    ) : (
                                      <img src={Disabled}></img>
                                    )}
                                  </span>
                                </td>
                              )}
                            </Fragment>
                          ))}

                          {toggleButtonToDisplay && (
                            <td key={index} className="img-btn-col">
                              <img
                                onClick={() => onToggleStatus(item)}
                                src={item.is_active == true ? Active : InActive}
                              ></img>
                            </td>
                          )}
                          {  buttonsToDisplay ?
                          <td className="flex-parent actionHeading">
                          
                             { buttonsToDisplay.map((btn, index) => (
                                <td key={index} className="img-btn-col">
                                  <Tooltip
                                    content={btn.Tooltipmessage}
                                    direction="top"
                                  >
                                    <a
                                      btn
                                      style={
                                        btn.color === undefined
                                          ? { color: "#286AD9" }
                                          : { color: `${btn.color}` }
                                      }
                                      alt="btn"
                                      onClick={() =>
                                        onTableButtonClick(item, btn.name)
                                      }
                                    >
                                      {btn.icon}
                                    </a>
                                  </Tooltip>
                                </td>
                              ))}
                          </td> : null}
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {onPagination ? (
                    <Fragment>
                      <div className="w-100 px-2 py-1 d-flex flex-row flex-wrap align-items-center justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <p className={headerClass}>
                            Records per page:
                            <select
                              className="table-record-select"
                              value={this.props.recordperpage}
                              onChange={this.handleNoOfRecordPerPageChange}
                            >
                              {noOfRecordsPerPageOptions.map((item) => (
                                <option key={item}>{item}</option>
                              ))}
                            </select>{" "}
                          </p>
                        </div>

                        <div className="d-flex flex-row pr-4 align-items-center">
                          <Pagination
                            totalRecords={total_count}
                            pageLimit={parseInt(per_page)}
                            currentPage={current_page}
                            totalPages={total_pages}
                            pageNeighbours={total_pages > 99 ? 2 : 1}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row pr-4 pl-3 align-items-center">
                        <span className="flex-child text-dark font-weight-normal">
                          Showing {startingPage} - {endingPage} of {total_count}{" "}
                          records
                        </span>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  onRecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  SortBy: (sortBy) => dispatch({ type: ActionTypes.SORT_BY, sortBy }),
  SortName: (sortName) => dispatch({ type: ActionTypes.SORT_NAME, sortName }),
});
const mapStateToProps = (state) => ({
  sortby: state.tab.sortBy,
  sortname: state.tab.SortName,
  recordperpage: state.tab.recordperpage,
});
export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
