import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { withRouter } from "react-router";

class DepartmentForm extends Component {
  



  handelGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };
  render() {
    const {
      selectedDepartmentInfo,
      onFormFieldChange,
      onSave,
      validateDepartment,
      onDepartmentUpdate,
    } = this.props;
    const {
      name,
     
    } = selectedDepartmentInfo;

    const {
      invalidname,

    } = validateDepartment;
    return (
      <div>
        <div className="content">
          <Row>
            {/* <Col md="4"></Col> */}
            <Col lg="12" md="12" sm="12">
              <Card className="card-user">
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label required">
                            {"Department Name"}
                          </label>
                          <Input
                            type="text"
                            autoFocus={invalidname}
                            placeholder={"Enter department name.."}
                            className={`input-text-field  form-label" ${
                              invalidname ? "inValid" : ""
                            }`}
                            name="name"
                            value={name}
                            onChange={onFormFieldChange}
                            maxLength={"20"}

                          />
                        </FormGroup>
                      </Col>
                    </Row>
    
                    <Row>
                      <Col md={4} lg={3} sm={12} xs={12}>
                        <Button
                          className="mb-3 btn-block"
                          outline
                          color="primary"
                          onClick={() => this.handelGoBack()}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col lg={6} md={4} sm={12} xs={12} />
                      <Col lg={3} sm={12} md={4} xs={12}>
                        {!this.props.formBranchAdd ?
                         <Button
                         className="btn-round btn-block float-right  "
                         color="primary"
                         onClick={() => onDepartmentUpdate()}
                         type="button"
                       >
                         Save Changes
                         </Button>
                       :
                         <Button
                         className="btn-round btn-block float-right  "
                         color="primary"
                         onClick={() => onSave()}
                         type="button"
                       >
                         Add Department
                       </Button>
                        }
                       
                      </Col>
                    </Row> 
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    
    );
  }
}



export default (withRouter(DepartmentForm));
