// LocalStorageService.js
const LocalStorageService = (function(){
    var _service;
    function _getService() {
        if(!_service) {
          _service = this;
          return _service
      }
      return _service
    }
   async function _setToken(tokenObj) {
      localStorage.setItem("access_token", tokenObj.access);
      localStorage.setItem("refresh_token", tokenObj.refresh);
    }
    function _getAccessToken() {
      return localStorage.getItem("access_token");
    }
    function _setUserInfo(user) {
      localStorage.setItem("user", user);
    }
    function _setAccessToken(token) {
      localStorage.setItem("access_token", token);
    }
    function _getRefreshToken() {
      return localStorage.getItem("refresh_token");
    }
    function _clearToken() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    }
   return {
      getService : _getService,
      setToken : _setToken,
      setAccessToken : _setAccessToken,
      getAccessToken : _getAccessToken,
      setUserInfo :_setUserInfo,
      getRefreshToken : _getRefreshToken,
      clearToken : _clearToken
    }
   })();
   export default LocalStorageService;