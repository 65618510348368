import {
  OPEN_SIDEBAR,
  COLLAPSE,
  IS_EXPANDED,
  CLOSE_SIDEBAR,
  CHANGE_ACTIVE_SIDEBAR_ITEM,
} from "../actions/navigationAction";

const initialState = {
  sidebarOpened: false,
  isCollapse: false,
  isExpanded: false,

  // activeItem: window.location.pathname,
  activeItem: JSON.parse(localStorage.getItem("staticSidebar"))
    ? window.location.pathname
    : null,
};

export default function runtime(state = initialState, action) {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: true,
      });
    case CLOSE_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: false,
      });
    case COLLAPSE:
      return Object.assign({}, state, {
        isCollapse: !state.isCollapse,
      });
    case IS_EXPANDED:
      return Object.assign({}, state, {
        isExpanded: !state.isExpanded,
      });

    case CHANGE_ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        activeItem: action.activeItem,
      };
    default:
      return state;
  }
}
