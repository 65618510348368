import { ApiUrls } from "../apiUrls";
import authAxios from "../../services/axiosInstance";

export const getClass = () => {
  return authAxios
    .get(ApiUrls.Lookups.GET_ALL_CLASS_LIST)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getSites = () => {
  return authAxios
    .get(ApiUrls.Lookups.GET_SITES_LIST)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getDevices = (id) => {
  return authAxios
    .get(ApiUrls.Lookups.GET_DEVICE_LOOKUP(id))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getLocationLookUp = (id) => {
  return authAxios
    .get(ApiUrls.Lookups.GET_LOCATION_LOOKUP_LIST(id))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getDepartment = () => {
  return authAxios
    .get(ApiUrls.Lookups.GET_DEPARTMENTS_LIST)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getDynamicTypes = () => {
  return authAxios
    .get(ApiUrls.Lookups.GET_DYNAMIC_TYPES)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getImageStatus = () => {
  return authAxios
    .get(ApiUrls.Lookups.GET_IMAGE_STATUSES_LIST)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getStatuses = () => {
  return authAxios
    .get(ApiUrls.Lookups.GET_STATUSES_LIST)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getAttendanceTypes = (type) => {
  return authAxios
    .get(ApiUrls.Lookups.GET_ATTENDANCE_TYPE, {
      params: {type},
    })
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getSections = () => {
  return authAxios
    .get(ApiUrls.Lookups.GET_SECTION)
    .then(({ data }) => data)
    .catch((response) => response);
};