import React from "react";
import { LocationDetails, updateLocation } from "../apiCalls";
import { notificationAction } from "../../../../redux/reducers/notification_reducer";
import HttpService from "../../../../services/HttpService";
import { Message } from "../../../../utils/messages.js";
import { connect } from "react-redux";
import LocationForm from "./Form/LocationForm";
import LoadingMask from "../../../../components/Loader/LoadingMask";
class EditLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: props.match.params.id,
      locationList: [],
      validateLocation: {},
      passData: false,
      selectedLocationInfo: {},
      selectedBranch: {},
      branchOptions: [],
    };
  }

  componentDidMount() {
    let id = this.state.id;

    LocationDetails(id)
      .then((response) => {
        if (response.code == 200) {
          this.setState(
            {
              selectedLocationInfo: response.data,
              loading: false,
            },
            () => this.handleLocationEdit(response.data)
          );
        } else if (response.code != 200) {
          this.setState({loading:false})
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.getLookUp();
  }
  async getLookUp() {
    this.setState({ branchOptions: await HttpService.getSitesList() });
  }

  handeBranchChange = (selectedOption, obj) => {
    const { selectedLocationInfo, validateLocation } = this.state;
    if (!selectedOption) {
      return;
    }

    const { value, id } = selectedOption;
    const { name } = obj;
    if (obj.name === "branch") {
      this.setState({
        selectedLocationInfo: {
          ...selectedLocationInfo,
          branch_id: id,
        },
        selectedBranch: selectedOption,
        validateLocation: {
          ...validateLocation,
          [`invalid${name}`]: !value,
        },
      });
    }
  };
  findArrayElementByTitle(array, title) {
    return array.find((element) => {
      return (
        element.value.toLowerCase() === title.toLowerCase() ||
        element.label.toLowerCase() === title.toLowerCase()
      );
    });
  }

  handleUpdateLocation = () => {
    let { selectedLocationInfo, validateLocation } = this.state;

    if (!selectedLocationInfo.name) {
      this.setState({
        validateLocation: {
          ...validateLocation,
          invalidname: true,
        },
      });
      const info = {
        message: Message.AddLocation,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedLocationInfo.branch_id) {
      this.setState({
        validateLocation: {
          ...validateLocation,
          invalidbranch: true,
        },
      });
      const info = {
        message: Message.SelectBranch,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    const { name, branch_id } = selectedLocationInfo;
    const eid = this.state.id;

    let postData = { location_id: eid, branch_id: branch_id, name: name };

    updateLocation({ postData })
      .then((response) => {
        if (response.code == 200) {
          const info = {
            message: Message.UpdateSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.history.push("/lookups/location");
        } else if (response.code != 200) {
          const info = {
            message: Message.UpdateFailure,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      })
      .catch((error) => {
        const info = {
          message: Message.UpdateFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      this.validateField({ name, value });
      return;
    }
  };

  validateField = ({ name, value }) => {
    const { selectedLocationInfo, validateLocation } = this.state;
    if (name === "name") {
      this.setState({
        selectedLocationInfo: {
          ...selectedLocationInfo,
          // [`invalid${name}`]: !value,
          [name]: value,
        },
        validateLocation: {
          ...validateLocation,
          [`invalid${name}`]: !value,
          // [name]: value,
        },
      });
    }
  };

  handleLocationEdit = (locationClicked) => {
    let newSelectedValues = this.state.locationList.find(
      (c) => c.location_number === locationClicked.location_number
    );
    this.setState({
      selectedLocationInfo: locationClicked,
      selectedBranch: {
        value: locationClicked.branch_id,
        label: locationClicked.branch,
      },
      selectedValues: newSelectedValues,
      eid: locationClicked.id,
      passData: true,
    });
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <LoadingMask />
        ) : (
          <LocationForm
            selectedLocationInfo={this.state.selectedLocationInfo}
            onFormFieldChange={this.handleFormFieldChange}
            SelectedValues={this.state.selectedValues}
            validateLocation={this.state.validateLocation}
            onBranchChange={this.handeBranchChange}
            selectedBranch={this.state.selectedBranch}
            onLocationUpdate={this.handleUpdateLocation}
            formLocationAdd={true}
          />
        )}
      </>
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(EditLocation);
