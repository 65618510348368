import React, { Component, createRef } from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import HttpService from "../../../services/HttpService";
import { MdArrowDropDown } from "react-icons/md";

export default class AbsentFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      department: [],
    };
  }
  async getLookUp() {
    this.setState({ department: await HttpService.getDepartmentList() });
  }

  componentDidMount() {
    this.getLookUp();
  }
  onEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.props.onFilterDetection();
    }
  };
  render() {
    const {
      onFilterDetection,
      onFilterReset,
      onFilterFieldChange,
      SelectedValues,
      onDDChange,
      onDateChange,
      selectedDate,
      onExportCsv,
      selectedDetectionFilters,
    } = this.props;
    const { person_name, person_number } = selectedDetectionFilters;
    const { selected_department } = SelectedValues;

    const keyRef = createRef(Date.now());

    return (
      <>
        <Row className="mt-0 mb-4">
          <Col>
            <Card className="card-user">
              <CardBody>
                <Row>
                  <Col>
                    <h5 className="form-heading">Filters</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-row">
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Name"}
                        </label>
                        <input
                          type="text"
                          name="person_name"
                          className={`input-text`}
                          value={person_name ? person_name : ""}
                          placeholder={"Search name..."}
                          onChange={onFilterFieldChange}
                          onBlur={onFilterFieldChange}
                          onKeyDown={(e) => this.onEnterPress(e)}
                          maxLength={"30"}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Employee #"}
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          name="person_number"
                          className={`input-text `}
                          value={person_number ? person_number : ""}
                          placeholder={"Search Employee # .."}
                          onChange={onFilterFieldChange}
                          onBlur={onFilterFieldChange}
                          onKeyDown={(e) => this.onEnterPress(e)}
                          maxLength={"30"}
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Department"}
                        </label>
                        <Select
                          isClearable
                          isSearchable={false}
                          name="department"
                          value={selected_department}
                          onChange={(selectedOption, name) =>
                            onDDChange(selectedOption, name)
                          }
                          options={this.state.department}
                          classNamePrefix="react-select"
                        />
                      </div>

                      <div className="col-lg-3  col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Date Picker"}
                        </label>
                        <ReactDatePicker
                          selected={selectedDate}
                          onChange={onDateChange}
                          todayButton="Today"
                          maxDate={new Date()}
                          value={selectedDate && selectedDate}
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>
                      <div className=" float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="float-left">
                          <Button
                            onClick={onExportCsv}
                            color="primary"
                            className="caret"
                          >
                            Export{" "}
                            <MdArrowDropDown size={20} className="mb-1" />
                          </Button>
                        </div>
                        <div className="float-right">
                          <Button color="primary" onClick={onFilterDetection}>
                            Apply Filter
                          </Button>
                        </div>
                        <div className="float-right">
                          <Button
                            className="mr-3"
                            outline
                            color="primary mr-3"
                            onClick={onFilterReset}
                          >
                            Clear Filters
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
