import { ApiUrls } from "../../../utils/apiUrls";
import authAxios from "../../../services/axiosInstance";

export const addBranch = ({ name }) => {
  return authAxios
    .post(ApiUrls.Lookups_Branch.ADD_BRANCH, name)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const branchDetails = (branch_id) => {
  return authAxios
    .get(ApiUrls.Lookups_Branch.BRANCH_DETAIL(branch_id))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateBranch = ({ postData }) => {
  return authAxios
    .post(ApiUrls.Lookups_Branch.UPDATE_BRANCH, postData)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const deleteBranch = ({ BulkData }) => {
  return authAxios
    .post(ApiUrls.Lookups_Branch.BRANCH_DELETE, BulkData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const filterTableRecords = (
  per_page,
  page_no,
  order_by,
  order_type,
  name,
  status,
  download
) => {
  return authAxios
    .get(ApiUrls.Lookups_Branch.GET_BRANCH_LIST, {
      params: {
        per_page,
        page_no,
        order_by,
        order_type,
        name,
        status,
        download
      },
    })
    .then(({ data }) => data)
    .catch((response) => response);
};

export const activeInActiveBranch = ({ BulkData }) => {
  return authAxios
    .put(ApiUrls.Lookups_Branch.ACTIVE_INACTIVE_BRANCH, BulkData)
    .then(({ data }) => data)
    .catch((response) => response);
};