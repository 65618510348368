import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Popover,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import "../../assets/sass/_common.scss";
import { withRouter } from "react-router";
import {
  MdCheck,
  MdClose,
  MdRemoveRedEye,
  MdVisibilityOff,
  MdWarning,
} from "react-icons/md";
import { BsCheck } from "react-icons/bs";
import { resetPassword } from "../users/apiCalls";
import "./password.scss";
import { notificationAction } from "../../redux/reducers/notification_reducer";
import { connect } from "react-redux";
class ResetForm extends Component {
  state = {
    inputType: "password",
    confirmType: "password",
    oldType: "password",
    selectedInfo: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    showMessage: false,
    validate: {},
    validationErrors: {},

    min_characters: false,
    small_letters: false,
    capital_letters: false,
    numbers: false,
    special_characters: false,
    popoverOpen: false,
    strength: 1,
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        popoverOpen: true,
      });
    }, 1500);
  };

  handelGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };
  handelShowPassword = (values) => {
    if (values === "password") {
      this.setState({
        inputType: this.state.inputType == "password" ? "text" : "password",
      });
    }
    if (values === "confirm_password") {
      this.setState({
        confirmType: this.state.confirmType == "password" ? "text" : "password",
      });
    }
    if (values === "old_password") {
      this.setState({
        oldType: this.state.oldType == "password" ? "text" : "password",
      });
    }
  };
  onFormFieldChange(evt) {
    const { name, value } = evt.target;
    if (
      name === "old_password" ||
      name === "new_password" ||
      name === "confirm_password"
    ) {
      this.validateField({ name, value });
      return;
    }
  }
  validateField = ({ name, value }) => {
    const { selectedInfo, validate, validationErrors } = this.state;

    this.setState({
      selectedInfo: {
        ...selectedInfo,
        [name]: value,
      },
      validate: {
        ...validate,
        [`invalid_${name}`]: !value,
      },

      validationErrors: {
        ...validationErrors,
        [`validation_${name}`]: "",
      },
    });
  };
  checkPassword = () => {
    let p = this.state.selectedInfo.new_password;
    let smallChar = p.search(/[a-z]/) < 0;
    let capitalChar = p.search(/[A-Z]/) < 0;
    let isNumber = p.search(/[0-9]/) < 0;
    let symbol = p.search(/[!@#\$%\^&\*_]/) < 0;
    const errors = [];
    if (p.length > 7) {
      this.setState({
        min_characters: true,
      });
    }
    if (p.length < 8) {
      this.setState({
        min_characters: false,
      });
    }
    if (smallChar) {
      this.setState({
        small_letters: false,
      });
    }
    if (!smallChar) {
      this.setState({
        small_letters: true,
      });
    }
    if (capitalChar) {
      this.setState({
        capital_letters: false,
      });
    }
    if (!capitalChar) {
      this.setState({
        capital_letters: true,
      });
    }
    if (isNumber) {
      this.setState({
        numbers: false,
      });
    }
    if (!isNumber) {
      this.setState({
        numbers: true,
      });
    }
    if (symbol) {
      this.setState({
        special_characters: false,
      });
    }
    if (!symbol) {
      this.setState({
        special_characters: true,
      });
    }
    return true;
  };
  validatePassword = () => {
    const { new_password, confirm_password } = this.state.selectedInfo;
    if (new_password != confirm_password) {
      return false;
    } else {
      return true;
    }
  };
  handleUpdatePassword = () => {
    const { old_password, new_password } = this.state.selectedInfo;
    let statusData = {
      old_password: old_password,
      new_password: new_password,
    };
    resetPassword({ statusData }).then((response) => {
      if (response.code === 200) {
        const info = {
          message: response.message,
          status: "success",
        };
        this.props.notificationAction(info);
        this.handelClearFields();
        return;
      } else if (response.code != 200) {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
        this.handelClearFields();
        return;
      }
    });
  };

  handelClearFields = () => {
    this.setState({
      selectedInfo: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      showMessage: false,
      validate: {},
      validationErrors: {},

      min_characters: false,
      small_letters: false,
      capital_letters: false,
      numbers: false,
      special_characters: false,
      popoverOpen: false,
    });
  };
  ResetPassword = (e) => {
    e.preventDefault();
    const { selectedInfo, validate, validationErrors } = this.state;
    if (!selectedInfo.old_password) {
      this.setState({
        validate: {
          ...validate,
          invalid_old_password: true,
        },
        validationErrors: {
          ...validationErrors,
          validation_old_password: "This field is required",
        },
      });
      return;
    }
    if (!selectedInfo.new_password) {
      this.setState({
        validate: {
          ...validate,
          invalid_new_password: true,
        },
        validationErrors: {
          ...validationErrors,
          validation_new_password: "This field is required",
        },
      });
      return;
    }
    if (!selectedInfo.confirm_password) {
      this.setState({
        validate: {
          ...validate,
          invalid_confirm_password: true,
        },
        validationErrors: {
          ...validationErrors,
          validation_confirm_password: "This field is required",
        },
      });
      return;
    }
    let validatePassword = this.validatePassword();
    if (validatePassword) {
      this.setState({
        showMessage: false,
      });
      this.handleUpdatePassword();
      return;
    } else {
      this.setState({
        showMessage: true,
      });
      setTimeout(() => {
        this.setState({
          showMessage: false,
        });
      }, 5000);
    }
  };

  render() {
    const {
      inputType,
      confirmType,
      oldType,
      selectedInfo,
      validate,
      message,
      validationErrors,
    } = this.state;
    const { new_password, old_password, confirm_password } = selectedInfo;
    const {
      validation_old_password,
      validation_new_password,
      validation_confirm_password,
    } = validationErrors;
    const {
      invalid_old_password,
      invalid_new_password,
      invalid_confirm_password,
    } = validate;
    const {
      small_letters,
      capital_letters,
      min_characters,
      numbers,
      special_characters,
    } = this.state;
    const array = [];
    let progresslength;
    array.push(small_letters, capital_letters, min_characters, numbers, special_characters);
    progresslength = array
      .map((data) => data === true)
      .filter((data) => data).length;

    return (
      <div className="content v">
        <Card>
          <CardBody>
            <Row>
              <Col lg="2" md="4" sm="4"></Col>
              <Col lg="6" md="4" sm="12" className="mt-5">
                <Form>
                  <Row>
                    <Col className="pr-1" md="12">
                      <div className="mb-3">
                        <p className="auth-reset mb-0 ml-1 text-center">
                          Reset Password
                        </p>
                        <p className="auth-reset-info body-1 mb-0 text-center">
                          {"Enter new password and repeat it."}
                        </p>
                      </div>
                    </Col>
                    <Col className="pr-1" md="6"></Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" lg={"12"} md="12">
                      <label className="form-label required">
                        {"Old Password"}
                      </label>
                      <div className="input-icons">
                        <i
                          onClickCapture={() => {
                            this.handelShowPassword("old_password");
                          }}
                          className="icon"
                        >
                          {oldType == "password" ? (
                            <MdRemoveRedEye
                              size={22}
                              className="text-primary"
                            />
                          ) : (
                            <MdVisibilityOff size={22} />
                          )}
                        </i>
                        <Input
                          type={oldType}
                          className={`input-text-field  form-label" ${
                            invalid_old_password ? "inValid" : ""
                          }`}
                          maxLength={20}
                          name="old_password"
                          value={old_password}
                          onChange={(e) => this.onFormFieldChange(e)}
                        />
                      </div>
                      {validation_old_password && (
                        <>
                          {" "}
                          <MdWarning color="red" />{" "}
                          <span className="inline-field-validation">
                            {validation_old_password}
                          </span>
                        </>
                      )}
                    </Col>
                    <Col className="pr-1" lg={"12"} md="12">
                      <label className="form-label required">
                        {"New Password"}
                      </label>
                      <div className="input-icons">
                        <i
                          onClickCapture={() => {
                            this.handelShowPassword("password");
                          }}
                          className="icon"
                        >
                          {inputType == "password" ? (
                            <MdRemoveRedEye
                              size={22}
                              className="text-primary"
                            />
                          ) : (
                            <MdVisibilityOff size={22} />
                          )}
                        </i>
                        <i
                          style={{
                            marginLeft: 20,
                            background: "red",
                            color: "red",
                          }}
                          id="popover644119284"
                          type="button"
                          className="ml-3"
                        />
                        <div style={{ marginLeft: 20 }}>
                          <UncontrolledPopover
                            style={{ padding: 10, background: "#EBEDF1" }}
                            placement="right"
                            isOpen={this.state.popoverOpen}
                            target="popover644119284"
                          >
                            <PopoverBody className="">
                              <p className="popoverHeadingText">REQUIREMENTS</p>
                              <div className="icons">
                                <BsCheck
                                  className={`${
                                    min_characters ? "visible" : "invisible"
                                  }`}
                                  color="green"
                                  size={25}
                                />
                                <p className="popoverText required">
                                  Minimum 8 characters
                                </p>
                              </div>
                              <div className="icons">
                                <BsCheck
                                  className={`${
                                    small_letters ? "visible" : "invisible"
                                  }`}
                                  color="green"
                                  size={25}
                                />
                                <p className="popoverText">
                                  Small Letters (a-z)
                                </p>
                              </div>
                              <div className="icons">
                                <BsCheck
                                  className={`${
                                    capital_letters ? "visible" : "invisible"
                                  }`}
                                  color="green"
                                  size={25}
                                />
                                <p className="popoverText">
                                  Capital Letters (A-Z)
                                </p>
                              </div>
                              <div className="icons">
                                <BsCheck
                                  className={`${
                                    numbers ? "visible" : "invisible"
                                  }`}
                                  color="green"
                                  size={25}
                                />
                                <p className="popoverText">Numbers (0-9)</p>
                              </div>
                              <div className="icons">
                                <BsCheck
                                  className={`${
                                    special_characters ? "visible" : "invisible"
                                  }`}
                                  color="green"
                                  size={25}
                                />{" "}
                                <dis BsCheck size={25} />
                                <p className="popoverText">
                                  Special Characters <br />
                                  (!@#$% etc.)
                                </p>
                              </div>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </div>
                        <Input
                          type={inputType}
                          className={`input-text-field  form-label" ${
                            invalid_new_password ? "inValid" : ""
                          }`}
                          maxLength={20}
                          name="new_password"
                          value={new_password}
                          onChange={(e) => this.onFormFieldChange(e)}
                          onKeyUp={this.checkPassword}
                        />
                      </div>
                      {validation_new_password && (
                        <>
                          {" "}
                          <MdWarning color="red" />{" "}
                          <span className="inline-field-validation">
                            {validation_new_password}
                          </span>
                        </>
                      )}
                    </Col>
                    <Col>
                      {progresslength > 0 ? (
                        <progress
                          // hidden={this.state.new_password.length === 0}
                          className={`password strength-${progresslength}`}
                          value={progresslength}
                          max="4"
                        />
                      ) : null}
                    </Col>
                    <Col className="pr-1" lg="12" md="12">
                      <label className="form-label required">
                        {"Confirm Password"}
                      </label>
                      <div className="input-icons">
                        <i
                          onClickCapture={() => {
                            this.handelShowPassword("confirm_password");
                          }}
                          className="icon"
                        >
                          {confirmType == "password" ? (
                            <MdRemoveRedEye
                              size={22}
                              className="text-primary"
                            />
                          ) : (
                            <MdVisibilityOff size={22} />
                          )}
                        </i>
                        <Input
                          type={confirmType}
                          className={`input-text-field  form-label" ${
                            invalid_confirm_password ? "inValid" : ""
                          }`}
                          maxLength={20}
                          name="confirm_password"
                          value={confirm_password}
                          onChange={(e) => this.onFormFieldChange(e)}
                        />
                      </div>
                      {validation_confirm_password && (
                        <>
                          {" "}
                          <MdWarning color="red" />{" "}
                          <span className="inline-field-validation">
                            {validation_confirm_password}
                          </span>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Button
                      className="m-2 ml-3 login-btn"
                      size="lg"
                      type="submit"
                      color="primary"
                      disabled={progresslength > 2 && min_characters ? false : true  }
                      onClick={this.ResetPassword}
                    >
                      Reset Password
                    </Button>
                  </Row>
                  <div>
                    {this.state.showMessage ? (
                      <p className=" ml-3 mt-3 p-2 mb-2  errorAlert">
                        {"Password and Confirm Password does not match"}
                      </p>
                    ) : null}
                  </div>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(ResetForm);
