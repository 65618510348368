import React from "react";
import {
  Col,
  Row,
  Button,
} from "reactstrap";
import Table from "../../../components/Table/Table";
import "../../../assets/sass/_common.scss";
import SweetAlert from "../../../components/sweet-alert/SweetAlert";
import {
  deleteLocation,
  activeInActiveLocation,
  filterTableRecords,
} from "./apiCalls";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../redux/actions/types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LocationFilter from "./component/Filter";
import { MdEdit, MdLocationOn } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import LoadingMask from "../../../components/Loader/LoadingMask";

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sweetalert: false,
      paginationData: [],
      locId: null,
      download: true,
      allChecked: false,
      appLoadingState: false,
      locationList: [],
      selectedDetailInfo: {},

      selectedLocationValues: {
        name: null,
        branch_id:null,
        is_active: null

      },
      selectedValues: {
        selected_is_active: { label: "All", value: null },
        selected_branch_id: { label: "All", value: null },

      },
    };
  }

  componentDidMount() {
    this.props.RecordPerPage(10)
    this.props.PageNo(1)
    this.handleFilterDataTableList();

  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageno !== this.props.pageno ||
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      this.handleFilterDataTableList();
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      this.props.PageNo(1);
      this.handleFilterDataTableList();
      this.setState({
        allChecked: false,
      });
    }
  }



  handleFiltersClear = () => {
    this.props.PageNo(1);
    this.props.RecordPerPage(10);
    this.setState(
      {
        selectedLocationValues: {
          name: "",
        },
        selectedValues: {
          ...this.state.selectedValues,
          selected_is_active: { label: "All", value: null },
          selected_branch_id: { label: "All", value: null },
        },
      },
      () => this.handleFilterDataTableList()
    );
  };



  handleFilterApply = () => {
    this.handleFilterDataTableList();
  };

  handleFilterDataTableList = () => {


    this.setState({ appLoadingState: true })
    const { selectedLocationValues ,selectedValues} = this.state;
    const {selected_is_active}=selectedValues;
    const {
      name,
      branch_id,
      is_active
    } =
      selectedLocationValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      name ? name : null,
      branch_id ?branch_id :null,
      selected_is_active.label !== "All" ? (is_active ? "1" : "0") : null,


    )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            locationList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState: false

          });
        }
        else if (response.code != 200){
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            appLoadingState: false,
          });
        }
      })
      .catch((error) => console.log("Erorr in getting locations ", error));
  };
  validateField = ({ name, value }) => {
    const { selectedLocationValues } = this.state;
    this.setState({
      selectedLocationValues: {
        ...selectedLocationValues,
        [name]: value,
      },
    });
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" ) {
      this.validateField({ name, value });
      return;
    }
  };
  handelDropDownChange = (selectedOption, obj) => {
    const { selectedValues, selectedLocationValues } = this.state;
    const { name } = obj;

    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (
        obj.name === "is_active" || obj.name === "branch_id"
      ) {
        this.setState({
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
          selectedLocationValues: {
            ...selectedLocationValues,
            [name]:null,
          },
        });
      }
      return;
    }
    if( obj.name === "is_active"){
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedLocationValues: {
          ...selectedLocationValues,
          [name]: selectedOption.value,
        },
      })
    }
    if( obj.name === "branch_id"){
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedLocationValues: {
          ...selectedLocationValues,
          [name]: selectedOption.id,
        },
      })
    }
  };

  handleCheckOptionToggle = (item) => {
    if (item != null) {
      this.setState({
        allChecked: false,
        locationList: this.state.locationList.map((s) =>
          s.id == item.id ? { ...item, Checked: !s.Checked } : s
        ),
      });
    } else {
      this.setState({
        allChecked: !this.state.allChecked,
        locationList: this.state.locationList.map((s) => ({
          ...s,
          Checked: !this.state.allChecked,
        })),
      });
    }
  };

  handleTableButtonClick = (item, type) => {

    if (type == "Edit") {

      this.handlLocationEdit({
        id: item.id,
      });
    } else if (type == "Delete") {
      this.handlLocationDelete(item.id);

    }
  };
  handleGettingBulkRecord = (item) => {
    const listArray = [];
    item.data.map((item) => {
        listArray.push(item.id);
    });
    let statusData = {
      location_id: listArray,
      status: item.status,
    };
    if (item.status == "delete") {
      const statusData = {
        location_id: listArray,
      };
      deleteLocation({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "0") {
      activeInActiveLocation({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "1") {
      activeInActiveLocation({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    }
  };
  handleToggleButtonClick = (item) => {
    let allLocations = this.state.locationList;
    let locationMapping = allLocations.find(function (element) {
      return element.id == item.id;
    });
    let locationStatus = null;
    if (locationMapping.status == true) {
      locationStatus = false;
    } else if (locationMapping.status == false) {
      locationStatus = true;
    }

    let statusData = {
      location_id: [locationMapping.id],
      status: locationStatus,
    };
    activeInActiveLocation({ statusData })
      .then((response) => {
        if (response.code === 200) {
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.handleFilterDataTableList();
          return;
        }
      })
      .catch((error, response) => {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };
  handlLocationEdit = (item) => {
    this.props.history.push({
      pathname: "/lookups/location/edit-location/" +item.id,
      state: {
        id: item.locationId,
      },
    });
  };
  handelSweetModalDelete = (e) => {
    let statusData = { location_id: [this.state.locId] };
    if (e) {
      deleteLocation({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.setState({
              sweetalert: false
            })
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            return;
          }else if (response.code != 200) {
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
              sweetalert: false,
            });
            return;
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    }
    else {
      this.setState({
        sweetalert: false
      })
    }

  }
  handlLocationDelete = (Id) => {
    this.setState({
      locId: Id,
      sweetalert: true
    })
  };
  handleExportCsv = () => {
    this.setState({ appLoadingState: true });
    const { download,selectedValues } = this.state;
    const {
      name,
      branch_id,
      is_active
    } =
      this.state.selectedLocationValues;
       const {selected_is_active}=selectedValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      name ? name : null,
      branch_id ?branch_id :null,
      selected_is_active.label !== "All" ? (is_active ? "1" : "0") : null,
      download ? 1 : null
    )
      .then((response) => {
        if (response.code == 200) {
          if (response.data.file_url != null) {
            window.open(response.data.file_url, "_blank");
          }
        }
        this.setState({ appLoadingState: false });
      })
      .catch((error) => console.log("Erorr in getting locations ", error));
  };
  render() {
    let template;

    template = (
      <div>
        <Row>
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={3} lg={3}>
           

          </Col>
          <Col md={9} lg={9}>
          <SweetAlert show={this.state.sweetalert}
              deleteRecord={this.handelSweetModalDelete}
            />

            <Link to="/lookups/location/add-location">
              <Button color="primary" className="pull-right button-full-width ">
                <MdLocationOn /> &nbsp; Add Location
              </Button>
            </Link>
          </Col>
        </Row>
        <LocationFilter
          onFilterApply={this.handleFilterApply}
          onExportCsv={this.handleExportCsv}
          SelectedValues={this.state.selectedValues}
          selectedFormValues={this.state.selectedLocationValues}
          onFormFieldChange={this.handleFormFieldChange}
          onDDChange={this.handelDropDownChange}
          onFilterReset={this.handleFiltersClear}
        />
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            {this.state.locationList && this.state.locationList.length > 0 ? (
              <Table
                primaryKeyField="sno"
                data={this.state.locationList}
                isImageEnabled={false}
                listItemToDisplay={[
                  { name: "Location", valueField: "name" },
                  { name: "Short Code", valueField: "code" },
                  { name: "Branch", valueField: "branch" },
                  { name: "Modified", valueField: "updated_at" },
                ]}
                onCheckOptionToggle={this.handleCheckOptionToggle}
                allChecked={this.state.allChecked}
                paginationData={this.state.paginationData}
                toggleButtonToDisplay={true}
                isCheckOption={true}
                buttonsToDisplay={[
                  {
                    name: "Edit",
                    buttonLabel: "Edit",
                    icon: <MdEdit size={22} />,
                    Tooltipmessage: "Edit Location"
                  },
                  {
                    name: "Delete",
                    color: "#CE3939",
                    icon: <MdDelete size={22} />,
                    buttonLabel: "Delete",
                    Tooltipmessage: "Delete Location"
                  },
                ]}
                onTableButtonClick={this.handleTableButtonClick}
                onToggleStatus={this.handleToggleButtonClick}
                onGettingBulkRecords={this.handleGettingBulkRecord}
              />
            ) : (
              <div className="text-center">
                There is no data available to show
              </div>
            )}
          </Col>
        </Row>
      </div>
    );

    return (
      <div className="">{template} </div>
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName,
});

export default connect(mapStateToProps, actions)(Location);
