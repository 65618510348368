import React from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { connect } from "react-redux";
import Pagination from "./Pagination";
import "./Table.scss";
import * as ActionTypes from "../../redux/actions/types";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import {
  Button,
  Col,
  Row,
  Table,
  Badge,
} from "../../../node_modules/reactstrap/es/index";
import s from "./Table.module.scss";
import Widget from "../../components/Widget/Widget.js";
import Active from "../../assets/table/Active.svg";
import InActive from "../../assets/table/InActive.svg";
import { GrUndo } from "react-icons/gr";
import { RiErrorWarningLine } from "react-icons/ri";
// import Profile from "../../assets/employee/profile.svg";
import CircleProfile from "../../assets/others/CircleThumbnail.svg";
import SquareThumbnail from "../../assets/others/SquareThumbnail.svg";
import { BASE_URL } from "../../utils/apiUrls";
import DayNightIcon from "../../assets/datesOverlapping.svg";
import { Fragment } from "react";
import Tooltip from "../Tooltip/ToolTip";

let selectedRecord = 0;
let stateValue = "";

let orderByAsc = true;
class DataTable extends React.Component {
  state = {
    filteredData: [],
    noOfRecordPerPage: null,
    listItemToPrint: [],
    onPagination: true,
    pageOfItems: [],
    sortDesc: false,
    sortName: "",
  };

  //lifecycle methods
  componentDidMount = () => {
    this.displayCurrentPageRecord(0, this.props.recordperpage);
    const listItemToPrint = this.props.data.map((item) => {
      return this.props.listItemToDisplay.map((nes) => item[nes.valueField]);
    });
    this.setState({
      listItemToPrint,
    });
    if (this.props.data.length < 1) {
      this.setState({ onPagination: false });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      const listItemToPrint = this.props.data.map((item) => {
        return this.props.listItemToDisplay.map((nes) => item[nes.valueField]);
      });
      this.setState({
        listItemToPrint,
      });
      this.displayCurrentPageRecord(0, this.props.paginationData.per_page);
    }
    if (prevProps.data.length !== this.props.data.length) {
      if (this.props.data.length < 1) {
        this.setState({ onPagination: false });
      }
    }
  }

  displayCurrentPageRecord = (startFrom, noOfRecordPerPageValue) => {
    let noOfRecordPerPageVal;
    if (noOfRecordPerPageValue != undefined) {
      noOfRecordPerPageVal = noOfRecordPerPageValue;
    } else {
      this.setState({
        noOfRecordPerPage: noOfRecordPerPageVal,
      });
    }
    var items = this.props.data.slice(
      startFrom * noOfRecordPerPageVal,
      +noOfRecordPerPageVal + startFrom * +noOfRecordPerPageVal
    );
    this.setState({
      filteredData: items,
    });
  };
  toggleKeyword = () => {
    orderByAsc = !orderByAsc;
    if (orderByAsc) {
      return "asc";
    } else return "desc";
  };
  //Sorting
  handleSorting = (sortByName) => {
    let sortValue = this.toggleKeyword();

    this.props.SortBy(sortValue);
    this.props.SortName(sortByName);
    this.setState({
      sortDesc: !this.state.sortDesc,
      sortName: sortByName,
    })
  
  };
  findSelectedRecord = () => {
    const listArray = [];
    this.props.data.map((item) => {
      if (item.Checked == true) {
        listArray.push([
          item.employee_name,
          item.image_path,
          item.employee_code,
        ]);
      }
    });
    return listArray;
  };
  handleNoOfRecordPerPageChange = (event) => {
    this.setState({
      noOfRecordPerPage: event.target.value,
    });
    this.displayCurrentPageRecord(
      event.target.value - event.target.value,
      event.target.value
    );
    this.props.onRecordPerPage(parseInt(event.target.value));
    setTimeout(() => {
      this.props.PageNo(1);
    }, 100);
  };

  handleBulkList = () => {
    const listArray = [];
    this.props.data.map((item) => {
      if (item.Checked == true) {
        listArray.push(item);
      }
    });
    return listArray;
  };

  handleBulkOperation = (action) => {
    const list = this.handleBulkList();
    let BulkList = {};
    BulkList = { data: list, status: action };
    this.props.onGettingBulkRecords(BulkList);
  };
  handelCheckState(e) {
    let colorVar = e && e.toLowerCase();
    switch (colorVar) {
      case "ok":
        {
          stateValue = "#278566";
        }
        break;
      case "pending":
        {
          stateValue = "#E59215";
        }
        break;
      case "duplicate":
        {
          stateValue = "#CE3939";
        }
        break;
      case "connected":
        {
          stateValue = "#286AD9";
        }
        break;
      case "disconnected":
        {
          stateValue = "#CE3939";
        }
        break;
      case "not initialized":
        {
          stateValue = "#39AEA9";
        }
        break;

      case "error":
        {
          stateValue = "#CE3939";
        }
        break;
      case "hidden":
        {
          stateValue = "#CE3939";
        }
        break;

      default:
        stateValue = "default";
        break;
    }
  }

  render() {
    if (this.props.isCheckOption == false) {
      selectedRecord = 0;
    }
    const {
      listItemToDisplay,
      buttonsToDisplay,
      textButtonsToDisplay,
      toggleButtonToDisplay = false,
      onTableButtonClick,
      onToggleStatus,
      data,
      isImageCircle,
      paginationData,
      noOfRecordsPerPageOptions = [10, 20, 30, 50, 100],
      isCheckOption = false,
      serialNoEnabled = false,
      onCheckOptionToggle,
      onShowPicture,
      onGettingBulkRecords,
      isImageEnabled = false,
      isImageSquare = false,
      isBadgeState = false,
      allChecked = false,
    } = this.props;

    const { noOfRecordPerPage, onPagination } = this.state;
    const { current_page, per_page, total_count, total_pages } = paginationData;
    let startingPage = 1;
    startingPage = per_page * current_page - per_page + 1;

    let endingPage = 1;
    let diff1 = 1;
    let diff2 = 1;
    let totalPages = total_pages * per_page;
    let cpage = current_page * per_page;
    if (cpage > total_count) {
      diff1 = totalPages - total_count;
      diff2 = total_count - diff1;
      endingPage = diff1 + diff2;
    } else {
      endingPage = current_page * per_page;
    }
    const headerClass = ["text-dark py-2 pr-4 m-0"].join(" ").trim();
    if (this.props.isCheckOption) {
      let checkedRecords = this.findSelectedRecord();
      selectedRecord = checkedRecords.length;
    }
    let selectedOptionsBar = "";
    if (isCheckOption[1] == null) {
      selectedOptionsBar = (
        <Fragment>
          <Button
            onClick={() => this.handleBulkOperation("delete")}
            className="m-2 button-full-width"
            color="danger"
          >
            {"Delete"}
          </Button>
          <Button
            onClick={() => this.handleBulkOperation("0")}
            className="m-2 button-full-width"
            color="danger"
          >
            {"Inactive"}
          </Button>
          <Button
            onClick={() => this.handleBulkOperation("1")}
            className="m-2 button-full-width"
            color="success"
          >
            {"Activate"}
          </Button>
        </Fragment>
      );
    } else if (isCheckOption[1] == "detection") {
      selectedOptionsBar = (
        <Fragment>
          <Button
            onClick={() => this.handleBulkOperation("false")}
            className="m-2"
            color="success"
          >
            {"Ignore Detection"}
          </Button>
          <Button
            onClick={() => this.handleBulkOperation("true")}
            className="m-2"
            color="danger"
          >
            {"Un-Ignore Detection"}
          </Button>
        </Fragment>
      );
    }
    return (
      <Row>
        <Col>
          {selectedRecord > 0 ? (
            <Row
              style={{ backgroundColor: "#101C30" }}
              className=" table-container rounded"
            >
              <Col lg={6} md={6} sm={12} className="float-left">
                <p className="text-light mt-3">
                  {" "}
                  Selected Records {selectedRecord}
                </p>
              </Col>
              <Col lg={6} md={6} sm={12} className="text-right ">
                {selectedOptionsBar}
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className="widget-table-overflow table-responsive">
                  <Table
                    className={`table-striped table-borderless   table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead
                      style={{
                        wordBreak: "keep-all",
                        whiteSpace: "nowrap",
                        backgroundColor: "#E7EBF6",
                        textAlign: "center",
                      }}
                    >
                      <tr>
                        {isCheckOption && (
                          <th className={s.checkboxCol}>
                            <div className=" checkbox-primary">
                              <input
                                type="checkbox"
                                className="styled"
                                id="checkbox100"
                                checked={allChecked}
                                onClick={() => onCheckOptionToggle(null)}
                              />
                            </div>
                          </th>
                        )}
                        {serialNoEnabled && <th>#</th>}
                        {isImageSquare[1] && (
                          <th className="font-weight-bold">Crop</th>
                        )}
                        {isImageEnabled && <th></th>}
                        {/* {isImageSquare[1] && (
                          <th className="font-weight-bold"></th>
                        )} */}
                        {listItemToDisplay.map((item) => (
                          <th
                            className="font-weight-bold cursor-pointer"
                            key={item.name}
                            onClick={() => this.handleSorting(item.valueField)}
                          >
                            {this.state.sortName == item.valueField ? (
                              this.state.sortDesc ? (
                                <>
                                  {item.name}
                                  <FaLongArrowAltUp />
                                </>
                              ) : (
                                <>
                                  {item.name}
                                  <FaLongArrowAltDown />
                                </>
                              )
                            ) : (
                              item.name
                            )}
                          </th>
                        ))}
                        {isBadgeState && (
                          <th className="font-weight-bold">
                            {" "}
                            {isBadgeState[1]}{" "}
                          </th>
                        )}
                        {toggleButtonToDisplay && (
                          <th className="font-weight-bold">Status</th>
                        )}
                        {buttonsToDisplay && (
                          <th className="actionHead text-center font-weight-bold">
                            {buttonsToDisplay.length > 0 ? "Action" : null}
                          </th>
                        )}
                        {textButtonsToDisplay && (
                          <th className="actionHead text-center font-weight-bold">
                            {textButtonsToDisplay.length > 0 ? "Action" : null}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody
                      className="table-body"
                      style={{ textAlign: "center" }}
                    >
                      {this.state.filteredData.map((item, index) => (
                        <tr style={{ wordBreak: "keep-all" }} key={index}>
                          {isCheckOption && (
                            <td>
                              <input
                                type="checkbox"
                                checked={item.Checked}
                                onClick={() => onCheckOptionToggle(item)}
                              />
                            </td>
                          )}

                          {serialNoEnabled && <td>{index + 1}</td>}
                          {isImageEnabled && (
                            <td>
                              <div>
                                <img
                                  onClick={() => onShowPicture(item.image_path)}
                                  style={{
                                    width: isImageSquare
                                      ? "5.25rem"
                                      : "3.75rem",
                                    height: isImageSquare
                                      ? "5.25rem"
                                      : "3.75rem",
                                    margin: isImageSquare ? "" : -6,
                                    borderRadius: isImageSquare
                                      ? isImageCircle
                                        ? 50
                                        : 0
                                      : 50,
                                    objectFit: "cover",
                                  }}
                                  src={
                                    item.image_path && item.image_path
                                      ? BASE_URL + "/" + item.image_path
                                      : CircleProfile
                                  }
                                />
                              </div>
                            </td>
                          )}
                          {isImageSquare[1] === true ? (
                            <td>
                              <div>
                                <img
                                  onClick={() =>
                                    onShowPicture(item.person_image)
                                  }
                                  style={{
                                    width: "3rem",
                                    height: "3rem",
                                    borderRadius: 50,
                                    objectFit: "cover",
                                  }}
                                  src={
                                    item.person_image && item.person_image
                                      ? BASE_URL + "/" + item.person_image
                                      : CircleProfile
                                  }
                                />
                              </div>
                            </td>
                          ) : (
                            ""
                          )}

                          {listItemToDisplay.map((nes, index) => (
                            <td key={index} data-label={nes.index}>
                              {
                                <span>
                                  {`${
                                    item[nes.valueField] != undefined
                                      ? item[nes.valueField]
                                      : ""
                                  }`}
                                </span>
                              }
                            </td>
                          ))}
                          {isBadgeState && (
                            <td>
                              {this.handelCheckState(
                                item.image_status ||
                                  item.review_type ||
                                  item.visibility ||
                                  item.connection
                              )}

                              <Badge style={{ backgroundColor: stateValue }}>
                                {item.image_status ||
                                  item.review_type ||
                                  item.visibility ||
                                  item.connection}
                              </Badge>
                            </td>
                          )}
                          {toggleButtonToDisplay && (
                            <td key={index} className="img-btn-col">
                              <img
                                onClick={() => onToggleStatus(item)}
                                src={item.status == true ? Active : InActive}
                              ></img>
                            </td>
                          )}
                          <td className="flex-parent actionHeading btn-group">
                            {buttonsToDisplay &&
                              buttonsToDisplay.map((btn, index) => (
                                <td key={index} className="img-btn-col">
                                  <Tooltip
                                    content={btn.Tooltipmessage}
                                    direction="top"
                                  >
                                    {/* {btn.value == "ShowHide" ? (
                                      <img
                                        onClick={() =>
                                          onTableButtonClick(item, btn.name)
                                        }
                                        src={item.ignored ? Valid : InValid}
                                      />
                                    ) : null} */}
                                    <a
                                      btn
                                      style={
                                        btn.color === undefined
                                          ? { color: "#286AD9" }
                                          : { color: `${btn.color}` }
                                      }
                                      alt="btn"
                                      onClick={() =>
                                        onTableButtonClick(item, btn.name)
                                      }
                                    >
                                      {btn.icon}
                                    </a>
                                  </Tooltip>
                                </td>
                              ))}
                            {textButtonsToDisplay &&
                              textButtonsToDisplay.map((btn, index) => (
                                <td key={index} className="img-btn-col">
                                  <Tooltip
                                    content={ btn.value == "ShowHide"
                                    ? !item.ignored
                                      ? btn.Tooltipmessage
                                      : "Undo Report"
                                    : btn.Tooltipmessage}
                                    direction="left"
                                  >
                                    {btn.value == "ShowHide" ? (
                                      item.ignored ? (
                                        <Button
                                        className="d-flex"
                                          onClick={() =>
                                            onTableButtonClick(item, "false")
                                          }
                                          color="secondary"
                                        >
                                          {" "}
                                          <GrUndo size={25} className="pb-1 pr-1"/>
                                          Undo
                                        </Button>
                                      ) : (
                                        
                                        <Button
                                        className="d-flex"
                                        onClick={() =>
                                          onTableButtonClick(item, "true")
                                        }
                                        outline
                                        color="warning"
                                      >
                                        {" "}
                                        <RiErrorWarningLine size={25} className="pb-1 pr-1" />
                                        Report
                                      </Button>
                                      )
                                    ) : (
                                      <Button
                                        onClick={() =>
                                          onTableButtonClick(item, btn.name)
                                        }
                                        color={btn.color}
                                      >
                                        {btn.buttonLabel}
                                      </Button>
                                    )}
                                  </Tooltip>
                                </td>
                              ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {onPagination ? (
                    <Fragment>
                      <div className="w-100 px-2 py-1 d-flex flex-row flex-wrap align-items-center justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <p className={headerClass}>
                            Records per page:
                            <select
                              className="table-record-select"
                              value={this.props.recordperpage}
                              onChange={this.handleNoOfRecordPerPageChange}
                            >
                              {noOfRecordsPerPageOptions.map((item) => (
                                <option key={item}>{item}</option>
                              ))}
                            </select>{" "}
                          </p>
                        </div>

                        <div className="d-flex flex-row pr-4 align-items-center">
                          <Pagination
                            totalRecords={total_count}
                            pageLimit={parseInt(per_page)}
                            currentPage={current_page}
                            totalPages={total_pages}
                            pageNeighbours={total_pages > 99 ? 2 : 1}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row pr-4 pl-3 align-items-center">
                        <span className="flex-child text-dark font-weight-normal">
                          Showing {startingPage} - {endingPage} of {total_count}{" "}
                          records
                        </span>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  onRecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  SortBy: (sortBy) => dispatch({ type: ActionTypes.SORT_BY, sortBy }),
  SortName: (sortName) => dispatch({ type: ActionTypes.SORT_NAME, sortName }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  sortby: state.tab.sortBy,
  sortname: state.tab.SortName,
  recordperpage: state.tab.recordperpage,
});
export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
