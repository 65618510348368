import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router";
import { BrowserRouter, HashRouter } from "react-router-dom";
import LayoutComponent from "../components/Layout/Layout.js";
import ErrorPage from "../views/error/ErrorPage.js";
import Login from "../views/login/Login.js";
import Register from "../views/register/Register.js";
import { logoutUser } from "../redux/actions/auth";

import "../styles/app.scss";
import { ToastContainer } from "react-toastify";
import ResetPassword from "../views/login/ResetPassword.js";

const PrivateRoute = ({ dispatch, component, ...rest }) => {
  if (
    !Login.isAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
  ) {
    dispatch(logoutUser());
    return <Redirect to="/login" />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    );
  }
};

class App extends React.PureComponent {
  render() {
    return (
      <div>
        <ToastContainer />
        <BrowserRouter>
          <Switch>
            <Route
              path="/"
              exact
              render={() => <Redirect to="/dashboard" />}
            />
       
             <Route path="/login" exact component={Login} />
           

            <PrivateRoute
              path="/"
              dispatch={this.props.dispatch}
              component={LayoutComponent}
            />
           
            <Route path="/error" exact component={ErrorPage} />
            <Route path="/register" exact component={Register} />
            <Route component={ErrorPage} />
            <Route
              path="*"
              exact={true}
              render={() => <Redirect to="/error" />}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
