import { ApiUrls } from "../../../utils/apiUrls";
import authAxios from "../../../services/axiosInstance";
 
export const addDepartment = ({ selectedDepartmentInfo }) => {
 return authAxios
   .post(ApiUrls.Lookups_Department.ADD_DEPARTMENT, selectedDepartmentInfo)
   .then(({ data }) => data)
   .catch((response) => response);
};
export const departmentDetails = (department_id ) => {
 return authAxios
   .get(ApiUrls.Lookups_Department.DEPARTMENT_DETAIL(department_id))
   .then(({ data }) => data)
   .catch((response) => response);
};
export const updateDepartment = ({ postData }) => {
 return authAxios
   .post(ApiUrls.Lookups_Department.UPDATE_DEPARTMENT, postData)
   .then(({ data }) => data)
   .catch((response) => response);
};
 
export const deleteDepartment = ({ BulkData }) => {
 return authAxios
   .post(ApiUrls.Lookups_Department.DEPARTMENT_DELETE, BulkData)
   .then(({ data }) => data)
   .catch((response) => response);
};
export const filterTableRecords = (
 per_page,
 page_no,
 order_by,
 order_type,
 name ,
 min,
 max,
 status,
 download
) => {
 return authAxios
   .get(ApiUrls.Lookups_Department.GET_DEPARTMENT_LIST, {
     params: {
       per_page,
       page_no,
       order_by,
       order_type,
       name,
       min,
       max,
       status,
       download
     },
   })
   .then(({ data }) => data)
   .catch((response) => response);
};
 
export const activeInActiveDepartment = ({ BulkData }) => {
 return authAxios
   .put(ApiUrls.Lookups_Department.ACTIVE_INACTIVE_DEPARTMENT, BulkData)
   .then(({ data }) => data)
   .catch((response) => response);
};
