import React from "react";
import "./loadingMask.scss";
import Loader from './spinner.gif'
const LoadingMaskDefault = () => {
    return (
      <div className="loadingMaskWrapperForHtmlElements">
        <div className="loading">
          <div className="loadingImageWrapper">
            <img className="loadingImg" src={Loader} alt="loading" />
          </div>
        </div>
      </div>
    );
  };
  export default LoadingMaskDefault;