import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import s from "./Breadcrumbs.module.scss";

class Breadcrumbs extends React.Component {
  renderBreadcrumbs = () => {
    let url = this.props.url;
    let route = this.props.url
      .split("/")
      .slice(1)
      .map((route) =>
        route
          .split("-")
          .map((word) =>
            word.length < 2
              ? word.toUpperCase()
              : word[0].toUpperCase() + word.slice(1)
          )
          .join(" ")
      );
    const length = route.length;
    const newroute=length >3 ?route.pop(1) :""
    if (length > 1 ) {
      return route.map((item, index) => {
        let middlewareUrl =
          "/" +
          url
            .split("/")
            .slice(1, index + 2)
            .join("/");
        return (
          <BreadcrumbItem className={s.breadcrumbText} key={uuidv4()}>
            {length === index + 1 && item.length < 30 ? (
              <span
                className={
                  index === 0 ? "" : `font-weight-bold ${s.breadcrumbText}`
                }
              >
                {" "}
                {item}
              </span>
            ) : index === 0 || index === 2  && item.length <30? (
              <span
                className={
                  index === 0 ? "" : `font-weight-bold ${s.breadcrumbText}`
                }
              >
                {" "}
                {item}
              </span>
            ) : (
              <Link to={middlewareUrl}>
                {item.length < 20 ? (
                  <span
                    className={
                      index != 2
                        ? `${s.breadcrumbText}`
                        : `font-weight-bold ${s.breadcrumbText}`
                    }
                  >
                    {item}
                  </span>
                ) : null}
              </Link>
            )}
          </BreadcrumbItem>
        );
      });
    } else {
      return route.map((item, index) => {
        let middlewareUrl =
          "/" +
          url
            .split("/")
            .slice(1, index + 2)
            .join("/");
        return (
          <BreadcrumbItem className={s.breadcrumbText} key={uuidv4()}>
            {length === index + 1 && item.length < 30 ? (
              <span
                className={
                  index === 0 ? "" : `font-weight-bold ${s.breadcrumbText}`
                }
              >
                {" "}
                {item}
              </span>
            ) : index === 1 || index === 3 ? (
              <span
                className={
                  index === 0 || index === 2
                    ? ""
                    : `font-weight-bold ${s.breadcrumbText}`
                }
              >
                {" "}
                {item}
              </span>
            ) : (
              <Link to={middlewareUrl}>
                {item.length < 20 ? (
                  <span
                    className={
                      index != 1
                        ? `${s.breadcrumbText}`
                        : `font-weight-bold ${s.breadcrumbText}`
                    }
                  >
                    {item}
                  </span>
                ) : null}
              </Link>
            )}
          </BreadcrumbItem>
        );
      });
    }
  };

  render() {
    let route = this.props.url
      .split("/")
      .slice(1)
      .map((route) =>
        route
          .split("-")
          .map((word) =>
            word.length < 2
              ? word.toUpperCase()
              : word[0].toUpperCase() + word.slice(1)
          )
          .join(" ")
      );

    let title = route[route.length - 1];
    let shortTitle = route[route.length - 2];
    return (
      <div className={s.breadcrumbs}>
        <div className="headline-2">
          {title.length > 20 ? shortTitle : title}
        </div>
        
        {route.length>1 ? (
          <Breadcrumb tag="nav" listTag="div">
            {this.renderBreadcrumbs()}
          </Breadcrumb>
        ): null}
      </div>
    );
  }
}

export default Breadcrumbs;
