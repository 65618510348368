import { ApiUrls,FORGOT_PASSWORD } from "../../utils/apiUrls";
import authAxios from "../../services/axiosInstance";




export const filterTableRecords = async (
  per_page,
  page_no,
  order_by,
  order_type,
  username,
  email,
  is_active,
  download
) => {
  try {
    const { data } = await authAxios
      .get(ApiUrls.Users.GET_USERS_LIST, {
        params: {
          per_page,
          page_no,
          order_by,
          order_type,
          username,
          email,
          is_active,
          download
        },
      });
    return data;
  } catch (response) {
    return response;
  }
};
export const addUser = async ({ user }) => {
  try {
    const { data } = await authAxios
      .post(ApiUrls.Users.ADD_USER, user);
    return data;
  } catch (response) {
    return response;
  }
};
export const editUser = async ({ user }) => {
  try {
    const { data } = await authAxios
      .put(ApiUrls.Users.EDIT_USER, user);
    return data;
  } catch (response) {
    return response;
  }
};
export const resetPassword = async ({ statusData }) => {
  try {
    const { data } = await authAxios
      .put(FORGOT_PASSWORD, statusData);
    return data;
  } catch (response) {
    return response;
  }
 };
  export const getUserDetail = async id => {
    try {
      const { data } = await authAxios
        .get(ApiUrls.Users.GET_USER_DETAIL(id));
      return data;
    } catch (response) {
      return response;
    }
  };

