import React from "react";
import {
  Col,
  Row,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Table from "../../components/Table/Table";
import "../../assets/sass/_common.scss";
import SweetAlert from "../../components/sweet-alert/SweetAlert";

import {
  importStudentBulk,
  deleteStudent,
  bulkActive,
  bulkInActive,
  bulkDelete,
  activeInActiveStudent,
  filterTableRecords,
} from "./apiCalls";
import { notificationAction } from "../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../redux/actions/types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StudentFilter from "./component/Filter";
import { CSVLink } from "react-csv";
import mockData from "./mock";
import { MdEdit, MdSchool } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { AiFillFileExcel } from "react-icons/ai";
import { GrStackOverflow } from "react-icons/gr";
import AddCSV from "./component/AddCSV";
import LoadingMask from "../../components/Loader/LoadingMask";
import ImageModel from "../../components/image/ImageModel";
import config from "../../config";
import CsvModal from "./CSVModel";
import { activeInActiveStaff } from "../staff/apiCalls";

let Json = {};
let bulkActionType = "";
class Student extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureModal: false,
      imageUrl: "",
      csvmodel:false,
      sweetalert: false,
      selectedCsvFile: {},
      paginationData: [],
      toggle: false,
      empId: null,
      download: true,
      person_type: "Z0FBQUFBQmlNd2dXTVg4QklvWTVlOHBMdWE3QUc4Y1FfbnhxU2RVNGpVRDVabUNucVV3S0s4TTZJSi02RjhHRzVROWVoV052eUhBbm8xbU5KbmF4YUkwVXNxeGNIRmJwN0E9PQ==",
      disabled: true,
      modalLoader:false,
      allChecked: false,
      dropdownOpen: false,
      appLoadingState: false,
      selectedRadioOption: "2",
      studList: [],
      multiSelectedSection: [],
      selectedDetailInfo: {},
      csvupload:"",

      selectedStudentValues: {
        first_name: null,
        last_name: null,
        person_name: null,
        person_number: null,
        roll_number: null,
        rfid:null,
        section: null,
        branch: null,
        image_status: null,
        person_class: null,
        is_active: null,
      },
      selectedValues: {
        selected_person_class: { label: "All", value: null },
        selected_is_active: { label: "All", value: -1 },
        selected_image_status: { label: "All", value: null },
        selected_section: { label: "All", value: null },
        selected_branch: { label: "All", value: null },
      },
    };
  }

  componentDidMount=async ()=> {
    await this.props.RecordPerPage(10)
    await this.props.PageNo(1);
    await   this.handleFilterDataTableList();
     
   }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageno !== this.props.pageno ||
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      this.handleFilterDataTableList();
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      this.handleFilterDataTableList();
      this.setState({
        allChecked: false,
      });
    }
  }

  handleStudentBulkDelete = () => {
    bulkActionType = "Delete";

    this.setState({ appLoadingState: false, showModal: true });
  };

  /**
   * Get the headings and data of csv on selection.
   */
  handleForce(data, fileInfo) {
    Json.action = bulkActionType;
    Json.data = data;
  }

  handelModalClose = () => {
    this.setState({
      showModal: false,
      selectedCsvFile: {},
      disabled: true,
    });
  };

  handleFiltersClear = () => {
    this.props.PageNo(1);
    this.setState(
      {
        selectedStudentValues: {
          person_name: "",
          person_number: "",
          roll_number: null,
          rfid:null,
          section:null,
        },
        multiSelectedSection: [],
        selectedValues: {
          selected_person_class: { label: "All", value: null },
          selected_is_active: { label: "All", value: null },
          selected_image_status: { label: "All", value: null },
          selected_section: { label: "All", value: null },
          selected_branch: { label: "All", value: null },
        },
      },
      () => this.handleFilterDataTableList()
    );
  };

  validateFilterField = ({ name, value }) => {
    const { selectedStudentFilters } = this.state;
    this.setState({
      selectedStudentFilters: {
        ...selectedStudentFilters,
        // [`invalid${name}`]: !value,
        [name]: value,
      },
    });
  };

  handleCsvChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      this.setState({
        selectedCsvFile: e.target.files[0],
        disabled: false,
      });
    }
  };

  handleUploadCSVC = () => {
    this.setState({
      modalLoader:true
    })
    const data = new FormData();
    data.append("csv_file", this.state.selectedCsvFile);
    data.append("flag", this.state.selectedRadioOption);
    data.append("person_type", this.state.person_type);
    importStudentBulk({ data })
      .then((response) => {
        if (response.code == 200) {

          this.setState({
            showModal: false,
            csvmodel:true,
            modalLoader:false,
            csvupload:response.data
          });
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.handleFilterDataTableList();
          return;
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.setState({
            modalLoader:false
          })
          this.props.notificationAction(info);
          return;
        }
      })
  };
  handleFilterApply = () => {
    this.handleFilterDataTableList();
  };

  handleFilterDataTableList = () => {
    this.setState({ appLoadingState: true });
    const { selectedStudentValues, person_type, selectedValues } = this.state;
    const { selected_is_active } = selectedValues;
    const { label} = selected_is_active
    const {
      person_name,
      person_number,
      roll_number,
      rfid,
      person_class,
      section,
      branch,
      image_status,
      is_active,
    } = selectedStudentValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      person_name ? person_name.replace(/^\s+|\s+$/g, '') : null,
      person_number ? person_number.replace(/^\s+|\s+$/g, '') : null,
      roll_number ? roll_number.replace(/^\s+|\s+$/g, '') : null,
      rfid ? rfid.replace(/^\s+|\s+$/g, '') : null,
      person_class ? person_class : null,
      section ? section : null,
      // branch ? branch : null,
      image_status ? image_status : null,
      label === "Active" ? true : null || label === "Inactive" ? false : null,
      person_type ? person_type : null
    )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            studList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState: false,
          });
        }
        else if (response.code != 200){
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            appLoadingState: false,
          });
        }
      })
      .catch((error) => console.log("Erorr in getting students ", error));
  };
  validateField = ({ name, value }) => {
    const { selectedStudentValues } = this.state;
    this.setState({
      selectedStudentValues: {
        ...selectedStudentValues,
        [name]: value,
      },
    });
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "person_name" ||
      name === "person_number" ||
      name === "roll_number" ||
      name === "rfid"
    ) {
      this.validateField({ name, value });
      return;
    }
  };
  handelDropDownChange = (selectedOption, obj) => {
    const { selectedValues, selectedStudentValues } = this.state;
    const {is_active} = selectedStudentValues
    const { name, value } = obj;

    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (
        obj.name === "person_class" ||
        obj.name === "is_active" ||
        obj.name === "image_status" ||
        obj.name === "section" ||
        obj.name === "branch"
      ) {
        this.setState({
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
          selectedStudentValues: {
            ...selectedStudentValues,
            [name]: null,
          },
        });
      }
      return;
    }
 
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedStudentValues: {
          ...selectedStudentValues,
          [name]: selectedOption.id,
        },
      });
      if(name == "is_active"){
        this.setState({
          selectedStudentValues: {
            ...selectedStudentValues,
           is_active: !is_active,
          },
        });
      }
      if( obj.name === "image_status"){
        this.setState({
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: selectedOption,
          },
          selectedStudentValues: {
            ...selectedStudentValues,
            [name]: selectedOption.value,
          },
        });
      }
  };
  handleGettingBulkRecord = (item) => {
    const listArray = [];
    item.data.map((item) => {
        listArray.push(item.person_id);
    });
    let statusData = {
      person_id: listArray,
      status: item.status == "1" ? true :false,
    };
    if (item.status == "delete") {
      const statusData = {
        person_id: listArray,
        status: "delete",
      };
      activeInActiveStudent({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
          else if (response.code != 200){
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.setState({
              allChecked: false,
            });
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "0") {
      activeInActiveStudent({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
          else if (response.code != 200){
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.setState({
              allChecked: false,
            });
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "1") {
      activeInActiveStudent({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
          else if (response.code != 200){
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.setState({
              allChecked: false,
            });
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    }
  };
  handleCheckOptionToggle = (item) => {
    if (item != null) {
      this.setState({
        allChecked: false,
        studList: this.state.studList.map((s) =>
          s.person_id == item.person_id ? { ...item, Checked: !s.Checked } : s
        ),
      });
    } else {
      this.setState({
        allChecked: !this.state.allChecked,
        studList: this.state.studList.map((s) => ({
          ...s,
          Checked: !this.state.allChecked,
        })),
      });
    }
  };

  handleTableButtonClick = (item, type) => {
    if (type == "Edit") {
      this.handleEmpEdit({
        selectedStudent: item,
      });
    } else if (type == "Delete") {
      this.handleEmpDelete(item.person_id);
    }
  };

  handleToggleButtonClick = (item) => {
    let allStudents = this.state.studList;
    let studentMapping = allStudents.find(function (element) {
      return element.person_id == item.person_id;
    });
    let studentStatus = null;
    if (studentMapping.status == true) {
      studentStatus = false;
    } else if (studentMapping.status == false) {
      studentStatus = true;
    }

    let statusData = {
      person_id: [studentMapping.person_id],
      is_active: studentStatus,
    };
    activeInActiveStudent({ statusData })
      .then((response) => {
        if (response.code === 200) {
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.handleFilterDataTableList();
          return;
        }
      })
      .catch((error, response) => {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };
  handelRadioChang = (e) => {
    this.setState({
      selectedRadioOption: e.target.value,
    });
  };

  handleEmpEdit = (item) => {
    this.props.history.push({
      pathname:
        "/students/list-of-students/edit-student/" +
       item.selectedStudent.person_id,
      state: {
        selectedDetailInfo: item.selectedStudent,
      },
    });
  };
  handelSweetModalDelete = (e) => {
    if (e) {
      const statusData = {
        person_id: [this.state.empId],
        status: "delete",
      };
      activeInActiveStaff({ statusData})
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.setState({
              sweetalert: false,
            });
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            return;
          }
          else if (response.code != 200){
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.setState({
              sweetalert: false,
            });
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else {
      this.setState({
        sweetalert: false,
      });
    }
  };
  handleEmpDelete = (Id) => {
    this.setState({
      empId: Id,
      sweetalert: true,
    });
  };
  handleExportCsv = () => {
    this.setState({ appLoadingState: true });
    const { download, person_type,selectedValues } = this.state;
    const {
      person_name,
      person_number,
      roll_number,
      rfid,
      person_class,
      section,
      // branch,
      image_status,
      is_active,
    } = this.state.selectedStudentValues;
    const { selected_is_active } = selectedValues;
    const { label} = selected_is_active
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      person_name ? person_name.replace(/^\s+|\s+$/g, '') : null,
      person_number ? person_number.replace(/^\s+|\s+$/g, '') : null,
      roll_number ? roll_number.replace(/^\s+|\s+$/g, '') : null,
      rfid ? rfid.replace(/^\s+|\s+$/g, '') : null,
      person_class ? person_class : null,
      section ? section : null,
      // branch ? branch : null,
      image_status ? image_status : null,
      label === "Active" ? true : null || label === "Inactive" ? false : null,
      person_type ? person_type : null,
      download ? 1 : null
    )
      .then((response) => {
        if (response.code == 200) {
          if (response.data.file_url != null) {
            window.open(response.data.file_url, "_blank");
          }
        }
        this.setState({ appLoadingState: false });
      })
      .catch((error) => {
        console.log("Erorr in getting students ", error);
      });
  };
  handelShowPictureModal = (item) => {
    this.setState({
      pictureModal: item && !this.state.pictureModal,
      imageUrl: item,
    });
  };
  CsvClose=(e)=>{
    this.setState({csvmodel:false})
  }

  render() {
const boolRfid = process.env.REACT_APP_RFID;
    return (
      <div>
        <Row> 
          {this.state.csvmodel ?<CsvModal show={this.state.csvmodel} Csvclose={this.CsvClose} csvdata={this.state.csvupload}/>:null}
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={3} lg={3}></Col>
          <SweetAlert
            show={this.state.sweetalert}
            deleteRecord={this.handelSweetModalDelete}
          />
          <Col md={9} lg={9}>
            <Dropdown
              size="md"
              className="pull-right"
              isOpen={this.state.dropdownOpen}
              toggle={() =>
                this.setState({ dropdownOpen: !this.state.dropdownOpen })
              }
            >
              <CSVLink
                filename="Student_Sample"
                className="mr-3 ml-3"
                data={boolRfid && boolRfid === "true" ? config.csvDataStudent_RFID: config.csvDataStudent}
              >
                {" "}
                <Button id="caret" color="primary">
                  {" "}
                  <AiFillFileExcel size={25} />
                </Button>{" "}
              </CSVLink>
              <DropdownToggle color="primary">
                {<GrStackOverflow />}&nbsp; Bulk Actions
              </DropdownToggle>
              <DropdownMenu className="dropdown-border">
                <DropdownItem></DropdownItem>
                <DropdownItem>
                  <Button
                    outline
                    color="primary"
                    className="bulkButton"
                    onClick={() => this.setState({ showModal: true })}
                  >
                    Bulk Add
                  </Button>
                </DropdownItem>
                <DropdownItem>
                  <Button
                    outline
                    color="primary"
                    className="bulkButton"
                    onClick={() => this.handleStudentBulkDelete()}
                  >
                    Bulk Delete
                  </Button>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            {/* <Button className="pull-right btn btn-primary">Bulk Actions</Button> */}
            {/* <Link to={"/student/add"} >   */}
            <Link to="/students/Add-Student">
              <Button outline color="primary" className="pull-right mt-1  add-full-width">
                <MdSchool /> &nbsp; Add Student
              </Button>
            </Link>
          </Col>
        </Row>
        <ImageModel
          show={this.state.pictureModal}
          imageUrl={this.state.imageUrl}
          onModalClose={this.handelShowPictureModal}
        />
        <StudentFilter
          onFilterApply={this.handleFilterApply}
          onExportCsv={this.handleExportCsv}
          SelectedValues={this.state.selectedValues}
          selectedFormValues={this.state.selectedStudentValues}
          onFormFieldChange={this.handleFormFieldChange}
          onDDChange={this.handelDropDownChange}
          multiSelectedSection={this.state.multiSelectedSection}
          onFilterReset={this.handleFiltersClear}
        />
        <AddCSV
          isOpen={this.state.showModal}
          onModalClose={this.handelModalClose}
          Disabled={this.state.disabled}
          loader={this.state.modalLoader}
          selectedRadioOption={this.state.selectedRadioOption}
          onDone={this.handleUploadCSVC}
          onRadioChange={this.handelRadioChang}
          onCsvChange={this.handleCsvChange}
        />
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            {this.state.studList && this.state.studList.length > 0 ? (
              //  {mockData.data && mockData.data.length > 0 ? (

              <Table
                primaryKeyField="sno"
                data={this.state.studList}
                isImageEnabled={true}
                isBadgeState={[true, "Image Status"]}
                onShowPicture={this.handelShowPictureModal}
                listItemToDisplay={[
                  { name: "First Name", valueField: "first_name" },
                  { name: "Last Name", valueField: "last_name" },
                  { name: "Registration #", valueField: "gu_id" },
                  { name: "Roll #", valueField: "roll_number" },
                  { name: "class", valueField: "person_class" },
                  { name: "section", valueField: "person_section" },
                  // { name: "Branch", valueField: "branch" },
                ]}
                onCheckOptionToggle={this.handleCheckOptionToggle}
                onGettingBulkRecords={this.handleGettingBulkRecord}
                isCheckOption={true}
                allChecked={this.state.allChecked}
                paginationData={this.state.paginationData}
                toggleButtonToDisplay={true}
                buttonsToDisplay={[
                  {
                    name: "Edit",
                    buttonLabel: "Edit",
                    icon: <MdEdit size={22} />,
                    Tooltipmessage: "Edit Student",
                  },
                  {
                    name: "Delete",
                    color: "#CE3939",
                    icon: <MdDelete size={22} />,
                    buttonLabel: "Delete",
                    Tooltipmessage: "Delete Student"
                  },
                  // {
                  //   name: "Details",
                  //   color: "gray",
                  //   buttonLabel: "Delete",
                  //   Tooltipmessage:"view Details",
                  //   icon: <FaChevronRight size={22} />,
                  // },
                ]}
                onTableButtonClick={this.handleTableButtonClick}
                onToggleStatus={this.handleToggleButtonClick}
              />
            ) : (
              <div className="text-center">
                There is no data available to show
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName,
});

export default connect(mapStateToProps, actions)(Student);
