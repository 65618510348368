import React, { Component } from "react";
import "../../../../assets/sass/_common.scss";
import { notificationAction } from "../../../../redux/reducers/notification_reducer";
import { Message } from "../../../../utils/messages.js";
import { connect } from "react-redux";
import ClassForm from "./Form/ClassForm";
import { addClass } from "../apiCalls";

class AddClass extends Component {
  state = {
    sid: 0,
    selectedClassInfo: {
      name: "",
      sections_ids:[]
    },
    multiSelectedSection: [],
    selectedValues: {
    },

    validateClass: {},
    
  };
 
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "name"

    ) {
      this.validateField({ name, value });
      return;
    }

  };
  findArrayElementByTitle(array, title) {
    return array.find((element) => {
      return (
        element.value.toLowerCase() === title.toLowerCase() ||
        element.label.toLowerCase() === title.toLowerCase()
      );
    });
  }
  handelSectionChange = (option) => {
    const {selectedClassInfo} = this.state;
    let arrayIds = [];
    option.map((item) => {
      arrayIds.push(item.id);
    });
    this.setState({
      multiSelectedSection: option,
      selectedClassInfo: {
        ...selectedClassInfo,
        sections_ids: arrayIds,
      },
    });
  };
handelNameChange=(e)=>{
  const { value } = e.target;
  const {selectedClassInfo, validateClass} = this.state;
    this.setState({
      selectedClassInfo: {
        ...selectedClassInfo,
        name: value,
      },
      validateClass: {
        ...validateClass,
        [`invalidname`]: !value,
        // [name]: value,
      },
    })
  
}
  handleSaveClass = () => {
    let { selectedClassInfo, validateClass } = this.state;
    const{name} = selectedClassInfo
    let sections_ids = null;
    sections_ids=this.state.selectedClassInfo.sections_ids
    
    if (!selectedClassInfo.name) {
      this.setState({
        validateClass: {
          ...validateClass,
          invalidname: true,
        },
      });
      const info = {
        message: Message.AddClass,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }   
    this.setState({
      Loading: true,
    });
    let selectedClass = {name, sections_ids}
    addClass({ name: selectedClass })
      .then((response) => {
        if (response.code == 200) {
          const info = {
            message: Message.AddSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.history.push("/lookups/class");
          return;
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        const info = {
          message: Message.AddFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };
  render() {
    return (
      <>
        <ClassForm
          selectedClassInfo={this.state.selectedClassInfo}
          onFormFieldChange={this.handleFormFieldChange}
          validateClass={this.state.validateClass}
          onSectionChange={this.handelSectionChange}
          onNameChange={this.handelNameChange}
          SelectedValues={this.state.selectedValues}
          multiSelectedSection={this.state.multiSelectedSection}
          onSave={this.handleSaveClass}
        />
      </>
    )
  }
}
const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});


export default connect(null, actions)(AddClass);
