
import React from "react";
import { departmentDetails, updateDepartment } from "../apiCalls";
import { notificationAction } from "../../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../../redux/actions/types";
import { Message } from "../../../../utils/messages.js";
import { connect } from "react-redux";
import DepartmentForm from "./Form/DepartmentForm";
import LoadingMask from "../../../../components/Loader/LoadingMask";
class EditDepartment extends React.Component {
 constructor(props) {
   super(props);
   this.state = {
     loading:true,
     id: props.match.params.id,
     validateDepartment: {},
     selectedDepartmentInfo: {},
     selectedValues: {
 
     },
   };
 }
  componentDidMount() {
   let department_id =this.props.match.params.id
   departmentDetails( department_id )
     .then((response) => {
       if (response.code == 200) {
         this.setState({
           selectedCompanyInfo: response.data,
           loading:false,
         }, () => this.handleDepartmentEdit(response.data));
       } else if (response.code == 400) {
        this.setState({loading:false})
         this.props.notificationAction(info);
         const info = {
           message: response.message,
           status: "error",
         };
         this.props.history.push("/");
         return;
       } else {
         const info = {
           message: response.message,
           status: "error",
         };
         this.props.notificationAction(info);
         return;
       }
     })
     .catch((error) => {
       console.log(error);
     });
 }
 
 
 handleUpdateDepartment = () => {
   let { selectedDepartmentInfo, validateDepartment } = this.state;
 
   if (!selectedDepartmentInfo.name) {
     this.setState({
       validateDepartment: {
         ...validateDepartment,
         invalidname: true,
       },
     });
     const info = {
       message: Message.AddDepartment,
       status: "error",
     };
     this.props.notificationAction(info);
     return;
   }
   const { name } = selectedDepartmentInfo;
   const did = this.state.id;
   let postData = { department_id: did, name: name };
 
   updateDepartment({ postData })
     .then((response) => {
       if (response.code == 200) {
         this.props.history.push("/lookups/department")
         const info = {
           message: Message.UpdateSuccess,
           status: "success",
         };
         this.props.notificationAction(info);
       } else if (response.code == 400) {
         const info = {
           message: Message.UpdateFailure,
           status: "error",
         };
         this.props.notificationAction(info);
       }
     })
     .catch((error) => {
       const info = {
         message: Message.UpdateFailure,
         status: "error",
       };
       this.props.notificationAction(info);
     });
 };
 
 
 
 
 handleFormFieldChange = (e) => {
   const { name, value } = e.target;
   if (
     name === "name"
   ) {
     this.validateField({ name, value });
     return;
   }
 };
 
 validateField = ({ name, value }) => {
   const { selectedDepartmentInfo, validateDepartment } = this.state;
   if (name === "name"
   ) {
     this.setState({
       selectedDepartmentInfo: {
         ...selectedDepartmentInfo,
         // [`invalid${name}`]: !value,
         [name]: value,
       },
       validateDepartment: {
         ...validateDepartment,
         [`invalid${name}`]: !value,
         // [name]: value,
       },
     })
   }
 }
 
 handleDepartmentEdit = (departmentClicked) => {
   this.setState({
     selectedDepartmentInfo: departmentClicked,
     did: departmentClicked.id,
   });
 };
 
 render() {
 
   return (
     <>
     {this.state.loading?<LoadingMask/>:
       <DepartmentForm
         selectedDepartmentInfo={this.state.selectedDepartmentInfo}
         SelectedValues={this.state.selectedValues}
         validateDepartment={this.state.validateDepartment}
         onFormFieldChange={this.handleFormFieldChange}
         onDepartmentUpdate={this.handleUpdateDepartment}
         formBranchAdd={false}
       />
   }
     </>
   )
 }
}
 
const actions = (dispatch) => ({
 notificationAction: (message, status) =>
   dispatch(notificationAction(message, status)),
});
 
 
export default connect(null, actions)(EditDepartment);