import React, { Component } from "react";
import CaptureImage from "../../../components/profile/CaptureImage";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import { Message } from "../../../utils/messages.js";
import { connect } from "react-redux";
import StudentForm from "./Form/StudentForm";
import { addStudent } from "../apiCalls";
import HttpService from "../../../services/HttpService";
import {isValidEmail} from "../../../utils/helpers"

class AddStudent extends Component {
  state = {
    showModal: false,
    picture: false,
    imgData: null,
    sid: 0,
    profile_image: null,
    selectedDOBDate: "",
    selectedStudentInfo: {
      reg_number: "",
      first_name: "",
      middle_name: "",
      rfid:"",
      email: "",
      phone_number: "",
      last_name: "",
      person_type: "",
      status: "1",
      date_of_birth: null,
      email: "",
      address_1: "",
      address_2: "",
      roll_number: "",
    },
    selectedGuardianInfo: {
      full_name: "",
      relation: "",
      phone: "",
      address_1: "",
    },
    selectedValues: {},
    validateStudent: {},
  };

  componentDidMount() {
    this.getLookUp();
  }

  modeladd = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  async getLookUp() {
    let respData = await HttpService.getDynamicTypesList()
    this.setState({
      selectedStudentInfo: {
        ...this.state.selectedStudentInfo,
        person_type: respData[4].id
      },
    });
  }

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
     
    if (
      name === "first_name" ||
      name === "middle_name" ||
      name === "rfid" ||
      name === "last_name" ||
      name === "reg_number" ||
      name === "roll_number" ||
      name === "phone_number" ||
      name === "email"
    
    ) {
      this.validateField({ name, value });
      return;
    }
    if (
      name === "full_name" ||
      name === "relation" ||
      name === "phone" ||
      name === "address_1" 
    ) {
      this.validateGurdianField({ name, value });
      return;
    }
  };

  validateGurdianField = ({ name, value }) => {
    const {selectedGuardianInfo,  validateStudent  } =
      this.state;
      this.setState({
        selectedGuardianInfo: {
          ...selectedGuardianInfo,
          [name]: value,
        },
        validateStudent: {
          ...validateStudent,
          [`invalid${name}`]: !value,
        },
      });
  };
  validateField = ({ name, value }) => {
    const { selectedStudentInfo, validateStudent } =
      this.state;
      this.setState({
        selectedStudentInfo: {
          ...selectedStudentInfo,
          [name]: value,
        },
        validateStudent: {
          ...validateStudent,
          [`invalid${name}`]: !value,
        },
      });
  };

  handelDropDownChange = (selectedOption, obj) => {
    const { selectedStudentInfo, selectedValues, validateStudent } = this.state;

    const { value } = selectedOption;
    const { name } = obj;
    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (
        obj.name === "person_class" ||
        obj.name === "section_id" ||
        obj.name === "branch"
      ) {
        this.setState({
          selectedStudentInfo: {
            ...selectedStudentInfo,
            [obj.name]: "",
          },
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
        });
      }
      return;
    } else {
      this.setState({
        selectedStudentInfo: {
          ...selectedStudentInfo,
          [name]: selectedOption.id,
        },
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        validateStudent: {
          ...validateStudent,
          [`invalid${name}`]: !value,
        },
      });
    }
  };
  DateToFormattedString(d) {
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 1).toString();
    var dd = d.getDate().toString();
    return (
      yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0])
    );
  }

  handleDOBChange = (d) => {
    let result = d && this.DateToFormattedString(d);
    this.setState({
      selectedStudentInfo: {
        ...this.state.selectedStudentInfo,
        date_of_birth: result,
      },
      selectedDOBDate: d,
    });
  };
  handleimageAdded = (imagsid) => {
    this.state.imgData = imagsid;
    this.setState({
      selectedStudentInfo: {
        ...this.state.selectedStudentInfo,
        profile_image: imagsid,
      },
    });
  };
  handleSaveStudent = () => {
    let { selectedStudentInfo, validateStudent } = this.state;

    if (!selectedStudentInfo.first_name) {
      this.setState({
        validateStudent: {
          ...validateStudent,
          invalidfirst_name: true,
        },
      });
      const info = {
        message: Message.FirstName,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedStudentInfo.last_name) {
      this.setState({
        validateStudent: {
          ...validateStudent,
          invalidlast_name: true,
        },
      });
      const info = {
        message: Message.LastName,
        status: "error",
      };

      this.props.notificationAction(info);
      return;
    }

    if (!selectedStudentInfo.reg_number) {
      this.setState({
        validateStudent: {
          ...validateStudent,
          invalidreg_number: true,
        },
      });
      const info = {
        message: Message.EnterRegNo,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (this.state.imgData === null) {
      this.setState({
        validateStudent: {
          invalidperson_image: true,
        },
      });
      const info = {
        message: Message.AddImage,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if(selectedStudentInfo.email!=""){
      if (!isValidEmail(selectedStudentInfo.email)) {
        this.setState({
          validateStudent: {
            ...validateStudent,
            invalidemail: true,
          },
        });
        const info = {
          message: Message.AddValidEmail,
          status: "error",
        };
        this.props.notificationAction(info);
        return;
      }
    }
    let { selectedGuardianInfo } = this.state;
    let person = selectedStudentInfo;
    let contact = selectedGuardianInfo;

    const selectedStudentInformation = { person, contact };
    this.setState({
      Loading: true,
    });
    addStudent(selectedStudentInformation)
      .then((response) => {
        if (response.code == 200) {
          const info = {
            message: Message.AddSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.history.push("/students");
          return;
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        const info = {
          message: Message.AddFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  render() {
    return (
      <>
        {this.state.showModal ? <CaptureImage /> : ""}
        <StudentForm
          selectedStudentInfo={this.state.selectedStudentInfo}
          selectedGuardianInfo={this.state.selectedGuardianInfo}
          SelectedValues={this.state.selectedValues}
          validateStudent={this.state.validateStudent}
          selectedDOBDate={this.state.selectedDOBDate}
          onFormFieldChange={this.handleFormFieldChange}
          onSave={this.handleSaveStudent}
          onDOBChange={this.handleDOBChange}
          onDDChange={this.handelDropDownChange}
          onImageAdded={this.handleimageAdded}
          isEdit={true}
        />
      </>
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(AddStudent);
