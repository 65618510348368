import React, { Component } from "react";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import { Message } from "../../../utils/messages.js";
import { connect } from "react-redux";
import { editUser, getUserDetail } from "../apiCalls";
import UsersForm from "./Form/UsersForm";
import { isValidEmail } from "../../../utils/helpers";
import { departmentDetails } from "../../lookups/Department/apiCalls";
import LoadingMask from "../../../components/Loader/LoadingMask";

class EditUsers extends Component {
  state = {
    sid: 0,
    selectedUserInfo: {
      username: "",
      person_email: "",
    },
    selectedCheckBoxes: {},
    loading: true,
    validateUser: {},
  };

  componentDidMount = () => {
    let user_id = this.props.match.params.id;
    getUserDetail(user_id)
      .then((response) => {
        if (response.code == 200) {
          this.setState(
            {
              selectedCompanyInfo: response.data,
              loading: false,
            },
            () => this.handleUserEdit(response.data)
          );
        } else if (response.code == 400) {
          this.setState({ loading: false });
          this.props.notificationAction(info);
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.history.push("/");
          return;
        } else {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleUserEdit = (payload) => {
    const { selectedUserInfo, selectedCheckBoxes } = this.state;
    this.setState({
      selectedUserInfo: {
        ...selectedUserInfo,
        username: payload.username,
        person_email: payload.email,
      },
      selectedCheckBoxes: {
        ...selectedCheckBoxes,
        student_checked: payload.has_students_app_permissions,
        students_reports_checked: payload.has_students_reports_permissions,
        staff_checked: payload.has_staff_app_permissions,
        staff_reports_checked: payload.has_staff_reports_permissions,
        devices_checked: payload.has_devices_app_permissions,
        lookups_checked: payload.has_lookups_app_permissions,
        users_checked: payload.has_users_app_permissions,
      },
      did: payload.id,
    });
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "username" || name === "person_email") {
      this.validateField({ name, value });
      return;
    }
  };

  validateField = ({ name, value }) => {
    const { selectedUserInfo, validateUser } = this.state;

    this.setState({
      selectedUserInfo: {
        ...selectedUserInfo,
        [name]: value,
      },
      validateUser: {
        ...validateUser,
        [`invalid${name}`]: !value,
      },
    });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    if (
      name === "student" ||
      name === "students_reports" ||
      name === "staff" ||
      name === "staff_reports" ||
      name === "devices" ||
      name === "lookups" ||
      name === "users"
    ) {
      this.validateCheckbox({ name, checked });
      return;
    }
  };
  validateCheckbox = ({ name, checked }) => {
    console.log("name check", name, checked, this.state.selectedCheckBoxes);
    const { selectedCheckBoxes } = this.state;
    this.setState({
      selectedCheckBoxes: {
        ...selectedCheckBoxes,
        [`${name}_checked`]: checked,
      },
    });
  };
  handleSaveUser = () => {
    let { selectedUserInfo, selectedCheckBoxes, validateUser } = this.state;
    console.log("checkboxes", selectedCheckBoxes);
    const {
      devices_checked,
      lookups_checked,
      staff_checked,
      staff_reports_checked,
      student_checked,
      students_reports_checked,
      users_checked,
    } = selectedCheckBoxes;
    this.setState({
      Loading: true,
    });

    if (!selectedUserInfo.username) {
      this.setState({
        validateUser: {
          ...validateUser,
          invalidusername: true,
        },
      });
      const info = {
        message: Message.EnterUsername,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedUserInfo.person_email) {
      this.setState({
        validateUser: {
          ...validateUser,
          invalidperson_email: true,
        },
      });
      const info = {
        message: Message.AddEmail,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (selectedUserInfo.person_email != "") {
      if (!isValidEmail(selectedUserInfo.person_email)) {
        this.setState({
          validateUser: {
            ...validateUser,
            invalidperson_email: true,
          },
        });
        const info = {
          message: Message.AddValidEmail,
          status: "error",
        };
        this.props.notificationAction(info);
        return;
      }
    }

    let studentPost = {
      id: this.props.match.params.id,
      email: selectedUserInfo.person_email,
      username: selectedUserInfo.username,
      has_students_app_permissions: student_checked,
      has_staff_app_permissions: staff_checked,
      has_students_reports_permissions: students_reports_checked,
      has_staff_reports_permissions: staff_reports_checked,
      has_lookups_app_permissions: lookups_checked,
      has_devices_app_permissions: devices_checked,
      has_users_app_permissions: users_checked,
    };
    editUser({ user: studentPost })
      .then((response) => {
        if (response.code == 200) {
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.history.push("/users");
          return;
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        const info = {
          message: Message.AddFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  render() {
    return (
      <>
      {this.state.loading ? <LoadingMask /> : 
        <UsersForm
          isEdit={true}
          selectedUserInfo={this.state.selectedUserInfo}
          validateUser={this.state.validateUser}
          selectedCheckBoxes={this.state.selectedCheckBoxes}
          onFormFieldChange={this.handleFormFieldChange}
          onCheckboxChange={this.handleCheckboxChange}
          onSave={this.handleSaveUser}
        /> }
      </>
    );
  }
}
const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(EditUsers);
