export const class_type = [
    { value: 'admin', label: 'admin', color: '#00B8D9', isFixed: true },
    { value: 'employee', label: 'employee', color: '#0052CC', isFixed: true },
    { value: 'all', label: 'All', color: '#5243AA' },
  ];
  export const Type = [
    { value: 'no_confirmation', label: 'No Confirmation'},
    { value: 'multiple_options', label: 'Multiple Options'},
    { value: 'both', label: 'Both', color: '#5243AA' },
  ];
 export const customStyles = {
    control: (base, state) => ({
      ...base,
    
      // background: "#F7F9FB 0% 0% no-repeat padding-box",
      // Overwrittes the different states of border
    
    })
  };
  export const Image_Status = [
    { value: 'Update_Image', label: 'Update Image', color: '#00B8D9', isFixed: true },
    { value: 'ok', label: 'OK', color: '#0052CC', isFixed: true },
    { value: 'all', label: 'All', color: '#5243AA' },
  ];