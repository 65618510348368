export const Message = {
  /////////////////////////
  InvalidEmployeeNoField: "Employee-No Should Be Numeric Values",
  EnterRegNo: "Enter Registration No",
  EnterEmployeeNo:"Enter Employee No",
  FirstName: "Enter First Name",
  LastName: "Enter Last Name",
  AddImage:"Please upload image",
  EmailAddress: "Enter Valid Email Address (Maximun Length 50)",
  ContactPerson: "Enter Contact Number",
  PersonType: "Select Person Type",
  AddClass:"Enter Class Name",
  AddDevice:"Enter Device Name",
  AddSection:"Enter Section Name",
  AddBranch:"Enter Branch Name",
  SelectBranch:"Select Branch Name",
  AddLocation:"Enter Location Name",
  EnterUsername:"Enter User Name",
  AddEmail:"Enter Email address",
  AddValidEmail:"Enter Valid Email address",
  AddDepartment:"Enter Department Name",
  AddSuccess: "Added Successfully",
  IncreaseLength: "Enter at least 6 number",
  AddFailure: "Error! Could Not Add",
  UpdateSuccess: "Updated Successfully",
  UpdateFailure: "Error! Could Not Updated",
  DeleteSuccess: "Deleted Successfully",
  DeleteError: "Error in Deleting",
  EnterOldPassword: "Enter Old Password",
  EnterPassword: "Enter Password",
  EnterPassword2: "Enter New Password",
  SelectAttendanceType: "Select Attendance Status",

};
export const ToolTipMessages = {
  Search: "Search",
  Add: "Click this button to add",
  Reset: "Reset",
  Save: "Save",
  Delete: "Delete",
  ExpandSection: "click this button to expand the section",
  CollapseSection: "click this button to collapse this section",
  Reload: "Click to reload error logs",
  Clear: "Click to clear logs",
  ImportExcelList: "Click Here to import excel sheet",
};
