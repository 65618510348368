import React from "react";
import { classDetails, updateClass } from "../apiCalls";
import { notificationAction } from "../../../../redux/reducers/notification_reducer";
import HttpService from "../../../../services/HttpService";

import * as ActionTypes from "../../../../redux/actions/types";
import { Message } from "../../../../utils/messages.js";
import { connect } from "react-redux";
import ClassForm from "./Form/ClassForm";
import LoadingMask from "../../../../components/Loader/LoadingMask";
let classId;
class EditClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      id: props.match.params.id,
      classList: [],
      sectionList: props.location.state.sectionList,
      appLoadingState: false,
      validateClass: {},
      multiSelectedSection: [],
      passData: false,
      selectedClassInfo: {},
    
    };
  }

  componentDidMount() {
    classId = this.props.match.params.id;

    classDetails(classId)
      .then((response) => {
        if (response.code == 200) {
          this.setState(
            {
              selectedClassInfo: response.data,
              loading:false
            },
            () => this.handleClassEdit(response.data)
          );
        } else if (response.code != 200) {
          this.setState({loading:false})
          this.props.notificationAction(info);
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.history.push("/");
          return;
        } else {
          const info = {
            message: response.message,
            status: "error",
          };

          // this.props.history.push("/");
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleUpdateClass = () => {
    let { selectedClassInfo, validateClass, multiSelectedSection } = this.state;
    const { name } = selectedClassInfo;
    const eid = [this.state.id];
    let arrayIds = [];
    multiSelectedSection.map((item) => {
      arrayIds.push(item.id);
    });
    let postData = { class_id: classId, sections_ids: arrayIds, name: name };

    if (!selectedClassInfo.name) {
      this.setState({
        validateClass: {
          ...validateClass,
          invalidname: true,
        },
      });
      const info = {
        message: Message.AddClass,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    updateClass({ postData })
      .then((response) => {
        if (response.code == 200) {
          this.props.history.push("/lookups/class");
          const info = {
            message: Message.UpdateSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      })
      .catch((error) => {
        const info = {
          message: Message.UpdateFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
    // if (selectedClassInfo.section) {
    //   let results = this.findArrayElementByTitle(
    //     this.state.section,
    //     selectedClassInfo.section
    //   );
    //   selectedClassInfo = {
    //     ...selectedClassInfo,
    //     section: results.value,
    //   };
    // }
  };
  handelDropDownChange = (selectedOption, obj) => {
    const { selectedClassInfo, selectedValues } = this.state;
    const { name } = obj;

    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (obj.name === "section") {
        this.setState({
          selectedClassInfo: {
            ...selectedClassInfo,
            [obj.name]: "",
          },
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
        });
      }
      return;
    } else {

      this.setState({
        selectedClassInfo: {
          ...selectedClassInfo,
          [name]: selectedOption.id,
        },
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
      });
    }
  };

  findArrayElementByTitle(array, title) {
    return array.find((element) => {
      return (
        element.value.toLowerCase() === title.toLowerCase() ||
        element.label.toLowerCase() === title.toLowerCase()
      );
    });
  }

  handelNameChange = (e) => {
    const { value } = e.target;
    const { selectedClassInfo, validateClass } = this.state;
    this.setState({
      selectedClassInfo: {
        ...selectedClassInfo,
        name: value,
      },
      validateClass: {
        ...validateClass,
        [`invalidname`]: !value,
        // [name]: value,
      },
    });
  };
  handelSectionChange = (option) => {
    const { selectedClassInfo } = this.state;
    let arrayIds = [];
    option.map((item) => {
      arrayIds.push(item.id);
    });
    this.setState({
      multiSelectedSection: option,
      selectedClassInfo: {
        ...selectedClassInfo,
        sections_ids: arrayIds,
      },
    });
  };

  handleClassEdit = (studentClicked) => {


    let allSections = studentClicked.sections;
    const length = allSections.length;
    let i = 0;
    let newArrayOptions =[];
    while ( i < length) {
      let newRec = this.findArrayElementByTitle(
        this.state.sectionList,
        allSections[i]
      );
      i++;
      newArrayOptions.push(newRec)
    }
    this.setState({
      multiSelectedSection: newArrayOptions,
      selectedClassInfo: studentClicked,
      eid: studentClicked.id,
      passData: true,
    });
  };

  render() {
    return (
      <>
      {this.state.loading?<LoadingMask/>:
        <ClassForm
          selectedClassInfo={this.state.selectedClassInfo}
          validateClass={this.state.validateClass}
          onNameChange={this.handelNameChange}
          onSectionChange={this.handelSectionChange}
          SelectedValues={this.state.selectedValues}
          multiSelectedSection={this.state.multiSelectedSection}
          onClassUpdate={this.handleUpdateClass}
          isEdit={true}
        />
    } 
      </>
      
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(EditClass);
