import React, { Component } from "react";
import { Button, Modal, Row, Col, ModalBody, Badge, ModalHeader } from "reactstrap";
import "react-image-crop/dist/ReactCrop.css";
import { AiFillWarning } from "react-icons/ai";
import { MdClose } from "react-icons/md";

class CsvModel extends Component {
  render() {
    const { Csvclose, csvdata } = this.props;
    console.log(csvdata, "csvdata");

    return (
      <div>
        <div>
          <Modal
            className="modal-dialog modal-content rounded Model-shape "
            isOpen={this.props.show}
            size="lg"
          >
               
            <ModalBody
              style={{
                maxHeight: "calc(100vh - 210px)",
                overflowY: "auto",
              }}
            >
              <Col xs={12} lg={12}>
                <Row>
                  <Col xs={12} lg={12}>
                    {csvdata &&
                      csvdata.map((user) => (
                        <>
                          <Row>
                            <Col xs={3} lg={3}>
                              <Badge
                                color={`${
                                  user.code == 200 ? "success" : "danger"
                                }`}
                              >
                                {" "}
                                Status
                              </Badge>{" "}
                              : {user.code}
                            </Col>
                            <Col xs={9} lg={9}>
                            <Badge
                                color={`${
                                  user.code == 200 ? "success" : "danger"
                                }`}
                              >
                                {" "}
                                Message
                              </Badge>{" "}    : {user.message}
                            </Col>
                          </Row>
                        </>
                      ))}
                  </Col>
                </Row>
              </Col>
              <Row>
              <Col sm={12} md={9} lg={9}></Col>
                <Col sm={12} md={3} lg={3}>
                  <Row
                    sm="12"
                    md={"8"}
                    className=" justify-content-around justify-content-center p-3 mb-4"
                  >
                    <Col className="ml-3">
                      <Button
                         className="btn-round btn-block float-right  "
                         color="info"
                      
                         onClick={() => {
                          Csvclose(false);
                        }}
                      >
                        Close
                      </Button>
                    </Col>
                   
                  </Row>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default CsvModel;
