import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import "../../../../../assets/sass/_common.scss";
import { withRouter } from "react-router";

class BranchForm extends Component {

  handelGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };
  render() {
    const {
      selectedBranchInfo,
      onFormFieldChange,
      onSave,
      validateBranch,
      onBranchUpdate,
    } = this.props;
    const {
      name,
    } = selectedBranchInfo;

    const {
      invalidname,
    } = validateBranch;
   return (
      <div>
        <div className="content">
          <Row>
            <Col lg="12" md="12" sm="12">
              <Card className="card-user">
          
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label required">
                            {"Branch"}
                          </label>
                          <Input
                            type="text"
                            autoFocus={invalidname}
                            className={`input-text-field  form-label" ${invalidname ? "inValid" : ""
                              }`}
                            name="name"
                            value={name}
                            onChange={onFormFieldChange}
                            maxLength={"20"}

                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4} lg={3} sm={12} xs={12}>
                        <Button
                          className="mb-3"
                          outline
                          color="primary"
                          onClick={() => this.handelGoBack()}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col lg={6} md={4} sm={12} xs={12} />
                      <Col lg={3} sm={12} md={4} xs={12}>
                        {this.props.isEdit ?
                          <Button
                            className="btn-round  float-right  "
                            color="primary"
                            onClick={() => onBranchUpdate()}
                            type="button"
                          >
                            Update Branch

                          </Button>
                          :
                          <Button
                            className="btn-round float-right  "
                            color="primary"
                            onClick={() => onSave()}
                            type="button"
                          >
                            Add Branch
                          </Button>
                        }

                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}



export default (withRouter(BranchForm));
