import * as ActionTypes from "../actions/types";
export const notificationAction = ({ message, status }) => {
  return {
    type: ActionTypes.NOTIFICATION,
    notification: {
      message,
      status,
    },
  };
};
