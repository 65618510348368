import React, { Component } from "react";
import { notificationAction } from "../../../../redux/reducers/notification_reducer";
import { Message } from "../../../../utils/messages.js";
import { connect } from "react-redux";
import DepartmentForm from "./Form/DepartmentForm";
import { addDepartment } from "../apiCalls";

class AddDepartment extends Component {
  state = {
    did: 0,
    selectedDepartmentInfo: {
      name: "",
    },

    validateDepartment: {},
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "name"

    ) {
      this.validateField({ name, value });
      return;
    }

  };

  validateField = ({ name, value }) => {

    const { selectedDepartmentInfo, validateDepartment } = this.state;
    if (name === "name"
    ) {
      this.setState({
        selectedDepartmentInfo: {
          ...selectedDepartmentInfo,
          // [`invalid${name}`]: !value,
          [name]: value,
        },
        validateDepartment: {
          ...validateDepartment,
          [`invalid${name}`]: !value,
          // [name]: value,
        },
      })
    }
  }
  haveSaveDepartment = () => {
    let { selectedDepartmentInfo, validateDepartment } = this.state;
    if (!selectedDepartmentInfo.name) {
      this.setState({
        validateDepartment: {
          ...validateDepartment,
          invalidname: true,
        },
      });
      const info = {
        message: Message.AddDepartment,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    this.setState({
      Loading: true,
    });
    addDepartment({  selectedDepartmentInfo })
      .then((response) => {
        if (response.code == 200) {
          const info = {
            message: Message.AddSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.history.push("/lookups/department");
          return;
        } else if (response.code == 400) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        const info = {
          message: Message.AddFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };


  render() {
    return (
      <>
        <DepartmentForm
          selectedDepartmentInfo={this.state.selectedDepartmentInfo}
          validateDepartment={this.state.validateDepartment}
          onFormFieldChange={this.handleFormFieldChange}
          onSave={this.haveSaveDepartment}
          formBranchAdd={true}

        />
      </>
    )


  }

}
const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});


export default connect(null, actions)(AddDepartment);
