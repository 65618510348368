import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { Collapse, Badge } from "reactstrap";
import { Route } from "react-router";
import classnames from "classnames";
import { RiArrowUpSLine } from "react-icons/ri";

import s from "./LinksGroup.module.scss";

let childrenlink = false;
class LinksGroup extends React.Component {
  static propTypes = {
    header: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    childrenLinks: PropTypes.array,
    iconName: PropTypes.object,
    className: PropTypes.string,
    badge: PropTypes.string,
    label: PropTypes.string,
    activeItem: PropTypes.string,
    isHeader: PropTypes.bool,
    index: PropTypes.string,
    deep: PropTypes.number,
    onActiveSidebarItemChange: PropTypes.func,
    labelColor: PropTypes.string,
    exact: PropTypes.bool,
  };

  static defaultProps = {
    link: "",
    childrenLinks: null,
    header: "",
    iconName: "",
    classname: "",
    isHeader: false,
    deep: 0,
    activeItem: "",
    label: "",
    exact: true,
  };

  constructor(props) {
    super(props);
    this.togglePanelCollapse = this.togglePanelCollapse.bind(this);

    this.state = {
      headerLinkWasClicked: false,
      changecolor: false,
    };
  }

  togglePanelCollapse(link, e) {
    this.props.onActiveSidebarItemChange(link);
    this.setState({
      changecolor: true,
      headerLinkWasClicked:
        !this.state.headerLinkWasClicked ||
        (this.props.activeItem &&
          !this.props.activeItem.includes(this.props.index && this.props.link)),
    });

    e.preventDefault();
  }

  render() {
    const changeclass = JSON.parse(localStorage.getItem("sidebar-collapsed"));

    let checkValue =
      this.props.activeItem &&
      this.props.activeItem.includes(this.props.link && this.props.index);
    const isOpen =
      this.props.activeItem &&
      // //  we comments this line becasue we did not need first active item to collapse when another item is clicked
      //  this.props.activeItem.includes(this.props.index)  &&
      this.state.headerLinkWasClicked;

    const { exact } = this.props.exact;

    if (!this.props.childrenLinks) {
      if (this.props.isHeader) {
        return (
          <li className={[s.headerLink, this.props.className].join(" ")}>
            <NavLink
              to={this.props.link}
              activeClassName={s.headerLinkActive}
              exact={exact}
              target={this.props.target}
            >
              <span className={s.icon}>{this.props.iconName}</span>
              {this.props.header}
              {this.props.label && (
                <sup
                  className={`${s.headerLabel} text-${
                    this.props.labelColor || "warning"
                  }`}
                >
                  {this.props.label}
                </sup>
              )}
              {this.props.badge && (
                <Badge className={s.badge} color="secondary-red" pill>
                  {this.props.badge}
                </Badge>
              )}
            </NavLink>
          </li>
        );
      }

      return (
        // children

        <li>
          <NavLink
            to={this.props.link}
            activeClassName={s.headerLinkActive}
            onClick={(e) => {
              if (this.props.link.includes("menu")) {
                e.preventDefault();
              }
              this.props.onActiveSidebarItemChange(this.props.index);
            }}
            exact={exact}
          >
            <div
              className={
                !changeclass ? s.activeChildern : s.activeChildernCollapse
              }
            >
              <i className="float-left" style={{ paddingRight: 8 }}>
                {this.props.iconName}
              </i>

              <p className="float-right">{this.props.header}</p>
            </div>
          </NavLink>
        </li>
      );
    }

    return (
      <Route
        path={this.props.link}
        children={(params) => {
          const { match } = params;
          return (
            //atiq2
            <li
              style={{
                background: checkValue
                  ? "#286AD9"
                  : "" || (isOpen && this.state.changecolor)
                  ? "#283345"
                  : "",
              }}
              className={classnames(
                { [s.headerLink]: this.props.isHeader },
                this.props.className
              )}
            >
              <a
                className={classnames(
                  s.accordionToggle,
                  // { [s.headerLinkActive]: match },
                  { [s.collapsed]: isOpen },
                  "d-flex"
                )}
                // style={{ paddingLeft: `${this.props.deep == 0 ? 16 : 35 + 10 * (this.props.deep - 1)}px`}}
                onClick={(e) => this.togglePanelCollapse(this.props.link, e)}
                href="#top"
              >
                {this.props.isHeader ? (
                  <span className={s.icon}>{this.props.iconName}</span>
                ) : null}
                {this.props.header}
                {this.props.label && (
                  <sup
                    className={`${s.headerLabel} text-${
                      this.props.labelColor || "warning"
                    } ml-1`}
                  >
                    {this.props.label}
                  </sup>
                )}
                {!changeclass ? (
                  <b className={["fa fa-angle-down", s.caret].join(" ")} />
                ) : null}
              </a>
              <Collapse className={s.panel} isOpen={isOpen}>
                <ul>
                  {this.state.changecolor &&
                    this.props.childrenLinks &&
                    this.props.childrenLinks.map((child, ind) => (
                      <LinksGroup
                        onActiveSidebarItemChange={
                          this.props.onActiveSidebarItemChange
                        }
                        activeItem={this.props.activeItem}
                        header={child.header}
                        link={child.link}
                        index={child.index}
                        iconName={child.iconName}
                        childrenLinks={child.childrenLinks}
                        deep={this.props.deep + 1}
                        key={ind}
                      />
                    ))}
                </ul>
              </Collapse>
            </li>
          );
        }}
      />
    );
  }
}
function mapStateToProps(store) {
  return {
    isCollapse: store.navigation.isCollapse,
  };
}

export default withRouter(connect(mapStateToProps)(LinksGroup));
