import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import tableReducer from "./reducers/table_reducer";
import goBackReducer from "./reducers/toggleReducer";
import groupReducer from "./reducers/groups_reducer";

import navigation from "./reducers/navigationReducer";
import register from "../redux/reducers/register";
import auth from "../redux/reducers/authReducer";
import authorization from "../../src/services/authorization";
const rootReducer = combineReducers({
  tab: tableReducer,
  tog: goBackReducer,
  group: groupReducer,
  register,
  auth,
  navigation,
});

const middleware = [thunk];
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
export default store;
