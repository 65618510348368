import { ApiUrls } from "../../utils/apiUrls";

import authAxios from "../../services/axiosInstance";


export const uploadBulkImages = (file) => {
  let formData = new FormData();
  formData.append("image", file);
  return authAxios
    .post(ApiUrls.Person.IMPORT_IMAGES, formData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const deleteBulkImage = (id) => {
  return authAxios
    .delete(ApiUrls.Media.DELETE_BULK_IMAGE,{ data: { id: id.id } })
    .then(({ data }) => data)
    .catch((response) => response);
};