import React from "react";
import {branchDetails, updateBranch } from "../apiCalls";
import { notificationAction } from "../../../../redux/reducers/notification_reducer";
import { Message } from "../../../../utils/messages.js";
import { connect } from "react-redux";
import BranchForm from "./Form/BranchForm";
import LoadingMask from "../../../../components/Loader/LoadingMask";
class EditLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      loading:true,
      branchList: [],
      appLoadingState: false,
      validateBranch: {},
      passData:false,
      selectedBranchInfo:{},
      selectedValues: {
      },
    };
  }

  componentDidMount() {

    let branch_id = this.props.match.params.id;
    branchDetails( branch_id )
      .then((response) => {
        if (response.code == 200) {  
          this.setState({
            selectedBranchInfo: response.data,
            loading:false
          },()=>this.handleBranchEdit(response.data) );
        } else if (response.code != 200) {
          this.setState({loading:false})
          this.props.notificationAction(info);
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.history.push("/");
          return;
        } else {
          const info = {
            message: response.message,
            status: "error",
          };

          // this.props.history.push("/");
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleUpdateBranch = () => {
    let { selectedBranchInfo, validateBranch } = this.state;

    if (!selectedBranchInfo.name) {
      this.setState({
        validateBranch: {
          ...validateBranch,
          invalidname: true,
        },
      });
      const info = {
        message: Message.AddBranch,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    const {name}=selectedBranchInfo;
    const bid = this.state.id;

    let postData = { branch_id: bid, name: name };

    updateBranch({ postData })
      .then((response) => {
        if (response.code == 200) {
         this.props.history.push("/lookups/branch")
          const info = {
            message: Message.UpdateSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
        } else if (response.code != 200) {
          const info = {
            message: Message.UpdateFailure,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      })
      .catch((error) => {
        const info = {
          message: Message.UpdateFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };
 
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "name" 
    ) {
      this.validateField({ name, value });
      return;
    }

  };

  validateField = ({ name, value }) => {

    const { selectedBranchInfo, validateBranch } = this.state;
    if(name === "name" 
     ){
    this.setState({
      selectedBranchInfo: {
        ...selectedBranchInfo,
        // [`invalid${name}`]: !value,
        [name]: value,
      },
      validateBranch: {
        ...validateBranch,
        [`invalid${name}`]: !value,
        // [name]: value,
      },
    })}
  }

  handleBranchEdit = ( branchClicked ) => {
    let newSelectedValues = this.state.branchList.find(
      (c) => c.branch_number === branchClicked.branch_number
    );
    newSelectedValues = {
      ...newSelectedValues,
    };

    this.setState({
      selectedBranchInfo: branchClicked,
      selectedValues: newSelectedValues,
      bid: branchClicked.id,
      passData:true
    });
  };

  render() {
  
    return (
      <>
      {this.state.loading ? <LoadingMask/> :
       <BranchForm
        selectedBranchInfo={this.state.selectedBranchInfo}
        onFormFieldChange={this.handleFormFieldChange}
        validateBranch={this.state.validateBranch}
        onBranchUpdate={this.handleUpdateBranch}
        isEdit={true}
      />
    }
   </>
  
    )
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(EditLocation);


