import React, { useEffect, useRef, useState } from "react";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel,
  PreviewContainerSpan,
  PreviewListText,
} from "./file-upload.styles";
import { BsImages } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { BsXSquare } from "react-icons/bs";
import { MdContentCopy } from "react-icons/md";

import { deleteBulkImage, uploadBulkImages } from "./apiCalls";
import { Button } from "reactstrap";
import { notificationAction } from "../../redux/reducers/notification_reducer";
import { connect } from "react-redux";
import { Fragment } from "react";
import "./import.css";
import Tooltip from "../../components/Tooltip/ToolTip";
const KILO_BYTES_PER_BYTE = 1024;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10485760;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);
const fileSizeCheck = (bytes) => {
  if (bytes > DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
    return false;
  } else {
    return true;
  }
};
const initialProgress = 10;
const FileUpload = ({
  label,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});
  const [toolTipContent, setToolTipContent] = useState("Copy Image URL!");

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      // if (file.size <= maxFileSizeInBytes) {
      if (!otherProps.multiple) {
        return { file };
      }
      files[file.name] = file;
      // }
    }
    return { ...files };
  };
  // useEffect(() => {
  //   let refreshObjArray = setTimeout(() => {
  //     setFiles({ ...files });
  //   }, 500);

  //   return () => {
  //     clearTimeout(refreshObjArray);
  //   };
  // }, [files]);
  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
      uploadFiles(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    const filesAsArray = convertNestedObjectToArray(files);
    const newFileLength = Object.keys(newFiles).length;
    if (newFiles.length && filesAsArray.length + newFileLength  <=50) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
    else {
        const info = {
          message: "Can't add more than 50 images",
          status: "info",
        };
        otherProps.notificationAction(info);
      }
  };

  const removeLargeAndPNGFile = (fileName, imageid) => {
    if (!imageid) {
      delete files[fileName];
      return;
    }
  };
  const removeFile = (fileName, imageid) => {
    if (!imageid) {
      removeLargeAndPNGFile(fileName);
      return;
    }
    deleteBulkImage({ id: imageid }).then((response) => {
      if (response.code == 200) {
        delete files[fileName];
        setFiles({ ...files });
        const info = {
          message: response.message,
          status: "success",
        };
        otherProps.notificationAction(info);
      }
      if (response.code == 400) {
        const info = {
          message: response.message,
          status: "error",
        };
        otherProps.notificationAction(info);
      }
    });
  };

  const uploadFiles = (file) => {
    const selectedFiles = file;
    for (let i = 0; i < selectedFiles.length; i++) {
      upload(selectedFiles[i]);
    }
  };
  const upload = async (file) => {
  await  uploadBulkImages(file)
      .then((response) => {
        if (response.code == 200) {
          let newObj = {
            imageid: response.data.id,
            image_path: response.data.image_path,
            progressbar: 100,
          };
          Object.assign(file, newObj);
        } else if (response.code == 400) {
          let newObj = { progressbar: 50 };
          Object.assign(file, newObj);
          const info = {
            message: response.message,
            status: "error",
          };
          otherProps.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
      setFiles({ ...files });
  };
  const handleCopyText = (e) => {
    navigator.clipboard.writeText(e);
  };
  return (
    <>
      <FileUploadContainer>
        <InputLabel>{label}</InputLabel>
        <BsImages size={80} color="#286AD9" />
        <ul className="listGroup">
          <li>JPG or JPEG</li>
          <li>Smaller than 10 MB per Image</li>
          <li> Maximum 50 Images</li>
        </ul>

        <Button
          color="primary"
          className=""
          type="button"
          onClick={handleUploadBtnClick}
        >
          <i className="fas fa-file-upload" />
          <span> Upload {otherProps.multiple ? "files" : "a file"}</span>
        </Button>
        <FormField
          type="file"
          ref={fileInputField}
          accept=".jpg,.jpeg"
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        {files &&
        Object.keys(files).length === 0 &&
        Object.getPrototypeOf(files) === Object.prototype ? (
          ""
        ) : (
          <span>To Upload</span>
        )}
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let progressbar = file.progressbar;
            let imageid = file.imageid;
            let image_path = file.image_path;
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <PreviewContainer key={fileName} className="mt-5">
                <div>
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData isImageFile={isImageFile}>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>

                      {fileSizeCheck(file.size) ? (
                        <AiFillDelete
                          className="deleteIcon pull-right"
                          onClick={() => removeFile(fileName, imageid)}
                        />
                      ) : (
                        <BsXSquare
                          className="deleteIcon pull-right"
                          onClick={() =>
                            removeLargeAndPNGFile(fileName, imageid)
                          }
                        />
                      )}
                    </aside>
                  </FileMetaData>
                </div>
                {fileSizeCheck(file.size) ? (
                  <Fragment>
                    {progressbar !== 50 && progressbar !== 100 ? (
                      <div className="progresser">
                        <div className="color"></div>
                      </div>
                    ) : (
                      ""
                    )}

                    {image_path ? (
                      <Fragment>
                        <PreviewListText>
                          <PreviewContainerSpan>
                            <p className="font-weight-bold">{fileName}</p>
                            <Tooltip content={image_path} direction="right">
                              <p>
                                View Url
                                {/* {image_path}  */}
                              </p>
                            </Tooltip>
                          </PreviewContainerSpan>
                          <Tooltip content={toolTipContent} direction="top">
                            <p
                              className="text-center mt-3"
                              name={`"${fileName}"`}
                              onClick={() => handleCopyText(image_path)}
                            >
                              <MdContentCopy />
                            </p>
                          </Tooltip>
                        </PreviewListText>
                      </Fragment>
                    ) : (
                      <p className="text-center text-danger m-2"></p>
                    )}
                  </Fragment>
                ) : (
                  <span className="text-danger">
                    File Size is greater than 10 MB
                  </span>
                )}
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  );
};

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(FileUpload);
