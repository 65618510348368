import axios from "axios";
 import { LOGIN } from "../../utils/apiUrls";
import auth from "../reducers/authReducer";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export function receiveLogin() {
  return {
    type: LOGIN_SUCCESS,
  };
}

function loginError(payload) {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

// logs the user out
export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    localStorage.clear();
    dispatch(receiveLogout());
  };
}

export function loginUser(credentials) {
  return async (dispatch) => {
    dispatch(receiveLogin());
    if (credentials.username.length > 0 && credentials.password.length > 0) {
      // localStorage.setItem("authenticated", true);
      const params = {
        username: credentials.username,
        password: credentials.password,
      };
      return axios
        .post(LOGIN, params)
        .then((response) => response)
        .catch((response) => response);
    } else {
      dispatch(loginError("Something was wrong. Try again"));
    }
  };
}
