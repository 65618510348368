import React, { Component } from "react";
import { MdClose } from "react-icons/md";
import { BASE_URL } from "../../utils/apiUrls";

import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";

export default class ImageModel extends Component {
  render() {
    const { onModalClose, imageUrl, onDone } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.show}
          toggle={onModalClose}
          size={"lg"}
          centered
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <MdClose
                  className="float-right mb-1"
                  size={22}
                  onClick={onModalClose}
                  color="black"
                />
                <img
                  style={{ objectFit: "cover" }}
                  className="rounded d-block w-100"
                  src={imageUrl && BASE_URL + "/" + imageUrl}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}