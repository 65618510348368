import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Notification from "../../components/Notification/Notification.js";
import s from "./Notifications.module.scss";
import { notificationAction } from "../../redux/reducers/notification_reducer";
import successIcon from "../../assets/notificationsIcons/successIcon.svg";
import closeIcon from "../../assets/notificationsIcons/closeIcon.svg";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const positions = [
  toast.POSITION.TOP_LEFT,
  toast.POSITION.TOP_CENTER,
  toast.POSITION.TOP_RIGHT,
  toast.POSITION.BOTTOM_LEFT,
  toast.POSITION.BOTTOM_CENTER,
  toast.POSITION.BOTTOM_RIGHT
];

const notificationTypes = ["info", "success", "warning", "error"]

const Notifications =  (props) => {
  const [notificationPosition, setNotificationPosition] = useState(2)
  useEffect(() => {
    let timer = setTimeout( () =>hideNotification(), 100);
    return () => {
      clearTimeout(timer);
   }
  }, [])
  const hideNotification = () => {
    props.notificationAction({
      message: "",
      status: "",
    });
  };
  const options = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
   position: positions[2],
  };

  const {  status, message } = props;
  return (

  //             type="info"
  //             type="error"
  //             type="success
  //             type="warning"
  <ToastContainer>
   {toast(<Notification type={status} message={message}/>, options)}

   </ToastContainer>  )

}

function mapStateToProps(store) {
  return {
    message: store.group.message,
    status: store.group.status,
  };
}


export default connect(mapStateToProps, { notificationAction })(Notifications);
