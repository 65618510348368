import axios from "axios";
import qs from "qs";
import storage from "./storage";
const TOKEN_KEY = "token";
const USER = "user";
const BASE_URL = "";
class authorization {
  setBaseUrl = () => {
    axios.defaults.baseURL = BASE_URL;
  };
  getToken = () => {
    return JSON.parse(storage.getItem({ key: TOKEN_KEY }));
  };

  tokenExist = () => !!this.getToken();

  saveToken = (token) => {
    storage.setItem({ key: TOKEN_KEY, value: token });
  };

  clearToken = () => {
    storage.removeItem({ key: TOKEN_KEY });
  };

  saveUser = ({ user }) => {
    storage.setItem({ key: USER, value: user });
  };

  getLoggedInUser = () => {
    return JSON.parse(storage.getItem({ key: USER }));
  };

  clearUser = () => {
    storage.removeItem({ key: USER });
  };

  isLoggedIn = () => this.tokenExist();
}

export default new authorization();
