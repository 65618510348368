export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const LOAD_USER = "LOAD_USER";
export const LOAD_EMPLOYEE = "LOAD_EMPLOYEE";

//General
export const GO_BACK = "GO_BACK";
export const STEP_1 = "STEP_1";
export const LOADING = "LOADING";

//notification
export const NOTIFICATION = "NOTIFICATION";

// Table
export const PAGE_NO = "PAGE_NO";
export const RECORDS_PER_PAGE = "RECORDS_PER_PAGE";
export const SORT_BY = "SORT_BY";
export const SORT_NAME = "SORT_NAME";


// Employees
export const GET_EMPLOYEES = "GET_EMPLOYEES";

// Groups
export const INITIALIZE_PROPS = "INITIALIZE_PROPS";
