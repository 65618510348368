import React from "react";
import { DeviceDetails, updateDevice, updatePassword } from "../apiCalls";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import HttpService from "../../../services/HttpService";
import { Message } from "../../../utils/messages";
import { connect } from "react-redux";
import DeviceForm from "./Form/DeviceForm";
class DeviceConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      deviceList: [],
      validateDevice: {},
      selectedDeviceInfo: {
        device_id:props.match.params.id,
        old_password: null,
        new_password: null,
      },
    };
  }

  // componentDidMount() {
  //   this.getLookUp();
  // }
  // async getLookUp() {
  //   this.setState({ site: await HttpService.getSitesList() });
  // }
  handleUpdateDevice = () => {
    let { selectedDeviceInfo, validateDevice } = this.state;

    if (!selectedDeviceInfo.old_password || selectedDeviceInfo.old_password.length <6 ) {
      this.setState({
        validateDevice: {
          ...validateDevice,
          invalid_old_password: true,
        },
      });
      const info = {
        message:  Message.EnterOldPassword,
        status: "error",
      };
      this.props.notificationAction(info);
      const old=selectedDeviceInfo.old_password.length <6;
      
      if(old){
        const info = {
          message:  Message.IncreaseLength,
          status: "error",
        };
        this.props.notificationAction(info);
        return;
      }else{
      const info = {
        message:  Message.EnterOldPassword,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    
    }

    if (!selectedDeviceInfo.new_password ||  selectedDeviceInfo.new_password.length <6) {
      this.setState({
        validateDevice: {
          ...validateDevice,
          invalid_new_password: true,
        },
      });
      const info = {
        message: Message.EnterPassword2,
        status: "error",
      };
      this.props.notificationAction(info);
      const old=selectedDeviceInfo.new_password.length <6;
 
      if(old){
        const info = {
          message:  Message.IncreaseLength,
          status: "error",
        };
        this.props.notificationAction(info);
        return;
      }else{
      const info = {
        message: Message.EnterPassword2,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    }
    
    updatePassword({ postData: selectedDeviceInfo})
      .then((response) => {
        if (response.code == 200) {
          this.props.history.push("/devices");
          const info = {
            message: Message.UpdateSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
        } else if (response.code != 200) {
          const info = {
            message: Message.UpdateFailure,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      })
      .catch((error) => {
        const info = {
          message: Message.UpdateFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "old_password" || "new_password") {
      this.validateField({ name, value });
      return;
    }
  };

  validateField = ({ name, value }) => {
    const { selectedDeviceInfo, validateDevice } = this.state;
    this.setState({
      selectedDeviceInfo: {
        ...selectedDeviceInfo,
        [name]: value,
      },
      validateDevice: {
        ...validateDevice,
        [`invalid_${name}`]: !value,
      },
    });
  };

  render() {
    return (
      <>
        <DeviceForm
          selectedDeviceInfo={this.state.selectedDeviceInfo}
          onFormFieldChange={this.handleFormFieldChange}
          validateDevice={this.state.validateDevice}
          onDeviceUpdate={this.handleUpdateDevice}
        />
      </>
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(DeviceConfiguration);
