import React, { Component } from "react";
import ApexCharts from "react-apexcharts";
import { IoMdMedkit } from "react-icons/io";
export default class ApexLineChart extends Component {
  render() {
    const series =  this.props.WeeklyData && this.props.WeeklyData.slice(0, 3)
    const days = this.props.WeeklyData && this.props.WeeklyData[3].days
    const chartSettings = {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "category",
        categories: days ,
        labels: {
          style: {
            colors: "#6B859E",
            opacity: 0.7,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: ["#6B859E"],
            opacity: 0.7,
          },
        },
      },
      tooltip: {
        x: {
          show: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 1,
          stops: [40, 90, 100],
        },
      },
      colors: ["#4D53E0", "#41D5E2",  "#FEB019",],
      chart: {
        toolbar: {
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            reset: true,
          },
        },
      },
      legend: {
        show: true,
        horizontalAlign: "center",
      },
    };
    return (
      <ApexCharts
        options={chartSettings}
        series={series}
        type="area"
        height={300}
      />
    );
  }
}
