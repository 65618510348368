import React from "react";
import "./loadingMask.scss";
import Loader from './loader.gif'
const LoadingMask = ({ text = "Loading data..." }) => {
  return (
    <div className="loadingMaskWrapper">
      <div className="loading">
        <div className="loadingImageWrapper">
          <img className="loadingImg" src={Loader} alt="loading" />
        </div>
        <div className="loadingText">{text}</div>
      </div>
    </div>
  );
};
export default LoadingMask;

