import * as ActionTypes from "../actions/types";
const initialState = {
  pageno: 1,
  recordperpage: 10,
  sortName:"id",
  sortBy:"desc"
  
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PAGE_NO:
      return {
        ...state,
        pageno: action.pageno,
      };
    case ActionTypes.RECORDS_PER_PAGE:
      return {
        ...state,
        recordperpage: action.recordperpage,
      };
      case ActionTypes.SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      };
      case ActionTypes.SORT_NAME:
        return {
          ...state,
          sortName: action.sortName,
        };

    default:
      return { ...state };
  }
};

export default reducer;
