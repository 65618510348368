import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import FileUpload from "./FileAndImageUpload";
export default class ImportImages extends Component {
  render() {
    let template;
    template = (
      <div>
        <form>
          <Row>
            <Col>
              <FileUpload
                updateFilesCb={this.handleMultipleImagesLoad}
                // accept=".jpg,.jpeg"
                multiple
              />
            </Col>
          </Row>
        </form>
      </div>
    );

    return <div className="">{template} </div>;
  }
}
