import * as ActionTypes from "../actions/types";
const initialState = {
  passpropstochild: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INITIALIZE_PROPS:
      return {
        ...state,
        passpropstochild: action.passpropstochild,
      };
    case ActionTypes.NOTIFICATION:
      const { notification } = action;
      return {
        ...state,
        ...notification,
        message: notification.message,
        status: notification.status,
      };
    default:
      return { ...state };
  }
};

export default reducer;
