import React, { Component } from "react";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import { Message } from "../../../utils/messages.js";
import { connect } from "react-redux";
import { addUser } from "../apiCalls";
import UsersForm from "./Form/UsersForm";
import { isValidEmail } from "../../../utils/helpers";

class AddUsers extends Component {
  state = {
    sid: 0,
    selectedUserInfo: {
      username: "",
      firstname: "",
      lastname: "",
      person_email: "",
      password: "",
    },
    selectedCheckBoxes: {
      student:false,
      students_reports:false,
      staff:false,
      staff_reports:false,
      devices:false,
      lookups:false,
      users:false
    },

    validateUser: {},
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "username" ||
      name === "person_email" ||
      name === "lastname" ||
      name === "firstname" ||
      name === "password"
    ) {
      this.validateField({ name, value });
      return;
    }
  };

  validateField = ({ name, value }) => {
    const { selectedUserInfo, validateUser } = this.state;

    this.setState({
      selectedUserInfo: {
        ...selectedUserInfo,
        [name]: value,
      },
      validateUser: {
        ...validateUser,
        [`invalid${name}`]: !value,
      },
    });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (
      name === "student" ||
      name === "students_reports" ||
      name === "staff" ||
      name === "staff_reports" ||
      name === "devices" ||
      name === "lookups" ||
      name === "users"
    
    ) {
      this.validateCheckbox({ name, checked });
      return;
    }
  }
  validateCheckbox = ({ name, checked }) => {
    const { selectedCheckBoxes } = this.state;
    this.setState({
      selectedCheckBoxes: {
        ...selectedCheckBoxes,
        [name]: checked,
      },
    });
  };
  handleSaveUser = () => {
    let { selectedUserInfo, selectedCheckBoxes, validateUser } = this.state;
    this.setState({
      Loading: true,
    });
    if (!selectedUserInfo.firstname) {
      this.setState({
        validateUser: {
          ...validateUser,
          invalidfirstname: true,
        },
      });
      const info = {
        message: Message.FirstName,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedUserInfo.lastname) {
      this.setState({
        validateUser: {
          ...validateUser,
          invalidlastname: true,
        },
      });
      const info = {
        message: Message.LastName,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedUserInfo.username) {
      this.setState({
        validateUser: {
          ...validateUser,
          invalidusername: true,
        },
      });
      const info = {
        message: Message.EnterUsername,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedUserInfo.person_email) {
      this.setState({
        validateUser: {
          ...validateUser,
          invalidperson_email: true,
        },
      });
      const info = {
        message: Message.AddEmail,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if(selectedUserInfo.person_email!=""){
      if (!isValidEmail(selectedUserInfo.person_email)) {
        this.setState({
          validateUser: {
            ...validateUser,
            invalidperson_email: true,
          },
        });
        const info = {
          message: Message.AddValidEmail,
          status: "error",
        };
        this.props.notificationAction(info);
        return;
      }
       
    }
    if (!selectedUserInfo.password) {
      this.setState({
        validateUser: {
          ...validateUser,
          invalidpassword: true,
        },
      });
      const info = {
        message: Message.EnterPassword,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    let studentPost = {
      first_name: selectedUserInfo.firstname,
      last_name: selectedUserInfo.lastname,
      password: selectedUserInfo.password,
      email: selectedUserInfo.person_email,
      username: selectedUserInfo.username,
      permissions:selectedCheckBoxes
    };
    addUser({ user: studentPost })
      .then((response) => {
        if (response.code == 200) {
          const info = {
            message: Message.AddSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.history.push("/users");
          return;
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        const info = {
          message: Message.AddFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  render() {
    return (
      <>
        <UsersForm
          selectedUserInfo={this.state.selectedUserInfo}
          validateUser={this.state.validateUser}
          selectedCheckBoxes={this.state.selectedCheckBoxes}
          onFormFieldChange={this.handleFormFieldChange}
          onCheckboxChange={this.handleCheckboxChange}
          onSave={this.handleSaveUser}
        />
      </>
    );
  }
}
const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(AddUsers);
