import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import "../node_modules/react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap-daterangepicker/daterangepicker.css';
import "@pathofdev/react-tag-input/build/index.css";
import App from './components/App';
import store from './redux/store';



ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

