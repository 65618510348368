import React from "react";
import {SectionDetails, updateSection } from "../apiCalls";
import { notificationAction } from "../../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../../redux/actions/types";
import { Message } from "../../../../utils/messages.js";
import { connect } from "react-redux";
import SectionForm from "./Form/SectionForm";
import LoadingMask from "../../../../components/Loader/LoadingMask";
class EditSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      id: props.match.params.id,
      sectionList: [],
      appLoadingState: false,
      validateSection: {},
      passData:false,
      selectedSectionInfo:{},
      selectedValues: {
      },
    };
  }

  componentDidMount() {
    let sectionId =  this.props.match.params.id;

    SectionDetails( sectionId )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            selectedSectionInfo: response.data,
            loading:false,
          },()=>this.handleSectionEdit(response.data) );
        } else if (response.code != 200) {
          this.setState({loading:false})
          this.props.notificationAction(info);
          const info = {
            message: response.message,
            status: "error",
          };
          return;
        } else {
          const info = {
            message: response.message,
            status: "error",
          };

          // this.props.history.push("/");
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }



  handelNext = () => {
  
  
  };

  handleUpdateSection = () => {
    let { selectedSectionInfo, validateSection } = this.state;

    if (!selectedSectionInfo.name) {
      this.setState({
        validateSection: {
          ...validateSection,
          invalidname: true,
        },
      });
      const info = {
        message: Message.AddSection,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    const {name}=selectedSectionInfo;
    const eid = this.state.id;

    let postData = { section_id: eid, name: name };
    updateSection({ postData })
      .then((response) => {
        if (response.code == 200) {
         this.props.history.push("/lookups/section")
          const info = {
            message: Message.UpdateSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);

        }
      })
      .catch((error) => {
        const info = {
          message: Message.UpdateFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };
 
 


  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "name" 
    ) {
      this.validateField({ name, value });
      return;
    }

  };

  validateField = ({ name, value }) => {

    const { selectedSectionInfo, validateSection } = this.state;
    if(name === "name" 
     ){
    this.setState({
      selectedSectionInfo: {
        ...selectedSectionInfo,
        // [`invalid${name}`]: !value,
        [name]: value,
      },
      validateSection: {
        ...validateSection,
        [`invalid${name}`]: !value,
        // [name]: value,
      },
    })}
  }



  handleSectionEdit = ( sectionClicked ) => {
    let newSelectedValues = this.state.sectionList.find(
      (c) => c.section_number === sectionClicked.section_number
    );
    newSelectedValues = {
      ...newSelectedValues,
    };

    this.setState({
      selectedSectionInfo: sectionClicked,
      selectedValues: newSelectedValues,
      eid: sectionClicked.id,
      passData:true
    });
  };

  render() {
  
    return (
      <>
      {this.state.loading?<LoadingMask/>:
       <SectionForm
        selectedSectionInfo={this.state.selectedSectionInfo}
        onFormFieldChange={this.handleFormFieldChange}
        validateSection={this.state.validateSection}
        onSectionUpdate={this.handleUpdateSection}
        formSectionAdd={true}
      />
    }
   </>
    )
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(EditSection);


