import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import "../../../../assets/sass/_common.scss";
import Select from "react-select";
import HttpService from "../../../../services/HttpService";
import { withRouter } from "react-router";
import { MdRemoveRedEye, MdVisibilityOff } from "react-icons/md";

class DeviceForm extends Component {
  state = {
    inputType: "password",
    confirmType: "password",
  };

  handelGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };
  handelShowPassword = (values) => {
    if (values === "password") {
      this.setState({
        inputType: this.state.inputType == "password" ? "text" : "password",
      });
    }
    if (values === "new_password") {
      this.setState({
        confirmType: this.state.confirmType == "password" ? "text" : "password",
      });
    }
  };
  render() {

    const {
      selectedDeviceInfo,
      onFormFieldChange,
      validateDevice,
      onDeviceUpdate,
    } = this.props;
    const { old_password, new_password } = selectedDeviceInfo;
    const { inputType, confirmType } = this.state;
    const { invalid_old_password,invalid_new_password } = validateDevice;
    return (
      <div>
        <div className="content">
          <Row>
            <Col lg="12" md="12" sm="12">
              <Card className="card-user">
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="6">
                        <div className="mb-3">
                          <p className="body-3 text-muted mb-0">Device Name</p>
                          <p className="body-1 mb-0">{"Android-st"}</p>
                        </div>
                      </Col>
                      <Col className="pr-1" md="6"></Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <label className="form-label required">
                          {"Old Password"}
                        </label>
                        <div className="input-icons">
                          <i
                            onClickCapture={() => {
                              this.handelShowPassword("password");
                            }}
                            className="icon"
                          >
                            {inputType == "password" ? (
                              <MdRemoveRedEye size={22} />
                            ) : (
                              <MdVisibilityOff size={22} />
                            )}
                          </i>
                          <Input
                            type={inputType}
                            autoFocus={invalid_old_password}
                            className={`input-text-field   form-label" ${
                              invalid_old_password ? "inValid" : ""
                            }`}
                            maxLength={8}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            name="old_password"
                            value={old_password}
                            onChange={onFormFieldChange}
                          />
                        </div>
                      </Col>
                      <Col className="pr-1" md="6">
                        <label className="form-label required">
                          {"New Password"}
                        </label>
                        <div className="input-icons">
                          <i
                            onClickCapture={() => {
                              this.handelShowPassword("new_password");
                            }}
                            className="icon"
                          >
                            {confirmType == "password" ? (
                              <MdRemoveRedEye size={22} />
                            ) : (
                              <MdVisibilityOff size={22} />
                            )}
                          </i>
                          <Input
                            type={confirmType}
                            className={`input-text-field   form-label" ${
                              invalid_new_password ? "inValid" : ""
                            }`}
                            maxLength={8}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            name="new_password"
                            value={new_password}
                            onChange={onFormFieldChange}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4} lg={3} sm={12} xs={12}>
                        <Button
                          className="mb-3 btn-block"
                          outline
                          color="primary"
                          onClick={() => this.handelGoBack()}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col lg={6} md={4} sm={12} xs={12} />
                      <Col lg={3} sm={12} md={4} xs={12}>
                        <Button
                          className="btn-round btn-block float-right  "
                          color="primary"
                          onClick={() => onDeviceUpdate()}
                          type="button"
                        >
                          Update Device
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(DeviceForm);
