import React, { useState } from "react";
import "./Tooltip.css";
const Tooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        window.innerWidth &&
        <div className={`Tooltip-Tip ${props.direction || "top"}`}>
          {/* Content */}
          {props.content.length > 45 ? 
          <>
          <div> {props.content.substr(0, 45)}</div> 
          <div>
          {props.content.substr(46) }
          </div>
          </>
          :props.content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
