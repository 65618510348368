import { ApiUrls } from "../../utils/apiUrls";
import authAxios from "../../services/axiosInstance";

export const getCounts = (payload) => {
    return authAxios
    .get(ApiUrls.Dashboard.GET_COUNT_LIST, {
      params: {
        person_id: JSON.stringify(payload),
      },
    })

    .then(({ data }) => data)
    .catch((response) => response);
};

export const getWeeklyGraphs = () => {
  return authAxios
    .get(ApiUrls.Dashboard.GET_WEEKLY_DATA)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getMonthlyGraph = (payload) => {
  return authAxios
    .get(ApiUrls.Dashboard.GET_MONTHLY_DATA, {
    params: {
      year_month: payload,
    },
  })
    .then(({ data }) => data)
    .catch((response) => response);
};
