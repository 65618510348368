import React from "react";
import { Col, Row } from "reactstrap";
import Table from "../../../components/Table/Table";
import MAttendanceFilter from "./MAttendanceFilter";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../redux/actions/types";
import LoadingMask from "../../../components/Loader/LoadingMask";
import ImageModel from '../../../components/image/ImageModel'
import AttendanceDetailModal from "../../student-reports/manual-attendance/detail-modal";
import { connect } from "react-redux";
import { getMAttendanceList,addManualAttendance  } from "./apiCalls";
import { HiArrowRight } from "react-icons/hi";
import { Message } from "../../../utils/messages";

class ManualStaffAttendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureModal: false,
      imageUrl: "",
      person_type: "Z0FBQUFBQmlNeXNpdkVRQXBsSGl2a1h0LWEyRkFITV94ZjR3NkIyaXlUUmh2OUdiU0FkSjJBNmhlOTROU2I3UW9aOVRINUhFWjlnd1gyREk1V0ZUZ05sZnZfTEQ4dk1GLVE9PQ==",
      paginationData: [],
      toggle: false,
      attendanceList: [],
      selectedAttendanceFilters: {
        student_name: "",
      },
      attendanceValues: {
        person_id: null,
        attendance_type: "",
        in_date_time: new Date(),
        out_date_time: null,
      },
      invalid_attendance_type:false,
      showEditModal: false,
      selectedTimeIn: new Date(),
      selectedTimeOut: null,
      DetailData: {},
      selected_Attendance: { label: "Select Type", value: "" },
    };
  }
  componentDidMount = async () => {
    await this.props.RecordPerPage(10)
    await this.props.PageNo(1);
    await this.handleFilterDataTableList();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      this.handleFilterDataTableList();
    }
    if (prevProps.pageno !== this.props.pageno) {
      this.handleFilterDataTableList();
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      this.props.PageNo(1);
      this.handleFilterDataTableList();
    }
  }

  handleApplyFilter = () => {
    this.props.PageNo(1);
    this.handleFilterDataTableList()
  }
  handleFilterFieldChange = (e) => {
    const { name, value } = e.target;
    const { selectedAttendanceFilters } = this.state;
    if (name === "student_name") {
    
    this.setState({
      selectedAttendanceFilters: {
        ...selectedAttendanceFilters,
        [name]: value,
      },
    });
    return;
  };
}
 
  handleFilterDataTableList = async () => {
    const { person_type } = this.state
    this.setState({ appLoadingState: true });
    const {
      student_name,
    } = this.state.selectedAttendanceFilters;
    await getMAttendanceList(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      student_name ? student_name.replace(/^\s+|\s+$/g, "") : null,
      person_type ? person_type : null,
    )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            attendanceList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState: false
          });

        }
        else if (response.code != 200) {
          this.setState({ appLoadingState: false })
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
        else {

        }
      })
      .catch((error, response) => {
        const info = {
          message: error.message,
          status: "error",
        };

        this.props.notificationAction(info);
      });
  };
  handleOutTimeChange = (d) => {
    let result = d && this.DateToFormattedString(d);
    const { attendanceValues } = this.state;
    this.setState({
      attendanceValues: {
        ...attendanceValues,
        out_date_time: result,
      },
      selectedTimeOut: d,
    });
  };
  DateToFormattedString(d) {
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 1).toString();
    var dd = d.getDate().toString();
    var minutes = d.getMinutes();
    var hours = d.getHours();
    return (
      yyyy +
      "-" +
      (mm[1] ? mm : "0" + mm[0]) +
      "-" +
      (dd[1] ? dd : "0" + dd[0]) +
      " " +
      ((hours > 0 ? hours : "00") +
        ":" +
        (minutes > 0 ? minutes : "00") +
        ":" +
        "00")
    );
  }
  handleInTimeChange = (d) => {
    let result = d && this.DateToFormattedString(d);
    const { attendanceValues } = this.state;
    this.setState({
      attendanceValues: {
        ...attendanceValues,
        in_date_time: result,
      },
      selectedTimeIn: d,
    });
  };
  handleAttendanceChange = (selectedOption) => {
    const { attendanceValues } = this.state;

    if (!selectedOption) {
      return;
    }
    this.setState({
      attendanceValues: {
        ...attendanceValues,
        attendance_type: selectedOption.id,
      },
      invalid_attendance_type:false,
      selected_Attendance: selectedOption,
    });
  };
  handelShowPictureModal = (item) => {
    this.setState({
      pictureModal: item && !this.state.pictureModal,
      imageUrl: item,
    });
  };
  handleTableData = (item) => {
    this.setState({
      showEditModal: true,
      DetailData: item,
    });
  };
  handelMainModalCLose = () => {
    this.setState({
      showEditModal: false,
      attendanceValues: {
        person_id: null,
        attendance_type: "",
        in_date_time: new Date(),
        out_date_time: null,
      },
      invalid_attendance_type:false,
      showEditModal: false,
      selectedTimeIn: new Date(),
      selectedTimeOut: null,
      DetailData: {},
      selected_Attendance: { label: "Select Type", value: "" },
    });
  };
  handleUpdateDevice = () => {
    const { attendanceValues, DetailData, selectedTimeIn, selectedTimeOut } =
      this.state;
    const { id } = DetailData;
    const { attendance_type, out_date_time } = attendanceValues;
    let in_date_time = this.DateToFormattedString(selectedTimeIn);
    // let out_date_time = this.DateToFormattedString(selectedTimeOut);
    const selectedInfo = { attendance_type, in_date_time, out_date_time , person_id:id };
    this.setState({
      Loading: true,
    });
 
    if (!attendance_type) {
      this.setState({
          invalid_attendance_type: true,
      });
      const info = {
        message: Message.SelectAttendanceType,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if(selectedTimeOut && selectedTimeIn > selectedTimeOut){
      const info = {
        message: "IN-Time can't be greater than OUT-Time",
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if(selectedTimeOut && selectedTimeIn.toDateString() !=   selectedTimeOut.toDateString()){
     
      const info = {
        message: "Dates in IN-Time and OUT-Time is not the same",
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    addManualAttendance({ payload: selectedInfo }).then(
      (response) => {
        if (response.code == 200) {
          this.setState({
            showEditModal:false
           })
          const info = {
            message: response.data.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.handelMainModalCLose();
          return;
        } else if (response.code != 200) {
          const info = {
            message: response.data.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      }
    );
    ///////////Update Device need to implement////////////////
  };
  render() {
    let template;

    template = (
      <div>
        <Row>
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={3} lg={3}></Col>
        </Row>
        <AttendanceDetailModal
          isStaff={true}
          show={this.state.showEditModal}
          DetailData={this.state.DetailData}
          onAttendanceChange={this.handleAttendanceChange}
          selected_Attendance={this.state.selected_Attendance}
          MarkAttendance={this.handleUpdateDevice}
          onInChange={this.handleInTimeChange}
          onOutChange={this.handleOutTimeChange}
          attendanceType={this.state.invalid_attendance_type}
          selectedTimeIn={this.state.selectedTimeIn}
          selectedTimeOut={this.state.selectedTimeOut}
          onCancel={this.handelMainModalCLose}
        />
        <MAttendanceFilter
          selectedAttendanceFilters={this.state.selectedAttendanceFilters}
          onFilterFieldChange={this.handleFilterFieldChange}
          onFilterAttendance={this.handleApplyFilter}
        />
        <ImageModel show={this.state.pictureModal} imageUrl={this.state.imageUrl} onModalClose={this.handelShowPictureModal} />
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            {this.state.attendanceList &&
              this.state.attendanceList.length > 0 ? (
              <Table
                data={this.state.attendanceList}
                isImageEnabled={true}
                isImageCircle={true}
                isImageSquare={[true, false]}
                isCheckOption={false}
                onShowPicture={this.handelShowPictureModal}
                listItemToDisplay={[
                  { name: "First Name", valueField: "first_name" },
                  { name: "Last Name", valueField: "last_name" },
                  { name: "Registration #", valueField: "gu_id" },
                ]}
                onTableButtonClick={this.handleTableData}
                paginationData={this.state.paginationData}
               
                textButtonsToDisplay={[ {
                  buttonLabel: "Mark Attendance",
                  color:"primary",
                  Tooltipmessage: "Mark Attendance"
                }]}
              />
            ) : (
              <div className="text-center">
                There is no data available to show
              </div>
            )}
          </Col>
        </Row>
      </div>
    );

    return (
      <div className="">{template} </div>);
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  SortName: (sortName) => dispatch({ type: ActionTypes.SORT_NAME, sortName }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName
});

export default connect(mapStateToProps, actions)(ManualStaffAttendance);
