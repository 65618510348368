import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import Webcam from "react-webcam";
import ReactCrop from "react-image-crop";
import captureicon from "../../assets/captureIcon.png";
import "react-image-crop/dist/ReactCrop.css";
import "./ProfileView.css";
let newImage = null;

const CaptureImage = (props) => {
  const [crop, setCrop] = useState({
    unit: "px",
    x: 120,
    y: 70,
    width: 200,
    height: 200,
  });

  const [cropImage, setcropImage] = useState(false);
  const [modal, setModal] = useState(false);
  const [image, setimage] = useState(null);

  // const toggle = () => setModal(!modal);

  const Recapture = (props) => {
    setcropImage(false);
  };
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    newImage = imageSrc;
    setcropImage(imageSrc);
  }, [webcamRef]);
  // continue button
  const HandlePic = () => {
    getCroppedImg();
    props.passingImage(newImage);
    props.toggle();
   
  };
  // cropped image function
  function getCroppedImg() {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL("image/jpeg");

    newImage = base64Image;
  }
  return (
    <div>
      <div>
        <Modal
          className="modal-dialog modal-content rounded Model-shape modal-lg "
          isOpen={props.isOpen}
          modalTransition={{ timeout: 700 }}
          backdropTransition={{ timeout: 1300 }}
          toggle={props.toggle}
        >
          <ModalHeader toggle={props.toggle}>Capture Image</ModalHeader>
          <ModalBody>
            {cropImage ? (
              <div>
                <ReactCrop
                  src={cropImage}
                  onImageLoaded={setimage}
                  crop={crop}
                  onChange={(newCrop) => setCrop(newCrop)}
                />
                <Row>
                  <Col>
                    <Button outline color="primary" onClick={Recapture}>
                      Retake
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ float: "right" }}
                      color="primary"
                      onClick={HandlePic}
                    >
                      Continue
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <>
                <div className="w-100 p-3">
                  <Webcam
                    style={{ width: "100%" }}
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                  />
                </div>
                <div className="text-center">
                  <Button
                    style={{
                      position: "absolute",
                      marginTop: "-6.25rem",
                      marginLeft: "-1.875rem",
                    }}
                    className="btn-cap"
                    onClick={capture}
                  >
                    <img
                      src={captureicon}
                      style={{ width: "3rem", marginTop: "1.6rem" }}
                      alt="image"
                      className="img-responsive"
                    />
                  </Button>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter  style={{display:"unset"}}>
            <Row>
              <Col>
                <h4 className="img-sops mr-auto">Image of SOPs:</h4>
              </Col>
            </Row>
            <Row xs="1" sm="2" md="4">
              <Col>
                <p className="text-muted">1. Uncover your face</p>
              </Col>
              <Col>
                <p className="text-muted">2. Uncover your face</p>
              </Col>
              <Col>
                <p className="text-muted">3. Uncover your face</p>
              </Col>
              <Col>
                <p className="text-muted">4. Uncover your face</p>
              </Col>
              <Col>
                <p className="text-muted">5. Uncover your face</p>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default CaptureImage;
