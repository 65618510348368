import React, { Component } from "react";
import "../../../../assets/sass/_common.scss";
import { notificationAction } from "../../../../redux/reducers/notification_reducer";
import { Message } from "../../../../utils/messages.js";
import { connect } from "react-redux";
import LocationForm from "./Form/LocationForm";
import { addLocation } from "../apiCalls";

class AddLocation extends Component {
  state = {
    sid: 0,
    selectedLocationInfo: {
      name: "",
      branch_id: null,
    },

    validateLocation: {},
    selectedBranch:{}
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      this.validateField({ name, value });
      return;
    }
  };

  validateField = ({ name, value }) => {
    const { selectedLocationInfo, validateLocation } = this.state;
    if (name === "name") {
      this.setState({
        selectedLocationInfo: {
          ...selectedLocationInfo,
          name: value,
        },
        validateLocation: {
          ...validateLocation,
          [`invalid${name}`]: !value,
        },
      });
    }
  };

  handeBranchChange = (selectedOption, obj) => {
    const { selectedLocationInfo, validateLocation } =
      this.state;
    if (!selectedOption) {
      return;
    }

    const { value, id } = selectedOption;
    const { name } = obj;
    if (obj.name === "branch") {
      this.setState({
        selectedLocationInfo: {
          ...selectedLocationInfo,
          branch_id: id,
        },
        selectedBranch:selectedOption,
        validateLocation: {
          ...validateLocation,
          [`invalid${name}`]: !value,
        },
      });
    }
  };
  handleSaveLocation = () => {
    let { selectedLocationInfo, validateLocation } = this.state;
    this.setState({
      Loading: true,
    });
    if (!selectedLocationInfo.name) {
      this.setState({
        validateLocation: {
          ...validateLocation,
          invalidname: true,
        },
      });
      const info = {
        message: Message.AddLocation,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedLocationInfo.branch_id) {
      this.setState({
        validateLocation: {
          ...validateLocation,
          invalidbranch: true,
        },
      });
      const info = {
        message: Message.SelectBranch,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }

    addLocation({ name: selectedLocationInfo })
      .then((response) => {
        if (response.code == 200) {
          const info = {
            message: Message.AddSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.history.push("/lookups/location");
          return;
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        const info = {
          message: Message.AddFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  render() {
    return (
      <>
        <LocationForm
          selectedLocationInfo={this.state.selectedLocationInfo}
          onFormFieldChange={this.handleFormFieldChange}
          onBranchChange={this.handeBranchChange}
          selectedBranch={this.state.selectedBranch}
          validateLocation={this.state.validateLocation}
          onSave={this.handleSaveLocation}
        />
      </>
    );
  }
}
const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(AddLocation);
