import * as ActionTypes from "../actions/types";
const initialState = {
  goback: false,
  loading:false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GO_BACK:
      return {
        ...state,
        goback: action.goback,
      };
      case ActionTypes.STEP_1:
      return {
        ...state,
        step1: action.step1,
      };
      case ActionTypes.LOADING:
        return {
          ...state,
          loading: action.loading,
        };
    default:
      return { ...state };
  }
};

export default reducer;
