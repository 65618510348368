import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import "../../../../../assets/sass/_common.scss";
import HttpService from "../../../../../services/HttpService";
import Select from "react-select";
import { withRouter } from "react-router";

class ClassForm extends Component {
  state = {
    section: [],
  };
  async getLookUp() {
    this.setState({ section: await HttpService.getSectionsList() });
  }
  componentDidMount() {
    this.getLookUp();
  }

  handelGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };
  render() {
    const {
      selectedClassInfo,
      onNameChange,
      onSave,
      multiSelectedSection,
      onSectionChange,
      validateClass,
      onClassUpdate,
    } = this.props;
    const { name } = selectedClassInfo;
    const { invalidname } = validateClass;
    return (
      <div>
        <div className="content">
          <Row>
            <Col lg="12" md="12" sm="12">
              <Card className="card-user">
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label required">
                            {"Class Name"}
                          </label>
                          <Input
                            type="text"
                            autoFocus={invalidname}
                            className={`input-text-field  form-label" ${
                              invalidname ? "inValid" : ""
                            }`}
                            name="name"
                            maxLength={"20"}
                            value={name}
                            onChange={onNameChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label">{"Section"}</label>
                          <Select
                            isClearable
                            isSearchable={false}
                            name="section"
                            isMulti
                            value={multiSelectedSection}
                            onChange={onSectionChange}
                            options={this.state.section}
                            classNamePrefix="react-select"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4} lg={3} sm={12} xs={12}>
                        <Button
                          className="mb-3 btn-block"r
                          outline
                          color="primary"
                          onClick={() => this.handelGoBack()}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col lg={6} md={4} sm={12} xs={12} />
                      <Col lg={3} sm={12} md={4} xs={12}>
                        {this.props.isEdit ? (
                          <Button
                            className="btn-round btn-block float-right  "
                            color="primary"
                            onClick={() => onClassUpdate()}
                            type="button"
                          >
                            Update Class
                          </Button>
                        ) : (
                          <Button
                            className="btn-round btn-block float-right  "
                            color="primary"
                            onClick={() => onSave()}
                            type="button"
                          >
                            Add Class
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(ClassForm);
