import React, { Component, createRef } from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import Select from "react-select";
import { customStyles, class_type } from "../mockdepartment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "../../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import HttpService from "../../../services/HttpService";
import { MdArrowDropDown } from "react-icons/md";

export default class AttendanceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      department: [],
      attendanceOptions: [],
      ranges: this.initializeRangeDate(),
    };
  }
  async getLookUp() {
    this.setState({ department: await HttpService.getDepartmentList() });
    this.setState({
      attendanceOptions: await HttpService.getAttendanceList(
        "person_attendance_type"
      ),
    });
  }

  componentDidMount() {
    this.getLookUp();
  }
  onEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.props.onFilterDetection();
    }
  };
  initializeRangeDate = () => {
    const initialstate = {
      timePicker: true,
      startDate: moment().startOf("date"),
      endDate: moment()
        .startOf("date")
        .hours(23)
        .minutes(59, "hour")
        .seconds(59, "min"),
      locale: {
        format: "DD/M/YYYY hh:mm",
      },
    };
    return initialstate;
  };
  render() {
    const {
      onFilterDetection,
      onFilterReset,
      onFilterFieldChange,
      onApply,
      SelectedValues,
      onDDChange,
      onExportCsv,
      selectedDateRange,

      selectedDetectionFilters,
    } = this.props;
    const { person_name, person_number } = selectedDetectionFilters;
    const { selected_department, selected_attendance_type } = SelectedValues;

    const keyRef = createRef(Date.now());

    return (
      <>
        <Row className="mt-0 mb-4">
          <Col>
            <Card className="card-user">
              <CardBody>
                <Row>
                  <Col>
                    <h5 className="form-heading">Filters</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-row">
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Name"}:
                        </label>
                        <input
                          type="text"
                          name="person_name"
                          className={`input-text`}
                          value={person_name ? person_name : ""}
                          placeholder={"Search name..."}
                          onChange={onFilterFieldChange}
                          onBlur={onFilterFieldChange}
                          onKeyDown={(e) => this.onEnterPress(e)}
                          maxLength={"30"}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Employee #"}:
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          name="person_number"
                          className={`input-text `}
                          value={person_number ? person_number : ""}
                          placeholder={"Search Employee # .."}
                          onChange={onFilterFieldChange}
                          onBlur={onFilterFieldChange}
                          onKeyDown={(e) => this.onEnterPress(e)}
                          maxLength={"30"}
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Department"}:
                        </label>
                        <Select
                          isClearable
                          isSearchable={false}
                          name="department"
                          value={selected_department}
                          onChange={(selectedOption, name) =>
                            onDDChange(selectedOption, name)
                          }
                          options={this.state.department}
                          classNamePrefix="react-select"
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Attendance Status"}:
                        </label>
                        <Select
                          isClearable
                          isSearchable={false}
                          name="attendance_type"
                          value={selected_attendance_type}
                          onChange={(selectedOption, name) =>
                            onDDChange(selectedOption, name)
                          }
                          options={this.state.attendanceOptions}
                          classNamePrefix="react-select"
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Date Time Range"}:
                        </label>
                        <span className="calendar-date-range"></span>
                        <DateRangePicker
                          key={keyRef.current}
                          onApply={onApply}
                          initialSettings={this.state.ranges}
                        >
                          <input
                            type="text"
                            name="date_time_range"
                            className={`input-text`}
                            onChange={onFilterFieldChange}
                            value={selectedDateRange && selectedDateRange}
                          />
                        </DateRangePicker>
                      </div>
                      <div className=" float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="float-left">
                          <Button
                            onClick={onExportCsv}
                            color="primary"
                            className="caret"
                          >
                            Export{" "}
                            <MdArrowDropDown size={20} className="mb-1" />
                          </Button>
                        </div>
                        <div className="float-right">
                          <Button color="primary" onClick={onFilterDetection}>
                            Apply Filter
                          </Button>
                        </div>
                        <div className="float-right">
                          <Button
                            className="mr-3"
                            outline
                            color="primary mr-3"
                            onClick={onFilterReset}
                          >
                            Clear Filters
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
