import { ApiUrls } from "../../../utils/apiUrls";
import authAxios from "../../../services/axiosInstance";
import axios from "axios";


export const getAllSection = () => {
  return authAxios
    .get(ApiUrls.Lookups_Section.GET_SECTION_LIST)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addSection = ({ name }) => {
  return authAxios
    .post(ApiUrls.Lookups_Section.ADD_SECTION, name)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const SectionDetails = ( sectionId ) => {
  return authAxios
    .get(ApiUrls.Lookups_Section.SECTION_DETAIL(sectionId))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateSection = ({ postData }) => {
  return authAxios
    .post(ApiUrls.Lookups_Section.UPDATE_SECTION, postData)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const deleteSecion = ({ statusData }) => {
  return authAxios
    .post(ApiUrls.Lookups_Section.SECTION_DELETE, statusData)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const filterTableRecords = (
  per_page,
  page_no,
  order_by,
  order_type,
  name,
  status,
  download
) => {
  return authAxios
    .get(ApiUrls.Lookups_Section.GET_SECTION_LIST, {
      params: {
        per_page,
        page_no,
        order_by,
        order_type,
        name,
        status,
        download
      },
    })
    .then(({ data }) => data)
    .catch((response) => response);
};

export const activeInActiveSection = ({ statusData }) => {
  return authAxios
    .put(ApiUrls.Lookups_Section.ACTIVE_INACTIVE_SECTION, statusData)
    .then(({ data }) => data)
    .catch((response) => response);
};