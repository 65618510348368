import React, { Component } from 'react'
import ApexCharts from "react-apexcharts";

export default class ApexColumnBasic extends Component {
  render() {
    const chartSettings = {
      chart: {
        toolbar: {
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true ,
            reset: true,
        }
        },
        height: 350,
        type: "line",
        stacked: false,
      },
      stroke: {
        width: [0, 0],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      fill: {
        type: "solid",
        opacity: [1, 0.5],
      },
      labels: this.props.MonthDates && this.props.MonthDates,
      markers: {
        size: 0,
      },
      xaxis: {
        type: "category",
        labels: {
          style: {
            colors: "#6B859E",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: ["#6B859E"],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + "";
            }
            return y;
          },
        },
      },
    };
    return (
      <ApexCharts
      options={chartSettings}
      series={this.props.MonthPresentSeries && this.props.MonthPresentSeries}
      type="area"
      height={300}
    />
    )
  }
}

