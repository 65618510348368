import React from "react";
import { Col, Row, Button } from "reactstrap";
import "../../assets/sass/_common.scss";
import { filterTableRecords } from "./apiCalls";
import { notificationAction } from "../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../redux/actions/types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UserFilter from "./component/Filter";
import LoadingMask from "../../components/Loader/LoadingMask";
import { IoPersonAdd } from "react-icons/io5";
// import mockData from "../../components/Table/mockData";
import { MdDelete, MdEdit } from "react-icons/md";
import UserRolesTable from "../../components/Table/UserRolesTable";
import SweetAlert from "../../components/sweet-alert/SweetAlert";

let Json = {};
let bulkActionType = "";
class UsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sweetalert: false,
      paginationData: [],
      download: true,
      disabled: true,
      appLoadingState: false,
      selectedRadioOption: "2",
      studList: [],
      selectedDetailInfo: {},

      selectedStudentValues: {
        username: null,
        person_email: null,
        student_name: null,
        email: null,
        status: null,
      },
      selectedValues: {
        selected_status: { label: "All", value: null },
      },
    };
  }

  componentDidMount() {
    this.handleFilterDataTableList();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageno !== this.props.pageno ||
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      this.handleFilterDataTableList();
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      this.props.PageNo(1);
      this.handleFilterDataTableList();
    }
  }

  handleFiltersClear = () => {
    this.props.PageNo(1);
    this.props.RecordPerPage(10);
    this.setState(
      {
        selectedStudentValues: {
          student_name: "",
          email: "",
        },
        selectedValues: {
          selected_status: { label: "All", value: null },
        },
      },
      () => this.handleFilterDataTableList()
    );
  };

  validateFilterField = ({ name, value }) => {
    const { selectedStudentFilters } = this.state;
    this.setState({
      selectedStudentFilters: {
        ...selectedStudentFilters,
        // [`invalid${name}`]: !value,
        [name]: value,
      },
    });
  };

  handleFilterApply = () => {
    this.handleFilterDataTableList();
  };

  handleFilterDataTableList = () => {
    this.setState({ appLoadingState: true });
    const { selectedStudentValues, selectedValues } = this.state;
    const { selected_status } = selectedValues;
    const { student_name, email, status } = selectedStudentValues;
    const { label } = selected_status;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      student_name ? student_name.replace(/^\s+|\s+$/g, "") : null,
      email ? email.replace(/^\s+|\s+$/g, "") : null,
      label === "Active" ? true : null || label === "Inactive" ? false : null
    )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            studList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState: false,
          });
        }
        else if (response.code != 200){
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            appLoadingState: false,
          });
        }
      })
      .catch((error) => console.log("Erorr in getting users ", error));
  };
  validateField = ({ name, value }) => {
    const { selectedStudentValues } = this.state;
    this.setState({
      selectedStudentValues: {
        ...selectedStudentValues,
        [name]: value,
      },
    });
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "student_name" || name === "email") {
      this.validateField({ name, value });
      return;
    }
  };
  handelDropDownChange = (selectedOption, obj) => {
    const { selectedValues, selectedStudentValues } = this.state;
    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (obj.name === "status") {
        this.setState({
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
        });
      }
      return;
    }

    const { name, value } = obj;

    if (obj.name === "status") {
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedStudentValues: {
          ...selectedStudentValues,
          [name]: selectedOption.value,
        },
      });
    }
  };

  handleExportCsv = () => {
    this.setState({ appLoadingState: true });
    const { student_name, email, status } = this.state.selectedStudentValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,

      student_name ? student_name.replace(/^\s+|\s+$/g, "") : null,
      email ? email.replace(/^\s+|\s+$/g, "") : null,
      status ? status : null,
      this.state.download ? 1 : null
    )
      .then((response) => {
        if (response.code == 200) {
          if (response.data.file_url != null) {
            window.open(response.data.file_url, "_blank");
          }
        }
        this.setState({ appLoadingState: false });
      })
      .catch((error) => console.log("Erorr in getting users ", error));
  };
  handelSweetModalDelete = (e) => {
    let studentId = { person: this.state.empId };
    if (e) {
      // deleteStudent({ id: studentId })
      //   .then((response) => {
      //     if (response.code === 200) {
      //       const info = {
      //         message: response.message,
      //         status: "success",
      //       };
      //       this.setState({
      //         sweetalert: false
      //       })
      //       this.props.notificationAction(info);
      //       this.handleFilterDataTableList();
      //       return;
      //     }
      //   })
      //   .catch((error, response) => {
      //     const info = {
      //       message: response.message,
      //       status: "error",
      //     };
      //     this.props.notificationAction(info);
      //   });
    } else {
      this.setState({
        sweetalert: false,
      });
    }
  };
  handleUserEdit = (item) => {
    this.props.history.push({
       pathname: "/users/edit-user/" + item.selectedUser.id,
      state: {
        selectedDetailInfo: item.selectedStudent,
      },
    });
  };
  handleUserDelete = (Id) => {
    this.setState({
      empId: Id,
      sweetalert: true,
    });
  };
  handleTableButtonClick = (item, type) => {
    if (type == "Edit") {
      this.handleUserEdit({
        selectedUser: item,
      });
    } else if (type == "Delete") {
      this.handleUserDelete(item.id);
    }
  };
  handleToggleButtonClick = (item) => {
    let allStudents = this.state.studList;
    let studentMapping = allStudents.find(function (element) {
      return element.id == item.id;
    });
    let studentStatus = null;
    if (studentMapping.is_active == true) {
      studentStatus = false;
    } else if (studentMapping.is_active == false) {
      studentStatus = true;
    }

    let statusData = {
      person: studentMapping.id,
      is_active: studentStatus,
    };
    // activeInActiveStudent({ statusData })
    //   .then((response) => {
    //     if (response.code === 200) {
    //       const info = {
    //         message: response.message,
    //         status: "success",
    //       };
    //       this.props.notificationAction(info);
    //       this.handleFilterDataTableList();
    //       return;
    //     }
    //   })
    // .catch((error, response) => {
    //   const info = {
    //     message: response.message,
    //     status: "error",
    //   };
    //   this.props.notificationAction(info);
    // });
  };
  render() {
    const UserInfo = localStorage.getItem("user");
    let template;

    template = (
      <div>
        <SweetAlert
          show={this.state.sweetalert}
          deleteRecord={this.handelSweetModalDelete}
        />
        <Row>
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={3} lg={3}></Col>
          <Col md={9} lg={9}>
            <Link to="/users/add-user">
              <Button color="primary" className="pull-right button-full-width">
                <IoPersonAdd size={20} /> &nbsp; Add User
              </Button>
            </Link>
          </Col>
        </Row>
        <UserFilter
          onFilterApply={this.handleFilterApply}
          onExportCsv={this.handleExportCsv}
          SelectedValues={this.state.selectedValues}
          selectedFormValues={this.state.selectedStudentValues}
          onFormFieldChange={this.handleFormFieldChange}
          onDDChange={this.handelDropDownChange}
          onFilterReset={this.handleFiltersClear}
        />

        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            {this.state.studList && this.state.studList.length > 0 ? (
              //  {mockData.data && mockData.data.length > 0 ? (

              <UserRolesTable
                primaryKeyField="sno"
                data={this.state.studList}
                isImageEnabled={false}
                toggleButtonToDisplay={  UserInfo == "superadmin" ? true :false}
                listItemToDisplay={[
                  { name: "Name", valueField: "username" },
                  { name: "Email", valueField: "email" },
                  { name: "Student Actions", valueField: "has_students_app_permissions" },
                  { name: "Student Reports", valueField: "has_students_reports_permissions" },
                  { name: "Staff Actions", valueField: "has_staff_app_permissions" },
                  { name: "Staff Reports", valueField: "has_staff_reports_permissions" },
                  { name: "Devices", valueField: "has_devices_app_permissions" },
                  { name: "Lookups", valueField: "has_lookups_app_permissions" },
                  { name: "Users", valueField: "has_users_app_permissions" },
                
                  
                ]}
                buttonsToDisplay={ UserInfo == "superadmin" ? 
                  [
                    {
                      name: "Edit",
                      buttonLabel: "Edit",
                      icon: <MdEdit size={22} />,
                      Tooltipmessage: "Edit User",
                    },
                    {
                      name: "Delete",
                      color: "#CE3939",
                      icon: <MdDelete size={22} />,
                      buttonLabel: "Delete",
                      Tooltipmessage: "Delete User",
                    },
                  ] : null
                }
                isCheckOption={false}
                // toggleButtonToDisplay={true}
                onTableButtonClick={this.handleTableButtonClick}
                onToggleStatus={this.handleToggleButtonClick}
                paginationData={this.state.paginationData}
              />
            ) : (
              <div className="text-center">
                There is no data available to show
              </div>
            )}
          </Col>
        </Row>
      </div>
    );

    return (
      <div className="">{template} </div>);
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName,
});

export default connect(mapStateToProps, actions)(UsersList);
