import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Form,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import HttpService from "../../../services/HttpService";
import { BASE_URL } from "../../../utils/apiUrls";
class AttendanceDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isCompanyInfoOpen: true,
      profileImage: "",
      attendanceOptions:[]
    };

  }
  componentDidMount(){
    this.getLookUp()
  }
  async getLookUp() {
    this.setState({ attendanceOptions: await HttpService.getAttendanceList("person_attendance_type")});

  }
  render() {
    const { onCancel, MarkAttendance, DetailData, selected_Attendance, attendanceType, onAttendanceChange,selectedTimeIn, selectedTimeOut, onOutChange, onInChange, isStaff } = this.props;
    const { first_name, gu_id, last_name, image_path } =
      DetailData;
    return (
      <div>
        <div>
          <Modal
            className="modal-dialog modal-content rounded Model-shape  "
            isOpen={this.props.show}
            size="lg"
            toggle={this.toggle}
            modalTransition={{ timeout: 700 }}
            backdropTransition={{ timeout: 1300 }}
          >
            <ModalHeader toggle={onCancel}>Attendance Details</ModalHeader>
            <ModalBody>
              <Row>
                <Col md="12">
                  <CardHeader>
                    <CardTitle tag="h5">
                      <Col
                        color="secondary"
                        className="collapseBarStyle"
                        onClick={() =>
                          this.setState({
                            isCompanyInfoOpen: !this.state.isCompanyInfoOpen,
                          })
                        }
                      >
                       {isStaff ? "Staff Information" : "Student Information" }
                        {this.state.isCompanyInfoOpen ? (
                          <FiChevronDown className="pull-right"></FiChevronDown>
                        ) : (
                          <FiChevronUp className="pull-right"></FiChevronUp>
                        )}
                      </Col>
                    </CardTitle>
                  </CardHeader>
                  {this.state.isCompanyInfoOpen ? (
                    <CardBody className="cardBodyBackground">
                      <Row>
                        <Col xs={12} sm={6} xl={4} md={4}>
                          <div className="mt-3">
                            <div className=" mb-0 text-muted"> Name</div>
                            <p className="body-1 mb-4">{first_name ? first_name : "N/A"}</p>
                          </div>

                       </Col>
                       <Col xs={12} sm={6} xl={4} md={4}>
                          <div className="mt-3">
                            <div className="  mb-0 text-muted">
                              Registration #
                            </div>
                            <p className="body-1 mb-4">
                              {gu_id ? gu_id : "N/A"}
                            </p>
                          </div>

                        </Col>
                        <Col xs={12} sm={6} xl={4} md={4}>
                          <div className="  mb-4 text-muted">
                            <img
                              className="rounded-circle profileImageSmall"
                              src={
                                image_path ? BASE_URL + "/" + image_path : null
                              }
                            ></img>
                          </div>
                          </Col>
                      </Row>
                    </CardBody>
                  ) : (
                   null
                  )}
                  <CardBody>
                    <Row>
                      <Col xs={12} sm={12} xl={6} md={6}>
                        <div className="mb-3">
                          <div className="  mb-0 text-muted required">
                            Attendance Status
                          </div>
                          <Select
                            isSearchable={false}
                            isClearable={true}
                            name="attendance"
                            className={`select-input  ${
                              attendanceType ? "inValid" : ""
                            }`}
                            menuPortalTarget={document.querySelector('body')}
                             styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            value={selected_Attendance}
                            onChange={onAttendanceChange}
                             options={this.state.attendanceOptions}
                            classNamePrefix="react-select"
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={12} xl={6} md={6} />
                      <Col xs={12} sm={12} xl={6} md={6}>
                        <div className="mb-3">
                          <p className="body-1  text-muted mb-0">
                            IN-Time Stamp
                          </p>
                          <ReactDatePicker
                             selected={selectedTimeIn}
                            onChange={onInChange}
                            todayButton="Today"
                            showTimeSelect
                            timeFormat="HH:mm"
                            menuPortalTarget={document.querySelector('body')}
                            maxDate={new Date()}
                           value={selectedTimeIn && selectedTimeIn}
                           dateFormat="MMMM d, yyyy h:mm"
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={12} xl={6} md={6}>
                      <div className="mb-3">
                          <p className="body-1  text-muted mb-0">
                            OUT-Time Stamp
                          </p>
                          <ReactDatePicker
                             selected={selectedTimeOut}
                             onChange={onOutChange}
                            todayButton="Today"
                            showTimeSelect
                            timeFormat="HH:mm"
                            maxDate={new Date()}
                            value={selectedTimeOut && selectedTimeOut}
                            dateFormat="MMMM d, yyyy h:mm"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="float-right">
                      <Button
                        className="mr-2"
                        outline
                        color="primary"
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>

                      <Button color="primary" onClick={MarkAttendance}>
                        Submit
                      </Button>
                    </Row>
                  </CardBody>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default AttendanceDetailModal;
