export default {
  auth: {
    email: 'admin@flatlogic.com',
    password: 'password'
  },
  csvDataStaff: [["First Name","Middle Name", "Last Name", "Employee Number", "Image","Department","Branch","Email","Phone Number","Emergency Name","Relation","Emergency Phone","Address","Status"]],
  csvDataStudent: [["First Name", "Middle Name", "Last Name", "Registration","Image","Roll Number","StudentClass","Section","Email","Phone Number","Guardian Name","Relation","Guardian Phone","Address","Status"]],
  csvDataStaff_RFID: [["First Name","Middle Name", "Last Name", "Employee Number","RFID", "Image","Department","Branch","Email","Phone Number","Emergency Name","Relation","Emergency Phone","Address","Status"]],
  csvDataStudent_RFID: [["First Name", "Middle Name", "Last Name", "Registration","RFID", "Image","Roll Number","StudentClass","Section","Email","Phone Number","Guardian Name","Relation","Guardian Phone","Address","Status"]],
};
